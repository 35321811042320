import React, {createContext, useContext, useEffect, useReducer, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Stepper , Step, StepLabel, Button, Box, Typography, StepButton } from '@material-ui/core';

import '../setupPage.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import Icon from '../../shared/components/Icon';
import StepIcon from '../../svg/stepper-step';
import CheckboxIcon from '../../svg/CompositeLayer.svg';
import SetupStepperButtons from './SetupStepperButtons';
import {Redirect} from "react-router-dom";

type SetupPageStepperProps = {
    redirect: (path: string) => void;
}

interface StepperContextProps {
    canGoForward: boolean;
}

const useStyles = makeStyles((theme) => ({
    stepper: {
        backgroundColor: "transparent",
        // maxWidth: "1200px",
        margin: "0 auto",
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepLabel: {
        "& .MuiStepLabel-completed, .MuiStepLabel-active": {
            color: "#fff",
        },
    },
    btnContainer: {
        marginTop: "10px",
    },
}));

function getSteps() {
    return [
        {
            label: 'Customize & Select Template',
            path: '/customize',
        },
        {
            label: 'Feedback Options',
            path: '/reviews',
        },
        {
            label: 'Staff Member Import',
            path: '/AgentImport/ConnectEmailClient',
        },
        {
            label: 'Copy & Paste for Signature',
            path: '/signature',
        }
    ];
}

type StepIconProps = {
    completed: boolean,
    active: boolean,
    icon: number,
};

function getStepIcon(props: StepIconProps) {
    const { active, completed } = props;

    return (
        <Box>
            {completed ? <Icon src={CheckboxIcon}></Icon>
                :
                <StepIcon active={active} number={props.icon} />}
        </Box>
    )
}

const StepperContext = createContext<StepperContextProps>({canGoForward: false});
const StepperDispatchContext = createContext<any | null>(null);
export function StepperProvider(props: any) {
    const [state, dispatch] = useReducer(stepReducer, {canGoForward: false});
    const {children} = props;

    return (
        <StepperContext.Provider value={state}>
            <StepperDispatchContext.Provider value={dispatch}>
                {children}
            </StepperDispatchContext.Provider>
        </StepperContext.Provider>
    );
}

export function useStepperState() {
    return useContext(StepperContext);
}

export function useStepperDispatch() {
    return useContext(StepperDispatchContext);
}

export function stepReducer(state: StepperContextProps, action: { type: string; payload: any; }): StepperContextProps {
    switch (action.type) {
        case 'setCanGoForward':
            return {...state, canGoForward: action.payload};
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}

function StepperButtons(props: {activeStep: number, steps: any; handleCurrent: any; }) {
    const classes = useStyles();
    const stepperState = useStepperState();
    const { activeStep, steps, handleCurrent } = props;

    return (
        <Stepper activeStep={activeStep} className={classes.stepper} >
            {steps.map((step:any, index:number) => {
                const stepProps = {
                    number: index
                };
                return (<Step className={`step ${index <= activeStep ? 'active' : ''}`} {...stepProps}>
                    <StepButton disabled={!stepperState.canGoForward && index >= activeStep + 1} onClick={() => handleCurrent(index)}>
                        <StepLabel className={`step-label ${classes.stepLabel}`}
                                   StepIconComponent={getStepIcon}
                                   {...stepProps}
                        >
                            {step.label}
                        </StepLabel>
                    </StepButton>
                </Step>)})
            }
        </Stepper>
    );
}

const SetupPageStepper: React.FC<SetupPageStepperProps & RouteComponentProps<{ orgid: string }>> = (props) => {
    const classes = useStyles();
    const { location } = props;
    const { orgid } = props.match.params;
    const [activeStep, setActiveStep] = React.useState(0);
    const [redirect, setRedirect] = useState('');
    const steps = getSteps();

    useEffect(() => {
        // reset the redirect to avoid a loop
        if (redirect.length > 0) {
            setRedirect('');
        }
    }, [redirect]);

    useEffect(() => {
        const tabIndex = getTabIndex(location.pathname);
        if (tabIndex !== activeStep) {
            setActiveStep(tabIndex);
        }
    }, [location]);

    const handleNext = () => {

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        const nextStepPath = steps[activeStep + 1].path;
        const redirectPath = `/${orgid}/setup${nextStepPath}`;

        setRedirect(redirectPath);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);

        const prevStepPath = steps[activeStep - 1].path;
        const redirectPath = `/${orgid}/setup${prevStepPath}`;
        setRedirect(redirectPath);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleCurrent = (index: number) => {
        setActiveStep(index);

        const nextStepPath = steps[index].path;
        const redirectPath = `/${orgid}/setup${nextStepPath}`;
        setRedirect(redirectPath);
    }

    const getTabIndex = (url: string) => {
        if(/customize/.test(url)) return 0;
        if(/reviews/.test(url)) return 1;
        if(/AgentImport/.test(url)) return 2;
        if(/signature/.test(url)) return 3;

        return 0;
    }

    if (redirect) {
        return <Redirect to={ redirect } />;
    }

    return (
        <StepperProvider>
            <StepperButtons
                steps={steps}
                activeStep={activeStep}
                handleCurrent={handleCurrent}
            />
            <SetupStepperButtons
                activeStep={activeStep}
                steps={steps}
                handleNext={handleNext}
                handleBack={handleBack}
                handleReset={handleReset}
            />
            {props.children}
        </StepperProvider>
    );
}

export default withRouter(SetupPageStepper);

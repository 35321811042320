import { SeverityType } from "../enums/SeverityType";
import { AlertModel, CustomError, ErrorType, ValidationError } from "./ErrorModels";
import { ErrorData } from "./ErrorsData";

// export const customErrorMapper = (error: ErrorData): CustomError => {
//     return {
//         id: Date.now(),
//         message: error.error_message,        
//     }
// };

export const customErrorMapper = (message: string): CustomError => {
    return {
        id: Date.now(),
        message,        
    }
};

export const validationErrorMapper = (key: string, error: string): ValidationError => {
    return {
        name: key,
        message: error,        
    }
};

export const alertMapper = (message: string): AlertModel => {
    return {
        id: Date.now(),
        message,
        severity: SeverityType.Success,
    }
};

// export const serverErrorMapper = (error): ErrorType => {
//     return {
//         code: error.code,
//         message: error.error_message,        
//     }
// };
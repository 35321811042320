import { SurveyType } from "../lookups/LookupsApiData";
import { GroupedSummaryEntry, SummaryByDateRange, SummaryEntry, SummaryUnit } from "./ReportingData";
import { GroupedSummaryEntryModel, SummaryByDateRangeModel, SummaryEntryModel, SummaryUnitModel } from "./ReportingModels";

export const summaryEntryToModel = (summaryEntry: SummaryEntry): SummaryEntryModel => {
    return {
        timePeriodQuantity: summaryEntry.timePeriodQuantity,
        timePeriodType: summaryEntry.timePeriodType,
        criteriaPercent: summaryEntry.criteriaPercent,
        units: summaryEntry.units.map(u => summaryUnitToModel(u)),
    }
};

// export const groupedSummaryEntryToModel = (summaryEntry: SummaryEntry): SummaryEntryModel => {
//     return {
//         timePeriodQuantity: summaryEntry.timePeriodQuantity,
//         timePeriodType: summaryEntry.timePeriodType,
//         criteriaPercent: summaryEntry.criteriaPercent,
//         units: summaryEntry.units.map(u => summaryUnitToModel(u)),
//     }
// };

export const summaryByDateRangeToModel = (summary: SummaryByDateRange, surveyTypes: SurveyType[]): SummaryByDateRangeModel => {
    let typesSet = new Set<string>();
    const summaries: GroupedSummaryEntryModel[] = [];
    const summaryEntries = summary.summaries;

    if (summaryEntries.length) {
        summaryEntries.forEach(entry => {
            entry.units.forEach(u => { if (u.type) typesSet.add(u.type)});
        });
    };

    typesSet.forEach(type => {
        const data: Array<number> = [];

        summaryEntries.forEach(e => {
            data.push(e.units.find(u => u.type === type)?.count || 0);
        });

        const legend: GroupedSummaryEntryModel = {
            label: type,
            data,
            tension: 0.3,
            //options: {} 
        };

        const surveyType = surveyTypes.find(t => t.description == type);

        if (surveyType) {
            legend.borderColor = surveyType.color;
            legend.backgroundColor = surveyType.color;
        };

        summaries.push(legend);
    })

    return {
        labels: summary.labels,
        summaries,
    };
};

export const summaryUnitToModel = (unit: SummaryUnit): SummaryUnitModel => {
    return {
        type: unit.type,
        count: unit.count,

        backgroundColor: unit.backgroundColor,
        color: unit.color,
        rate: unit.rate,
    }
}

export const getMonthName = (month: number | undefined) => {
    switch(month) {
        case 1: return "January";
        case 2: return "February";
        case 3: return "March";
        case 4: return "April";
        case 5: return "May";
        case 6: return "June";
        case 7: return "July";
        case 8: return "August";
        case 9: return "September";
        case 10: return "October";
        case 11: return "November";
        case 12: return "December";
        default: return "";
    }
};
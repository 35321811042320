import React, {useCallback, useEffect, useState} from "react";
import {Box, Button, Checkbox, TextField, Tooltip, Typography} from "@material-ui/core";
import OfficeWorkerIcon from "../../svg/office-worker.jsx";
import * as selectors from "../feedbackSelectors";
import * as sharedSelectors from "../../shared/sharedSelectors";
import "./FeedbackPage.scss";
import {makeStyles} from "@material-ui/core/styles";
import {getFormOptions, leaveFeedback} from "../feedbackActions";
import {connect} from "react-redux";
import {ApplicationState} from "../../ApplicationState";
import {SurveyAnswerFormDetailsModel, FeedbackModel} from "../FeedbackModels";
import {appSettings} from "../../config/appSettings";
import {IconSetType} from "../../lookups/LookupEnum";
import * as validation from "../../shared/validation/validationHelper";
import {validationSelector} from "../../shared/errors/errorSelectors";
import {ValidationError} from "../../shared/errors/ErrorModels.js";
import starIcon1 from "../../png/V2.1.png";
import starIcon2 from "../../png/V2.2.png";
import starIcon3 from "../../png/V2.3.png";
import successBadgeIcon from "../../svg/success_badge_icon.svg";
import successBadgeRibbon from "../../svg/success_badge_ribbon.svg";
import Loading from "../../shared/components/Loading";
import InfoIcon from "@material-ui/icons/Info";
import {ReCaptchaModel, WhiteLabelProfile} from "../../shared/SharedModels";
import * as sharedActions from "../../shared/sharedActions";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {setValidationErrors} from "../../shared/errors/errorActions";

const staticDataUrl = appSettings.app.staticDataUrl;

type SubmitFeedbackPageProps = {
    whiteLabel?: WhiteLabelProfile;
    customer?: SurveyAnswerFormDetailsModel;
    isSubmitSucceeded: boolean;
    validationErrors: ValidationError[];
    organizationId: string;
    agentId: string;
    surveyAnswerId: string;
    submitRate: string;
    organisationFeedback?: number;
    starId?: number;
    leaveFeedback: typeof leaveFeedback;
    verifyReCaptcha?: typeof sharedActions.verifyReCaptcha;
    recaptchaResponse?: ReCaptchaModel;
    reviewSiteId?: number;
    logReview?: boolean;
    getFormOptions: typeof getFormOptions;
    setPublicReviewLabel?: (label: string) => void;
};

type CustomerSatisfactionTypeProps = {
    name: string; backgroundColor: string; color: string; exclaiming: string; title: string;
};

const useStyles = makeStyles((theme) => ({
    feedbackInput: {
        height: "120px",
    }, submitBtn: {
        marginTop: "45px", marginBottom: "40px", alignSelf: "center", width: "110px",
    }, iconTitle: {
        marginTop: "30px", marginBottom: "35px",
    }, infoIcon: {
        paddingLeft: "1rem", color: "#3B76EF"
    },
}));

const feedbackProperties: { [key: string]: CustomerSatisfactionTypeProps } = {
    verysatisfied: {
        name: "Very Satisfied",
        backgroundColor: "#D7EBD9",
        color: "#309A39",
        exclaiming: "Thank You!",
        title: "I appreciate your rating!",
    }, exceededexpectations: {
        name: "Exceeded Expectations",
        backgroundColor: "#EAF7E1",
        color: "#9FD973",
        exclaiming: "Thank You!",
        title: "I appreciate your rating!",
    }, satisfied: {
        name: "Satisfied",
        backgroundColor: "#FEF0C9",
        color: "#F7BA00",
        exclaiming: "Thank You!",
        title: "I appreciate your rating!",
    }, dissatisfied: {
        name: "Dissatisfied",
        backgroundColor: "#FFDEC4",
        color: "#FF9A48",
        exclaiming: "Oh, gosh!",
        title: "Can you please tell us where we can improve?",
    }, verydissatisfied: {
        name: "Very Dissatisfied",
        backgroundColor: "#F9C9CC",
        color: "#E44128",
        exclaiming: "Oh, gosh!",
        title: "Can you please tell us where we can improve?",
    }
};

const SubmitFeedbackPage: React.FC<SubmitFeedbackPageProps> = (props) => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    const classes = useStyles();
    const {
        customer,
        isSubmitSucceeded,
        validationErrors,
        organizationId,
        agentId,
        submitRate,
        surveyAnswerId,
        organisationFeedback,
        starId,
    } = props;

    const initialInputFields = {
        customerName: "", message: "", anonymous: false,
    };
    const [inputFields, setInputFields] = useState(initialInputFields);

    const feedbackProps = feedbackProperties[submitRate.toLowerCase()];
    const submitRateName = submitRate.toLowerCase();

    let privacyPolicyLink = 'https://ratemyservice.io/privacy';
    if (props.whiteLabel) {
        if (props.whiteLabel.privacyPolicy) privacyPolicyLink = props.whiteLabel.privacyPolicy;
    }

    const handleReCaptchaVerify = useCallback(async (event) => {
        event.preventDefault();
        if (!executeRecaptcha) {
            setValidationErrors([{name: 'recaptcha', message: 'reCaptcha not yet available'}]);
            return;
        }

        const token = await executeRecaptcha('/submitfeedback');
        if (token && props.verifyReCaptcha) {
            props.verifyReCaptcha(token);
        }

    }, [executeRecaptcha]);

    useEffect(() => {
        if (!customer || customer.answer.description !== submitRate) {
            props.getFormOptions({
                organizationId,
                agentId,
                answerId: surveyAnswerId,
                submitRate: submitRateName,
                logReview: false,
            });
        }
    },[]);

    useEffect(() => {
        if (customer?.formLabels.publicReviewLinkLabel && props.setPublicReviewLabel) {
            props.setPublicReviewLabel(customer.formLabels.publicReviewLinkLabel);
        }
    }, [customer])

    useEffect(() => {
        if (props.recaptchaResponse) {
            if (!props.recaptchaResponse.success) {
                setValidationErrors([{name: 'recaptcha', message: 'reCaptcha was not successful'}]);
                return;
            }
            if (props.recaptchaResponse.action !== '/submitfeedback' || props.recaptchaResponse.score < 0.6) {
                setValidationErrors([{name: 'recaptcha', message: 'reCaptcha did not pass'}]);
                return;
            }

            submit();
        }
    }, [props.recaptchaResponse])

    const submit = () => {
        if (customer && organisationFeedback !== undefined) {
            const data: FeedbackModel = {
                ...inputFields,
                organizationId: Number(organizationId),
                agentId: Number(agentId),
                agentName: customer.agentFullName,
                iconPath: customer.answer.iconPath,
                iconType: IconSetType.Static,
                customerSatisfactionType: customer.answer.description,
                surveyAnswerId: customer.answer.id,
                surveyId: customer.answer.surveyId,
                surveyAnswerTemplateId: customer.answer.surveyAnswerTemplateId,
                rate: customer.answer.rate,
                backgroundColor: customer.answer.backgroundColor,
                color: customer.answer.color,
                isArchived: false,
                organisationFeedback: organisationFeedback,
            };

            props.leaveFeedback(data);
        }
    };

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
        setInputFields((prevCreds) => {
            return {
                ...prevCreds, [event.target.name!]: event.target.value,
            };
        });
    };

    const handleAnonymous = (checked: boolean) => {
        if (checked) {
            setInputFields((prevCreds) => {
                return {
                    ...prevCreds, anonymous: checked, customerName: customer?.formLabels.anonymousLabel || "Anonymous",
                };
            });
        } else {
            setInputFields((prevCreds) => {
                return {
                    ...prevCreds, anonymous: checked, customerName: "",
                };
            });
        }
    }

    return (<>
            {!isSubmitSucceeded ? (customer ? (<>
                        <Box
                            className="feedback-title"
                            style={{backgroundColor: feedbackProps.backgroundColor}}
                        >
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                margin="auto"
                            >
                                <OfficeWorkerIcon/>
                                <Typography style={{marginLeft: "5px"}}>
                                    <strong>{customer!.agentFullName}</strong>{" "}
                                    {customer.formLabels.feedbackTitle}
                                </Typography>
                            </Box>
                        </Box>
                        <Box position="relative">
                            <Box className="circle">
                                {(organisationFeedback === 0 || organisationFeedback === undefined) && (<img
                                        width="50px"
                                        className="feedback-icon"
                                        alt="feedback icon"
                                        src={staticDataUrl + customer.answer.iconPath}
                                    />)}
                                {organisationFeedback === 1 && starId === 1 && (<img
                                        className="feedback-icon"
                                        alt="feedback icon"
                                        src={starIcon1}
                                    />)}
                                {organisationFeedback === 1 && starId === 2 && (<img
                                        className="feedback-icon"
                                        alt="feedback icon"
                                        src={starIcon2}
                                    />)}
                                {organisationFeedback === 1 && starId === 3 && (<img
                                        className="feedback-icon"
                                        alt="feedback icon"
                                        src={starIcon3}
                                    />)}
                            </Box>
                            <Typography
                                className={classes.iconTitle}
                                style={{color: feedbackProps.color}}
                            >
                                {customer.formLabels.name}
                            </Typography>
                            <Typography>
                                <strong>{customer.formLabels.exclaiming}</strong>{" "}
                                {customer.formLabels.title}
                            </Typography>
                        </Box>
                        <Box display={"flex"} flexDirection={"row"}>
                            <TextField
                                name="customerName"
                                value={inputFields.customerName}
                                label={customer.formLabels.feedbackName}
                                disabled={inputFields.anonymous}
                                variant="outlined"
                                margin="dense"
                                error={validation.hasError(validationErrors, "CustomerFullName")}
                                helperText={validation.getErrorText(validationErrors, "CustomerFullName")}
                                onChange={handleChange}
                                style={{flexGrow: 1}}
                            />
                            {Number(customer.anonymousActive) === 1 && (
                            <Box display={"flex"} flexDirection={"row"} style={{placeItems: "center"}}>
                                <label htmlFor={"anonymous_checkbox"}
                                       style={{display: "flex", flexDirection: "row", placeItems: "center"}}>
                                    <Checkbox id="anonymous_checkbox" checked={inputFields.anonymous} color="primary"
                                              onChange={(e) => handleAnonymous(e.target.checked)}/>
                                    <Typography>{customer.formLabels.anonymousLabel}</Typography>
                                </label>
                                <Tooltip
                                    title={<p
                                        style={{
                                            fontSize: "0.8rem", lineHeight: "125%", textAlign: "center"
                                        }}>{customer.formLabels.anonymousHint}</p>}
                                    placement="right-end"
                                    enterTouchDelay={0}
                                    arrow>
                                    <InfoIcon className={`${classes.infoIcon}`}/>
                                </Tooltip>
                            </Box>)}
                        </Box>
                        <TextField
                            name="message"
                            value={inputFields.message}
                            label={customer.formLabels.feedbackInput}
                            variant="outlined"
                            margin="dense"
                            multiline
                            rows={6}
                            error={validation.hasError(validationErrors, "FeedbackMessage")}
                            helperText={validation.getErrorText(validationErrors, "FeedbackMessage")}
                            onChange={handleChange}
                        />
                        <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"}>
                            <a href={privacyPolicyLink} target={"_blank"}><Typography
                                variant={"body2"}>{customer.formLabels.policyLinkLabel}</Typography></a>
                        </Box>
                        <Button
                            onClick={handleReCaptchaVerify}
                            color="primary"
                            className={classes.submitBtn}
                            variant="contained"
                            disabled={!customer}
                        >
                            {customer.formLabels.submitButton}
                        </Button>
                    </>) : (
                    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}
                         width={"100%"} height={"100%"}>
                        <Loading message={"Loading..."}/>
                    </Box>)) : (
                    <>
                        <div style={{position: "fixed", top: "0", left: "0", width: "100%", height: "100%", zIndex: 0, backgroundColor: "#f5f5f5"}}></div>
                        <Box className="anonymous-page-content">
                            <Box style={{zIndex: 2, position: "relative"}}>
                                <img src={successBadgeIcon} style={{width: "128px"}} alt={"Success Icon"} />
                                <Typography variant="h3" style={{
                                    backgroundImage: `url(${successBadgeRibbon})`,
                                    padding: "0.5rem",
                                    color: "white",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    marginTop: "-1rem",
                                }}>
                                    <strong>{customer?.formLabels.successTitle}</strong>
                                </Typography>
                            </Box>
                            <Box className={"success-message"}>
                                <Typography variant={"h4"}>{customer?.formLabels.successMessage}</Typography>
                            </Box>
                        </Box>
                    </>)}
        </>);
};

const mapStateToProps = (state: ApplicationState) => {
    return {
        customer: selectors.customerSelector(state),
        whiteLabel: sharedSelectors.whiteLabelSelector(state),
        isSubmitSucceeded: selectors.isSubmitSucceededSelector(state),
        validationErrors: validationSelector(state),
        recaptchaResponse: state.globals.reCaptchaResponse,
    };
};

const mapDispatchToProps = {getFormOptions, leaveFeedback, verifyReCaptcha: sharedActions.verifyReCaptcha}

const SubmitFeedbackPageContainer = connect(mapStateToProps, mapDispatchToProps)(SubmitFeedbackPage);

export default SubmitFeedbackPageContainer;

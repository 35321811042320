export default (params) => {
  const { last4, brand, expMonth, expYear } = params;

  function getValidDue(month, year){
    const y = year.toString().slice(2);
    let m = month;

    if (m < 10) m = `0${m}`;
    return `${m}/${y}`;
  }
  // const getCardNumberSplitted = (cardNumber) => {
  //   let i = 4;
  //   const arr = [];

  //   while (i > 0) {
  //     arrcardNumber.slice(0, 4);

  //   }
  // }  

  return(
<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="312" height="inherit" viewBox="0 0 312 176">
  <defs>
    <linearGradient id="linear-gradient" x1="0.067" y1="0.093" x2="0.95" y2="0.86" gradientUnits="objectBoundingBox">
      <stop offset="0" stopColor="#eaca89"/>
      <stop offset="1" stopColor="#f8d58a"/>
    </linearGradient>
  </defs>
  <g id="Group_697" data-name="Group 697" transform="translate(18.122 8.0)">
    <g id="Group_696" data-name="Group 696" transform="translate(0 36.868)">
      <g id="Group_806" data-name="Group 806">
        <rect id="Rectangle_160" data-name="Rectangle 160" width="51" height="34" rx="3" transform="translate(-0.122 -0.138)" fill="url(#linear-gradient)"/>
      </g>
      <g id="Group_695" data-name="Group 695">
        <path id="Path_496" data-name="Path 496" d="M0-.5v34" transform="translate(11.51 0.501)" fill="none" stroke="#a66841" strokeWidth="1"/>
        <line id="Line_158" data-name="Line 158" x2="50.976" transform="translate(0 22.903)" fill="none" stroke="#a66841" strokeWidth="1"/>
        <line id="Line_159" data-name="Line 159" x2="50.976" transform="translate(0 10.411)" fill="none" stroke="#a66841" strokeWidth="1"/>
        <line id="Line_160" data-name="Line 160" y2="10.635" transform="translate(42.468)" fill="none" stroke="#a66841" strokeWidth="1"/>
        <line id="Line_163" data-name="Line 163" y2="10.635" transform="translate(25.452)" fill="none" stroke="#a66841" strokeWidth="1"/>
        <line id="Line_164" data-name="Line 164" y2="9.217" transform="translate(25.452 24.79)" fill="none" stroke="#a66841" strokeWidth="1"/>
        <line id="Line_161" data-name="Line 161" y2="12.762" transform="translate(46.779 9.926)" fill="none" stroke="#a66841" strokeWidth="1"/>
        <line id="Line_162" data-name="Line 162" y2="11.344" transform="translate(42.468 22.663)" fill="none" stroke="#a66841" strokeWidth="1"/>
        <g id="Path_495" data-name="Path 495" transform="translate(18.397 5.672)" fill="#f8d58a">
          <path d="M 13.67991733551025 21.45394134521484 L 0.4999972283840179 21.45394134521484 L 0.4999972283840179 0.5000006556510925 L 13.67991733551025 0.5000006556510925 L 13.67991733551025 21.45394134521484 Z" stroke="none"/>
          <path d="M 0.9999971389770508 1 L 0.9999971389770508 20.95394134521484 L 13.17991733551025 20.95394134521484 L 13.17991733551025 1 L 0.9999971389770508 1 M -2.86102294921875e-06 0 L 14.17991733551025 0 L 14.17991733551025 21.95394134521484 L -2.86102294921875e-06 21.95394134521484 L -2.86102294921875e-06 0 Z" stroke="none" fill="#a66841"/>
        </g>
      </g>
    </g>
    <text id="_01_80" data-name="01/80" transform="translate(215.879 147.73)" fill="#fff" fontSize="20" fontFamily="Nunito-Regular, Nunito"><tspan x="0" y="0">{getValidDue(expMonth, expYear)}</tspan></text>
    <text id="_1234_5678_9012_3456" data-name="1234  5678  9012  3456" transform="translate(-3.0 111.73)" fill="#fff" fontSize="22" fontFamily="Nunito-Regular, Nunito"><tspan x="0" y="0" xmlSpace="preserve">{brand.toUpperCase()} XXXX XXXX XXXX {last4}</tspan></text>
    <text id="Credit_Card" data-name="Credit Card" transform="translate(-0.121 21.73)" fill="#fff" fontSize="22" fontFamily="Nunito-SemiBold, Nunito" fontWeight="600"><tspan x="0" y="0">Credit Card</tspan></text>
    {/* <text id="_0123" data-name="0123" transform="translate(-0.121 128.73)" fill="#fff" fontSize="12" fontFamily="Nunito-Regular, Nunito" letterSpacing="-0.04em" opacity="0.5"><tspan x="0" y="0">0123</tspan></text> */}
    <text id="Valid_Thru" data-name="ValidThru" transform="translate(185.879 138.73)" fill="#fff" fontSize="8" fontFamily="Nunito-Regular, Nunito" letterSpacing="-0.04em" opacity="0.5"><tspan x="0" y="0">VALID</tspan><tspan x="0" y="8">THRU</tspan></text>
    {/* <text id="Name_Surname" data-name="Name Surname" transform="translate(-0.121 155.73)" fill="#fff" fontSize="17" fontFamily="Nunito-Regular, Nunito" letterSpacing="-0.04em"><tspan x="0" y="0">{cardholderName}</tspan></text> */}
    <rect id="Rectangle_198" data-name="Rectangle 198" width="74" height="7" transform="translate(237.878 14.73)" fill="#fff" opacity="0.11"/>
    <rect id="Rectangle_199" data-name="Rectangle 199" width="37" height="7" transform="translate(274.878 1.73)" fill="#fff" opacity="0.11"/>
  </g>
</svg>



  )
}
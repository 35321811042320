import { Box, Button, createStyles, IconButton, InputAdornment, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import Icon from "../../../shared/components/Icon";
// import RMSLogo from '../../../svg/RateMyService-logo-new.svg'
import * as validation from '../../../shared/validation/validationHelper';
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { ApplicationState } from "../../../ApplicationState";
import * as errorSelectors from '../../../shared/errors/errorSelectors';
import { ValidationError } from "../../../shared/errors/ErrorModels";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { resetPassword } from "../resetPasswordActions";
import * as selectors from "../resetPasswordSelectors";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import {setValidationErrors} from "../../../shared/errors/errorActions";
import * as sharedActions from "../../../shared/sharedActions";
import {ReCaptchaModel} from "../../../shared/SharedModels";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

const useStyles = makeStyles((theme: Theme) => createStyles({
    btn: {
      backgroundColor: "#F43B3B",
      color: "#fff",
      marginTop: "10px",
    },
    agreement: {
        fontSize: "0.8rem",
    }
}));

type ResetPasswordPageProps = {
    validationErrors: ValidationError[] | [];
    isResetPasswordConfirmed: boolean | undefined;
    resetPassword: typeof resetPassword;
    verifyReCaptcha: typeof sharedActions.verifyReCaptcha;
    recaptchaResponse?: ReCaptchaModel;
};

const ResetPasswordPage: React.FC<RouteComponentProps & ResetPasswordPageProps> = (props) => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    const classes = useStyles();
    const { validationErrors, isResetPasswordConfirmed, location } = props;
    const [ privateData, setPrivateData ] = useState({
        token: "",
        login: "",
    });

    const [ resetPasswordModel, setResetPasswordModel ] = useState({
        password: "",
        confirmPassword: "",
        showPassword: false,
    });

    const handleReCaptchaVerify = useCallback(async (event) => {
        event.preventDefault();
        if (!executeRecaptcha) {
            setValidationErrors([{name: 'recaptcha', message: 'reCaptcha not yet available'}]);
            return;
        }

        const token = await executeRecaptcha('/forgotpassword');
        if (token) {
            props.verifyReCaptcha(token);
        }

    }, [executeRecaptcha]);

    useEffect(() => {
        if (props.recaptchaResponse) {
            if (!props.recaptchaResponse.success) {
                setValidationErrors([{name: 'recaptcha', message: 'reCaptcha was not successful'}]);
                return;
            }
            if (props.recaptchaResponse.action !== '/forgotpassword' || props.recaptchaResponse.score < 0.6) {
                setValidationErrors([{name: 'recaptcha', message: 'reCaptcha did not pass'}]);
                return;
            }

            handleResetPassword();
        }
    }, [props.recaptchaResponse]);

    useEffect(() => {
        const tkn = (location.search.match(/token=([^&]+)/) || [])[1];
        const lgn = (location.search.match(/login=([^&]+)/) || [])[1];

        setPrivateData(prev => {
            return {
                ...prev,
                token: tkn,
                login: lgn,
            }
        })
    }, []);

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
        if (!event.target.name) return;

        setResetPasswordModel(prev => {
            return {
                ...prev,
                [event.target.name!]: event.target.value,
            }
        });
    }

    const handleEnterPressResetPassword = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if(event.key == "Enter") {
            handleReCaptchaVerify(event);
        }
    };

    const handleResetPassword = () => {

        props.resetPassword({
            password: resetPasswordModel.password,
            confirmPassword: resetPasswordModel.confirmPassword,
            email: privateData.login,
            token: privateData.token,
        });

    };

    const handleClickShowPassword = () => {
        setResetPasswordModel(prev => {
            return {
            ...prev,
            showPassword: !prev.showPassword,
            }
        });
    };

    // need to type
    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const handleKeyPressPassword = () => {

    };

    return (
        <Box className='login-container-wrapper'>
            <Box className='login-container'>
                <Box className='login-box' justifyContent='space-around'>
                    <Box className='login-box-item'>
                        <Box className='sign-box'>
                            <Typography variant='h6'><strong>Reset Password</strong></Typography>

                            {isResetPasswordConfirmed ?
                                <Box>
                                    <Typography style={{ display: "inline" }}>Your password was successfully updated. Use the new password to </Typography><Link to="/signin">Sign In</Link>
                                </Box>
                                :
                                <form onKeyPress={handleEnterPressResetPassword} onSubmit={handleReCaptchaVerify}>
                                    <Typography style={{ marginBottom: "10px" }}>
                                        Enter your new password
                                    </Typography>
                                    <TextField
                                        className='sign-box-item'
                                        label="Password"
                                        type={resetPasswordModel.showPassword ? 'text' : 'password'}
                                        name='password'
                                        value={resetPasswordModel.password}
                                        variant="outlined"
                                        InputProps ={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        tabIndex="-1"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        onKeyDown={handleKeyPressPassword}
                                                    >
                                                        {resetPasswordModel.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                        error={validation.hasError(validationErrors, 'Password')}
                                        helperText={validation.getErrorText(validationErrors, 'Password')}
                                        onChange={handleChange}
                                    />
                                    <TextField
                                        className='sign-box-item'
                                        label="Repeat Password"
                                        type={resetPasswordModel.showPassword ? 'text' : 'password'}
                                        name='confirmPassword'
                                        value={resetPasswordModel.confirmPassword}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        tabIndex="-1"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {resetPasswordModel.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                        error={validation.hasError(validationErrors, 'PasswordConfirmation')}
                                        helperText={validation.getErrorText(validationErrors, 'PasswordConfirmation')}
                                        onChange={handleChange}
                                    />
                                    <Button
                                        type="submit"
                                        className={`${classes.btn} sign-box-btn`}
                                        variant="contained"
                                    >
                                        Reset
                                    </Button>
                                </form>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
};

const mapStateToProps = (state: ApplicationState) => {
    return {
        validationErrors: errorSelectors.validationSelector(state),
        isResetPasswordConfirmed: selectors.resetPasswordConfirmedSelector(state),
        recaptchaResponse: state.globals.reCaptchaResponse,
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        resetPassword: resetPassword,
        verifyReCaptcha: sharedActions.verifyReCaptcha,
    }, dispatch);
};

const ResetPasswordPageContainer = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);

export default withRouter(ResetPasswordPageContainer);

export enum EmailClientTypes {
    gsuite = "gsuite",
    office365 = "office365",
}

export enum TemplateColorTypes {
    // lawngreen
    green = "#7CFC00",
    // yellow
    yellow = "#FFFF00",
    black = "black",
}
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    MenuItem,
    FormControl, Select, InputLabel, Box
} from '@material-ui/core';
import {Redirect, RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import {connect} from 'react-redux';

import {ApplicationState} from '../../ApplicationState';

import * as sharedSelectors from "../sharedSelectors";
import {AgencyModel, UserProfileModel} from "../SharedModels";
import {getProfile} from "../sharedActions";
import {switchOrganization} from "../../EntryPages/SignInPage/signInActions";
import {appSettings} from "../../config/appSettings";

const useStyles = makeStyles(() => ({
    dropdown: {
        marginLeft: "2rem",
        minWidth: "20rem",
    },
    clientSelect: {
        "& div:nth-of-type(1)": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
    },
    menuItemContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    logoContainer: {
        paddingLeft: "1rem",
    },
    logo: {
        maxHeight: "25px",
    }
}));

type AgencyDropdownComponentProps = {
    profile: UserProfileModel | undefined;
    switchOrganization: typeof switchOrganization;
};

function AgencyDropdown(props: RouteComponentProps<{ orgid: string }> & AgencyDropdownComponentProps) {
    const history = useHistory();
    const classes = useStyles();
    const {profile, switchOrganization} = props;

    const staticDataUrl = appSettings.app.staticDataUrl;

    const agencyDetails: AgencyModel[] | undefined = profile?.agencyDetails;

    const initialSelectedClient = {
        id: 0,
        name: "Client",
        logoPath: ``,
    }

    const [selectedClient, setSelectedClient] = React.useState<AgencyModel | undefined>(initialSelectedClient);

    const handleAgencyDropdownChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const client = agencyDetails?.find((client) => client.id === event.target.value);
        setSelectedClient(client);
    }

    const dropdownValue = profile?.organizationId;

    useEffect(() => {
        if (selectedClient!.id !== 0) {
            switchOrganization(selectedClient!.id);
            const returnUrl = window.location.href.split('/').slice(4).join('/');
            history.push(`/org${selectedClient!.id}/${returnUrl}`)
        }
    }, [selectedClient])

    return (
        <FormControl variant="outlined" size="small" className={classes.dropdown}>
            <Select
                className={classes.clientSelect}
                name="clients"
                value={dropdownValue}
                onChange={handleAgencyDropdownChange}
            >
                {agencyDetails && agencyDetails.length > 0 && agencyDetails!.map((client, index) => {
                    return <MenuItem className={classes.menuItemContainer} key={index} value={client.id}>
                        <Box>
                            {client.name} - {client.id}
                        </Box>
                        <Box className={classes.logoContainer}>
                            <img className={classes.logo} src={`${staticDataUrl}${client.logoPath}`}
                                 alt="logo" />
                        </Box>
                    </MenuItem>
                })}
            </Select>
        </FormControl>)
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        profile: sharedSelectors.profileSelector(state),
    }
}

const mapDispatchToProps = {
    getProfile,
    switchOrganization
};

const AgencyDropdownComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(AgencyDropdown);

export default withRouter(AgencyDropdownComponent);

import { deprecated } from "typesafe-actions";
import { ReportingFilters, SummaryByDateRangeModel, SummaryEntryModel } from "../ReportingPage/ReportingModels";
import {
    FeedbackModel,
    Filters,
    SurveyAnswerFormDetailsModel,
    ToggleFeedbackModel
} from "./FeedbackModels";

const { createStandardAction } = deprecated;

export const getFormOptions = createStandardAction('submitFeedback/GET_FORM_OPTIONS')<{ organizationId: string, agentId: string, answerId: string, submitRate: string, logReview?: boolean }>();
export const leaveFeedback = createStandardAction('submitFeedback/LEAVE_FEEDBACK')<FeedbackModel>();
export const getAllFeedback = createStandardAction('feedback/GET_FEEDBACK')<Filters>();
export const getFeedback = createStandardAction('feedback/GET')<number>();
export const getReportSummary = createStandardAction('feedback/GET_FEEDBACK_SUMMARY')<ReportingFilters>();
export const toggleFeedbackIsArchived = createStandardAction('feedback/TOGGLE_FEEDBACK_ARCHIVED')<ToggleFeedbackModel>();

export const getFormOptionsCompleted = createStandardAction('submitFeedback/GET_FORM_OPTIONS_COMPLETED')<SurveyAnswerFormDetailsModel>();
export const leaveFeedbackCompleted = createStandardAction('submitFeedback/LEAVE_FEEDBACK_COMPLETED')();
export const getFeedbackCompleted = createStandardAction('feedback/GET_FEEDBACK_COMPLETED')<FeedbackModel>();
export const getAllFeedbackCompleted = createStandardAction('feedback/GET_ALL_FEEDBACK_COMPLETED')<FeedbackModel[]>();
export const getReportSummaryCompleted = createStandardAction('feedback/GET_FEEDBACK_SUMMARY_COMPLETED')<{ tableSummary: SummaryEntryModel[], chartSummary: SummaryByDateRangeModel }>();
export const toggleFeedbackIsArchivedCompleted = createStandardAction('feedback/TOGGLE_FEEDBACK_ARCHIVED_COMPLETED')();

export const clearFeedbackSummary = createStandardAction('feedback/CLEAR_FEEDBACK_SUMMARY')();
export const validateFeedbackModel = createStandardAction('feedback/VALIDATE_FEEDBACK')<FeedbackModel>();
export const feedbackModelValidated = createStandardAction('feedback/FEEDBACK_MESSAGE_VALIDATED')<boolean>();

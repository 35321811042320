import { ValidationError } from "../errors/ErrorModels";

const hasError = (validationErrors: ValidationError[], key: string): boolean => {
    return validationErrors.some(x => x.name === key);
}

const getErrorText = (validationErrors: ValidationError[], key: string): string => {
    const error = validationErrors.find(x => x.name === key);
    return error ? error.message : '';
}

export { hasError, getErrorText };
import { Box, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronUp from '../../svg/chevron-up.svg';
import ChevronDown from '../../svg/chevron-down.svg';
import Icon from "./Icon";
import ArrowIcon from "../../svg/arrowbtn.svg";

const useStyles = makeStyles((theme) => ({
    container: {
        position: "relative",
    },
    line: {
      border: "1px solid #3B76EF",
      backgroundColor: "#3B76EF",
      width: "275px",

      margin: "0px",
      marginBottom: "15px",
    },
    rectangle: {
        border: "3px solid #3B76EF",
        backgroundColor: "#3B76EF",

        width: "57px",
        margin: "0px",

        position: "absolute",
        top: "-6px",
        right: "20px",
    },
    collapseButton: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        border: "none",
        top: "-28px",
        right: "20px",
        width: "62px",
        backgroundColor: "transparent",
        transition: "background-color 0.3s",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "white",
        },
        "& img": {
            width: "24px",
            height: "24px",
        }
    }
}));

type SectionComponentProps = {
    children: string,
    marginTop?: string,
    width?: string,
    collapseButtonState?: boolean,
    collapseButtonAction?: () => void
};

const SectionComponent: React.FC<SectionComponentProps> = (props) => {
    const classes = useStyles();
    return(
        <Box
            style={{ marginTop: props.marginTop, width: "fit-content" }}
        >
            <Typography>{props.children}</Typography>
            <Box className={classes.container}>
                {props.collapseButtonAction ? <>
                    <button className={classes.collapseButton} onClick={props.collapseButtonAction}>{!props.collapseButtonState ? Icon({src: ChevronDown}) : Icon({src: ChevronUp})}</button>
                </>: null}
                <hr className={classes.rectangle} />
                <hr
                    className={classes.line}
                    style={{ width: props.width ? props.width : "275px" }}
                />
            </Box>
        </Box>
    )
}

export default SectionComponent;

import { CircularProgress } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { LoadingStateMap } from './LoadingStateModel';
import { ApplicationState } from '../../ApplicationState';

interface Props {
  scope?: string;
  loadingState: LoadingStateMap;
}

export class LoadingStateContainer extends React.Component<Props> {

  render() {
    const scope = this.props.scope || 'default';

    let showSpinner = false;

    if (this.props.loadingState) {
      const globalState = this.props.loadingState['default'];
      const loadingState = this.props.loadingState[scope];


      if (loadingState) {
        showSpinner = loadingState.isLoading;
      }

      // hide partial spinners if global is running
      if (globalState && globalState.isLoading && scope !== 'default') {
        showSpinner = false;
      }

    }

    return (
      (
        <>
          {
            showSpinner &&
            <div className="overlay-content text-center">
              <CircularProgress className="overlay-spinner" />
              <div className="overlay" />
            </div>
          }
        </>
      )
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  loadingState: state.loading,
});

const LoadingState = connect(mapStateToProps)(LoadingStateContainer);

export default LoadingState;
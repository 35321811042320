import React, { useState } from 'react';
import { Box, Button, Grid, Typography
} from '@material-ui/core';

import { CreditCardModel, StripeSetupModel } from '../AccountModels';
import { ValidationError } from '../../shared/errors/ErrorModels';
import { addCreditCard, updateCreditCard, createSetupIntent, confirmStripePayment, setDefaultCreditCard } from '../accountActions';
import * as selectors from '../accountSelectors';
import { addError } from '../../shared/errors/errorActions';
import * as s from '../../shared/sharedSelectors';
import * as errorSelectors from "../../shared/errors/errorSelectors";
import * as lookupSelectors from "../../lookups/lookupSelectors";

import CreditCardIcon from '../../svg/credit-card';
import Icon from '../../shared/components/Icon';
import PlusIcon from '../../svg/plus.svg';

import '../../App.scss';
import './accountPage.scss';
import { connect } from 'react-redux';
import { ApplicationState } from '../../ApplicationState';
import { OrganizationModel } from '../../shared/SharedModels';
import StripePopup from './StripePopup';

type CreditCardContainerProps = {
    creditCards: CreditCardModel[] | [],
    validationErrors: ValidationError[] | [],
    stripeSetup: StripeSetupModel,
    organization: OrganizationModel | undefined,

    addCreditCard: typeof addCreditCard,
    updateCreditCard: typeof updateCreditCard,
    createSetupIntent: typeof createSetupIntent,
    addError: typeof addError,
    confirmStripePayment: typeof confirmStripePayment,
    setDefaultCreditCard: typeof setDefaultCreditCard,
};

const CreditCards = (props: CreditCardContainerProps) => {
    const { organization, stripeSetup } = props;
    const [openCreditCardPopup, setOpenCreditCardPopup] = useState<{ open: boolean, action: string, creditCard?: CreditCardModel }>({
        open: false,
        action: "",
    });

    const handleAddCreditCard = () => {
        setOpenCreditCardPopup({
            open: true,
            action: "add",
        });
    }

    const handleUpdateCreditCard = (card: CreditCardModel) => {
        setOpenCreditCardPopup({
            open: true,
            action: "update",
            creditCard: card,
        });
    }

    const handleCloseCreditCardPopup = () => {
        setOpenCreditCardPopup({
            open: false,
            action: "",
        });
    };

    return (
        <Box className="credit-cards content-block">
            <Grid container wrap="nowrap">
                {
                    props.creditCards.length > 0 &&
                    props.creditCards.map((card: CreditCardModel) => {
                        return (
                            <Grid item
                                className="creditcard"
                                key={card.id}
                            >
                                <CreditCardIcon
                                    last4={card.last4}
                                    brand={card.brand}
                                    expMonth={card.expMonth}
                                    expYear={card.expYear}
                                ></CreditCardIcon>
                                <Box className="button-container">
                                    {
                                        card.isDefault ?
                                            <Box className="btn">default</Box>
                                            :
                                            <Button
                                                className="btn"
                                                size="small"
                                                variant="outlined"
                                                onClick={() => props.setDefaultCreditCard(card.id)}
                                            >
                                                set as default
                                            </Button>
                                    }
                                </Box>
                            </Grid>
                        )
                    })
                }
                
                <Grid item>
                    <Button className="add-creditcard-btn" onClick={handleAddCreditCard}>
                        <Icon src={PlusIcon}></Icon>

                        <Typography>Link a Card</Typography>
                    </Button>
                </Grid>
            </Grid>
            <StripePopup
                open={openCreditCardPopup.open}
                action={openCreditCardPopup.action}
                organization={organization}
                addCreditCard={props.addCreditCard}
                updateCreditCard = {props.updateCreditCard}
                handleClosePopup={handleCloseCreditCardPopup}
                validationErrors={props.validationErrors}
                createSetupIntent={props.createSetupIntent} 
                stripeSetup={stripeSetup}
                addError={props.addError}
                confirmStripePayment={props.confirmStripePayment}    
            >
            </StripePopup>                   
        </Box>
    )
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        navTabIsSelected: s.navTabSelector(state),
        subscriptionPlans: lookupSelectors.subscriptionPlansSelector(state),
        validationErrors: errorSelectors.validationSelector(state),
        organization: s.organizationSelector(state),
        // selectedPlan: s.orgPlanSelector(state),

        creditCards: selectors.creditCardsSelector(state),
        stripeSetup: selectors.stripeSettingsSelector(state),
        // errors: selectors.todoListsErrorSelector(errors),
        // ownProps
    }  
} 

const mapDispatchToProps = { addCreditCard, addError, createSetupIntent, confirmStripePayment, setDefaultCreditCard };

const CreditCardsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreditCards);

export default CreditCardsContainer;
import React, { useState } from 'react';
import { Box, Button, TableContainer, Table, TableHead, TableBody, TableRow
    , TableCell, TextField, Dialog, DialogTitle, DialogContent, DialogContentText
    , DialogActions, Checkbox, Typography, Snackbar,
} from "@material-ui/core";
import { connect } from 'react-redux';

import * as sharedSelectors from '../../shared/sharedSelectors';
import * as setupSelectors from '../setupSelectors';

import { redirect } from '../../shared/router/routerActions';
import { getAgents, editAgent, addAgent, selectEmailClient, clearImportResult, importCSV, changeSaveTemplateFlag } from '../setupActions';
import { getOrganizationPlan, getAuthUrl } from '../../shared/sharedActions';
import { addError } from '../../shared/errors/errorActions';
import ImportTabs from './ImportTabs';
import { ApplicationState } from "../../ApplicationState";
import { SubscriptionPlanModel, UserProfileModel, WhiteLabelProfile } from "../../shared/SharedModels";
import { Route, Switch } from "react-router";
import ConnectEmailClient from './ConnectEmailClient';
import { makeStyles } from '@material-ui/core/styles';
import ImportCSV from "./ImportCSV";
import { AgentProfileModel, ImportUserModel, NewAgentModel } from "../SetupModels";
import { useEffect } from "react";

import editBtnIcon from '../../svg/editbtn.svg';
import Icon from '../../shared/components/Icon';
import PlusIcon from '../../svg/plusbtn.svg';
import { EmailClientTypes } from '../setupEnums';
import SectionComponent from '../../shared/components/SectionComponent';
import { ImportStatusType } from '../../shared/enums/ImportStatusType';

import '../setupPage.scss';
import Loading from '../../shared/loading/LoadingStateContainer';
import { Alert } from '@material-ui/lab';
import WarningIcon from '@material-ui/icons/Warning';
import WarningBadge from '../../svg/warning-badge.svg';
import {useStepperDispatch} from "./SetupStepper";
import {getCurrentDomainName} from "../../shared/helpers";
import connectEmailClient from "./ConnectEmailClient";

const useStyles = makeStyles({
    tableContainer: {
        maxHeight: "250px",
    },
    table: {
      minWidth: 650,
      borderLeft: '2px solid #fff',
      borderRight: '2px solid #fff',
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    tableHeader: {
        height: "45px",
        display: "flex",
        padding: "10px",
        alignItems: "center",
        backgroundColor: "#fff",
        justifyContent: "space-between",
    },
    cell: {
        borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
    dialogText: {
        backgroundColor: "#FEF0C9",
        borderRadius: "0.2rem",
        padding: "0.5rem",
    },
    dialogInnerText: {
        borderRadius: "0.5rem",
        backgroundColor: "white",
        padding: "1rem",
        paddingTop: "3rem",
        marginTop: "-2rem",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    },
    buttonContainer: {
        flexDirection: "column",
    },
    cancelButton: {
        color: "#F43B3B",
        borderColor: "#F43B3B",
        marginTop: "0.5em",
        width: "100%",
    },
    line: {
        borderColor: "rgba(0, 0, 0, 0.2);"
    }
});

type StaffMemberImportPageProps = {
    whiteLabel: WhiteLabelProfile | undefined,
    profile: UserProfileModel | undefined,
    agents: AgentProfileModel[],
    selectedEmailClient: EmailClientTypes,
    organizationPlan?: SubscriptionPlanModel,
    importedUsers: ImportUserModel[] | [],
    isSaved: boolean,

    redirect: typeof redirect,
    getAgents: typeof getAgents,
    editAgent: typeof editAgent,
    addAgent: typeof addAgent,
    selectEmailClient: typeof selectEmailClient,
    getOrganizationPlan: typeof getOrganizationPlan,
    clearImportResult: typeof clearImportResult,
    importCSV: typeof importCSV,
    addError: typeof addError,
    getAuthUrl: typeof getAuthUrl,
    changeSaveTemplateFlag: typeof changeSaveTemplateFlag,
}

type EditPopupProps = {
    open: boolean,
    editedAgent: AgentProfileModel,

    handleClose: () => void,
    editAgent: typeof editAgent,
}

type AddAgentPopupProps = {
    open: boolean,

    handleClose: () => void,
    addAgent: typeof addAgent,
}

type ImportResultPopupProps = {
    open: boolean,
    users: ImportUserModel[] | undefined,

    handleClose: () => void,
};

type WarningPopupProps = {
    orgId: number,
    open: boolean,

    handleClose: () => void,
};

const EditPopup: React.FC<EditPopupProps> = (props) => {
    const [ editedAgent, setEditedAgent ] = useState(props.editedAgent);
    const [ validationErrors, setValidationErrors ] = useState<string[]>([]);
    const handleChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
        if (!event.target.name) {
            return;
        }

        setEditedAgent(prevAgent => {
            return {
                ...prevAgent,
                [event.target.name!]: event.target.value,
            }
        });
    }

    const handleSave = () => {
        let errors: string[] = [];
        if (editedAgent.fullName.length < 3) {
            errors = [...errors, "Full name should be at least 3 characters long"];
        }
        if (!editedAgent.defaultEmail.match(/^[a-zA-Z0-9._-]+@([a-zA-Z0-9.-]+\.)+[a-zA-Z]{2,}$/)) {
            errors = [...validationErrors, "Invalid email address"];
        }
        if (errors.length > 0) {
            setValidationErrors(errors);
            return;
        }
        props.editAgent(editedAgent);
        props.handleClose();
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="xs"
        >
            <DialogTitle id="alert-dialog-title">Edit Agent</DialogTitle>
            <DialogContent>
                <DialogContentText className="dialog-content">
                    <TextField
                        name='fullName'
                        value={editedAgent.fullName}
                        label="Full Name"
                        variant="outlined"
                        onChange={handleChange}/>
                    <TextField
                        name='defaultEmail'
                        value={editedAgent.defaultEmail}
                        label="Email"
                        variant="outlined"
                        onChange={handleChange}/>
                    {validationErrors.length > 0 && validationErrors.map((error, index) => (
                        <Typography key={index} color="error">{error}</Typography>
                    ))}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button color="secondary" onClick={handleSave}>SAVE</Button>
            </DialogActions>
        </Dialog>
    )
};

const AddAgentPopup: React.FC<AddAgentPopupProps> = (props) => {

    const initialNewAgent: NewAgentModel = {
        fullName: "",
        defaultEmail: "",
    }
    const [ agent, setAgent ] = useState<NewAgentModel>(initialNewAgent);
    const [ validationErrors, setValidationErrors ] = useState<string[]>([]);

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
        if (!event.target.name) {
            return;
        }

        setAgent(prevAgent => {
            return {
            ...prevAgent,
            [event.target.name!]: event.target.value,
            }
        });
    }

    const handleSave = () => {
        let errors: string[] = [];
        if (agent.fullName.length < 3) {
            errors = [...errors, "Full name should be at least 3 characters long"];
        }
        if (!agent.defaultEmail.match(/^[a-zA-Z0-9._-]+@([a-zA-Z0-9.-]+\.)+[a-zA-Z]{2,}$/)) {
            errors = [...validationErrors, "Invalid email address"];
        }
        if (errors.length > 0) {
            setValidationErrors(errors);
            return;
        }
        props.addAgent(agent);
        props.handleClose();
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"

            fullWidth={true}
            maxWidth="xs"
        >
            <DialogTitle id="alert-dialog-title">Add Agent</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <TextField
                        name='fullName'
                        value={agent.fullName}
                        label="Full Name"
                        variant="outlined"
                        margin="dense"
                        onChange={handleChange}
                    />
                    <TextField
                        name='defaultEmail'
                        value={agent.defaultEmail}
                        label="Email"
                        variant="outlined"
                        margin="dense"
                        onChange={handleChange}
                    />
                    {validationErrors.length > 0 && validationErrors.map((error, index) => (
                        <Typography key={index} color="error">{error}</Typography>
                    ))}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button color="secondary" onClick={handleSave}>SAVE</Button>
            </DialogActions>
        </Dialog>
    )
};

const ImportResultPopup: React.FC<ImportResultPopupProps> = (props) => {
    const classes = useStyles();

    const { users } = props;

    const getStatusStr = (s: ImportStatusType | string) => {
        switch(s) {
            case ImportStatusType.Succeed:
                return "Import succeed";
            case ImportStatusType.Failed:
                return `Failed`;
            default:
                return s;
        }
    }

    return(
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="lg"
        >
            <DialogTitle id="alert-dialog-title">Import agents</DialogTitle>
            <DialogContent>
                <TableContainer className={classes.tableContainer}>
                    <Table className={classes.table} size="small" stickyHeader aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.cell} align="center">Full Name</TableCell>
                                <TableCell className={classes.cell} align="center">Default Email Address</TableCell>
                                <TableCell className={classes.cell} align="center">Status</TableCell>
                                <TableCell className={classes.cell} align="center">Message</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {users && users.map((i: ImportUserModel) => (
                            <TableRow key={i.user.id} >
                                <TableCell className={classes.cell}>{i.user.fullName}</TableCell>
                                <TableCell component="th" scope="row" className={classes.cell}>
                                    {i.user.login}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    {getStatusStr(i.importStatus)}
                                    {i.importStatus === "Failed" && <Typography color="error"> {i.errorMessage}</Typography>}
                                </TableCell>
                                <TableCell className={classes.cell} >
                                    {i.errorMessage}
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Ok</Button>
            </DialogActions>
        </Dialog>
    )
}

const WarningPopup: React.FC<WarningPopupProps> = (props) => {
    const { orgId } = props;
    const classes = useStyles();
    const primaryToken = JSON.parse(localStorage.getItem('primaryToken')!);

    const isMainAccount = !(primaryToken && primaryToken.grant_type === 'agency_account');
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="xs"
            className="dialogContainer"
        >
            <DialogContent style={{ textAlign: "center", }}>
                <Box>
                    <img src={WarningBadge} alt="Warning" />
                </Box>
                <Box className={classes.dialogInnerText}>
                    <DialogContentText className={`dialog-content ${classes.dialogText}`}>
                        <Typography style={{ fontWeight: 700, marginBottom: "1em" }}>
                            You do not have enough "Staff Members" available in your Subscription.
                        </Typography>
                        <hr className={classes.line} />
                        <Typography>
                            Increase your Subscription before adding more Staff Members, or contact your sales representative.
                        </Typography>
                    </DialogContentText>
                </Box>
            </DialogContent>
            <DialogActions className={classes.buttonContainer}>
                <Box textAlign="center" width="50%">
                    {isMainAccount && <Button
                        href={`/org${orgId}/account/subscription`}
                        variant="contained"
                        color="primary"
                        style={{ width: "100%" }}
                    >
                        View Subscriptions
                    </Button>}
                    <Button
                        onClick={props.handleClose}
                        variant="outlined"
                        className={classes.cancelButton}
                    >
                        Cancel
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

const StaffMemberImportPage: React.FC<StaffMemberImportPageProps> = (props) => {
    const dispatch = useStepperDispatch();
    const classes = useStyles();
    const { profile, agents, selectedEmailClient, organizationPlan, importedUsers, isSaved
        , redirect, getAgents, editAgent, selectEmailClient, importCSV
        , getOrganizationPlan, addAgent, clearImportResult, addError, getAuthUrl
    } = props;
    const organization = profile?.organization;

    const activeAgentCount = agents.reduce((acc, agent) => (agent.active ? acc + 1 : acc), 0);

    const [editedAgent, setEditedAgent] = useState<AgentProfileModel | undefined>();
    const [editPopupOpen, setEditPopupOpen] = useState<boolean | undefined>();
    const [addAgentPopupOpen, setAddAgentPopupOpen] = useState<boolean | undefined>();
    const [importResultPopupOpen, setImportResultPopupOpen] = useState<boolean>(false);
    const [warningPopupOpen, setWarningPopupOpen] = useState<boolean>(false)
    const [showBulkImport, setShowBulkImport] = useState(false);

    useEffect(() => {
        getAgents();
    },[organization]);

    useEffect(() => {
        if (!agents.length) getAgents();
        if(!organizationPlan) getOrganizationPlan();
        dispatch({ type: 'setCanGoForward', payload: true });
    }, []);

    useEffect(() => {
        if(importedUsers.length) setImportResultPopupOpen(true);
    })


    const closeEditPopupHandler = () => {
        setEditPopupOpen(false);
    };

    const closeImportResultPopupHandler = () => {
        setImportResultPopupOpen(false);
        clearImportResult();
    }

    const closeWarningPopupHandler = () => {
        setWarningPopupOpen(false);
    };

    const editClickHandler = (agent: AgentProfileModel) => {
        setEditedAgent(agent);
        setEditPopupOpen(true);
    }

    const editAgentStatusClickHandler = (e: React.ChangeEvent<HTMLInputElement>, agent: AgentProfileModel) => {
        const activeAgentCount = agents.reduce((acc, agent) => (agent.active ? acc + 1 : acc), 0);
        if(organizationPlan && organizationPlan.agentsLimitMax
                && activeAgentCount >= organizationPlan.agentsLimitMax && e.target.checked)
        {
            setWarningPopupOpen(true);
            return;
        }

        const model = {
            ...agent,
            active: e.target.checked,
        };

        editAgent(model);
    }

    const addAgentClickHandler = () => {
        setAddAgentPopupOpen(true);
    }

    const clientEmailChangeHandler = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
        if (selectedEmailClient !== event.target.value) selectEmailClient(event.target.value);
    }

    const handleAlertClose = () => {
        props.changeSaveTemplateFlag(false);
    }
    const domain = getCurrentDomainName();
    let connectEmailClientRoute = null;
    // if it contains "ratemyservice" then show the connect email client component
    if (!domain || domain.match('ratemyservice')) {
        connectEmailClientRoute = (
            <Route path='/:orgId/setup/AgentImport/ConnectEmailClient'>
                <ConnectEmailClient
                    selectedEmailClient={selectedEmailClient}
                    handleEmailClientChange={clientEmailChangeHandler}
                    getAuthUrl={getAuthUrl}
                />
            </Route>
        );
    }
    return (
        <>
            <SectionComponent >STAFF MEMBERS</SectionComponent>
            <Box className='content-block table'>
                <Box className={classes.tableHeader}>
                    <Button variant="contained" color="secondary" size="small" endIcon={<Icon src={PlusIcon}></Icon>} onClick={addAgentClickHandler}>Add Staff Member</Button>
                </Box>
                <TableContainer className={classes.tableContainer}>
                <Table className={classes.table} size="small" stickyHeader aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cell} align="center">Staff Member ID</TableCell>
                            <TableCell className={classes.cell} align="center">Full Name</TableCell>
                            <TableCell className={classes.cell} align="center">Default Email Address</TableCell>
                            <TableCell className={classes.cell} align="center">Active</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {agents.length > 0 && agents.map((agent) => (
                        <TableRow key={agent.id} >
                            <TableCell className={classes.cell}>{agent.id}</TableCell>
                            <TableCell component="th" scope="row" className={classes.cell}>
                                {agent.fullName}
                            </TableCell>
                            <TableCell className={classes.cell}>
                                <Typography color="primary">{agent.defaultEmail}</Typography>
                            </TableCell>
                            <TableCell className={classes.cell} >
                                <Checkbox checked={agent.active} color="primary" onChange={(e) => editAgentStatusClickHandler(e, agent)} />
                            </TableCell>
                            <TableCell className={classes.cell} >
                                <Button variant="outlined" color="primary" endIcon={<Icon src={editBtnIcon}></Icon>} onClick={() => editClickHandler(agent)}>Edit</Button>
                            </TableCell>
                        </TableRow>
                    ))}

                    { editedAgent && editPopupOpen && <EditPopup handleClose={closeEditPopupHandler} editAgent={editAgent} editedAgent={editedAgent} open={editPopupOpen} ></EditPopup> }
                    { addAgentPopupOpen && <AddAgentPopup handleClose={() => setAddAgentPopupOpen(false)} addAgent={addAgent} open={addAgentPopupOpen} ></AddAgentPopup> }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <SectionComponent
                marginTop="30px"
                width={"300px"}
                collapseButtonAction={() => setShowBulkImport(!showBulkImport)}
                collapseButtonState={showBulkImport}
            >BULK IMPORT (OPTIONAL)</SectionComponent>
            {showBulkImport &&
            <Box id="importfrom-container">
                <ImportTabs orgId={profile!.organization.id} />
                <Switch>
                    {connectEmailClientRoute}
                    <Route path='/:orgId/setup/AgentImport/csv'>
                        <ImportCSV
                            importCSV={importCSV}
                        />
                    </Route>
                </Switch>
            </Box>}
            <ImportResultPopup
                open={importResultPopupOpen}
                users={importedUsers}
                handleClose={closeImportResultPopupHandler}
            />
            <WarningPopup
                orgId={organization!.id}
                open={warningPopupOpen}
                handleClose={closeWarningPopupHandler}
            />
            <Snackbar open={isSaved} onClose={handleAlertClose} autoHideDuration={1000} >
                <Alert variant="filled" severity="success">
                    SAVED
                </Alert>
            </Snackbar>
            <Loading scope="import" />
        </>
    )
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        whiteLabel: sharedSelectors.whiteLabelSelector(state),
        profile: sharedSelectors.profileSelector(state),
        agents: setupSelectors.agentsSelector(state),
        selectedEmailClient: setupSelectors.emailClientSelector(state),
        organizationPlan: sharedSelectors.orgPlanSelector(state),
        importedUsers: setupSelectors.importedUsersSelector(state),

        isSaved: setupSelectors.savedTemplateSelector(state),
    }
}

const mapDispatchToProps = {
    redirect, getAgents, editAgent, addAgent, selectEmailClient,
    getOrganizationPlan, clearImportResult, importCSV, addError, getAuthUrl, changeSaveTemplateFlag,
};

const StaffMemberImport = connect(
    mapStateToProps,
    mapDispatchToProps
)(StaffMemberImportPage);

export default StaffMemberImport;

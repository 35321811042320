import { ActionType, getType } from 'typesafe-actions';
import * as actions from './routerActions';
import { RouterModel } from './RouterModel';

export type RouterAction = ActionType<typeof actions>;

const initialState: RouterModel = {};

const routerReducer = (state: RouterModel = initialState, action: RouterAction): RouterModel => {
    switch (action.type) {
        case 'router/REDIRECT':
            return {
                ...state,
                redirectTo: action.payload
            };
        case getType(actions.setReturnUrl):
            return {
                ...state,
                returnUrl: action.payload
            };
        default:
            return {
                ...state,
                redirectTo: undefined
            };
    }
}

export default routerReducer;

import React from "react";
import { Box, Typography,  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TemplateModel } from "../SetupModels";
import { KeyValueModel } from "../../shared/SharedModels";
import IconSetComponent from "../../shared/components/IconSetComponent";
import { IconSetModel } from "../../lookups/LookupModels";

type PreviewComponentProps = {
    template: TemplateModel,

    actions: KeyValueModel[],
    fontSizes: KeyValueModel[],
    titleSpacings: KeyValueModel[],
    iconSizes: KeyValueModel[],
    iconSpacings: KeyValueModel[],
    fontFamilies: KeyValueModel[],
    colorPalette: KeyValueModel[],
    iconSets: IconSetModel[],
    templateLanguages: KeyValueModel[],
};

const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: "center",
    },
}));

const PreviewComponent = (props: PreviewComponentProps) => {
    const classes = useStyles();
    const { action, size, font, color, iconSet, actionCount, titleSpacing, iconSpacing, iconSize} = props.template;
    const chosenIconSet = props.iconSets.find(set => set.key === iconSet);

    const chosenStyles = {
        fontSize: `${size}px`,
        fontFamily: font,
        color: color,
    }

    return (
        <Box className={`preview content-block ${classes.root}`}>
            <Typography style={{ ...chosenStyles }}><strong>{action}</strong></Typography>
            <Box className="icons" style={{marginTop: titleSpacing}}>
                { chosenIconSet &&
                    <IconSetComponent actionCount={actionCount} icon={chosenIconSet?.value} spacing={iconSpacing} size={iconSize} />
                }
            </Box>
        </Box>
    )
}

export default PreviewComponent;

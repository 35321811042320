export default (params) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.794" height="22" viewBox="0 0 16.794 22" fill={params.fill}>
    <g id="Group_804" data-name="Group 804" transform="translate(-2.603)">
        <g id="invoice" transform="translate(2.603)">
        <g id="Group_566" data-name="Group 566">
            <g id="Group_565" data-name="Group 565">
            <g id="Group_803" data-name="Group 803">
                <path id="Path_468" data-name="Path 468" d="M18.8.569h0a1.014,1.014,0,0,0-1.1.168L16.262,2,14.317.263a1.029,1.029,0,0,0-1.373,0L11,2,9.056.263a1.029,1.029,0,0,0-1.373,0L5.737,2,4.306.737a1.023,1.023,0,0,0-1.7.772V20.492a1.024,1.024,0,0,0,1.705.772L5.738,20l1.945,1.74a1.029,1.029,0,0,0,1.373,0L11,20l1.944,1.738a1.029,1.029,0,0,0,1.373,0L16.263,20l1.431,1.266a1.023,1.023,0,0,0,1.7-.772V1.508A1.014,1.014,0,0,0,18.8.569ZM16.944,19.226a1.029,1.029,0,0,0-1.368,0l-1.945,1.74-1.944-1.738a1.029,1.029,0,0,0-1.373,0L8.369,20.969l-1.946-1.74a1.023,1.023,0,0,0-1.364,0L3.633,20.491V17.745L3.628,1.509,5.056,2.774a1.029,1.029,0,0,0,1.368,0L8.37,1.031l1.944,1.738a1.029,1.029,0,0,0,1.373,0l1.944-1.738,1.946,1.74a1.023,1.023,0,0,0,1.364,0l1.426-1.266V14.558l.005,5.933Z" transform="translate(-2.603)" />
            </g>
            </g>
        </g>
        <g id="Group_568" data-name="Group 568" transform="translate(2.999 10.485)">
            <g id="Group_567" data-name="Group 567">
            <path id="Path_469" data-name="Path 469" d="M15.883,10.485H6.117a.515.515,0,1,0,0,1.03h9.767a.515.515,0,1,0,0-1.03Z" transform="translate(-5.602 -10.485)" />
            </g>
        </g>
        <g id="Group_570" data-name="Group 570" transform="translate(2.999 7.394)">
            <g id="Group_569" data-name="Group 569">
            <path id="Path_470" data-name="Path 470" d="M11.247,7.394H6.117a.515.515,0,0,0,0,1.03h5.13a.515.515,0,1,0,0-1.03Z" transform="translate(-5.602 -7.394)" />
            </g>
        </g>
        <g id="Group_572" data-name="Group 572" transform="translate(2.999 13.576)">
            <g id="Group_571" data-name="Group 571">
            <path id="Path_471" data-name="Path 471" d="M15.883,13.576H6.117a.515.515,0,1,0,0,1.03h9.767a.515.515,0,1,0,0-1.03Z" transform="translate(-5.602 -13.576)" />
            </g>
        </g>
        </g>
    </g>
    </svg>
)
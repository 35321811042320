import {call, put, takeLatest} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import * as actions from "./emailUnsubscribeActions";
import * as api from "../../api";
import * as authApi from "../../api/authenticationApi";
import {Tina4SignInTokenResult} from "../SignUpPage/signUpData";

export default function* emailUnsubscribeSaga() {
    yield takeLatest(getType(actions.emailUnsubscribe), emailUnsubscribe);
}

function* emailUnsubscribe(action: ActionType<typeof actions.emailUnsubscribe>) {
    const token = action.payload
    const url = "email-unsubscribe"

    try {
        const authResponseData: Response = yield call(() => authApi.initialAuthToken(url));
        const authResponseBody: Tina4SignInTokenResult = yield call(() => authResponseData);

        const response: Response = yield call(() => api.unsubscribeUserFromEmail(token, authResponseBody.access_token));

        if(response.ok) {
            yield put(actions.emailUnsubscribeSuccess());
        } else {
            yield put(actions.emailUnsubscribeFailure());
        }

    } catch (e) {
        console.error(e);
        yield put(actions.emailUnsubscribeFailure());
    }
}
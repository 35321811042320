export function addQueryParams(url: string, params: Record<string, string>): string {
    let urlObj = new URL(url);
    let paramsObj = new URLSearchParams(urlObj.search);

    for (let key in params) {
        paramsObj.set(key, params[key]);
    }

    urlObj.search = paramsObj.toString();

    return urlObj.toString();
}

import React, { Fragment, useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Box, Container, Typography } from "@material-ui/core";
import TemplatePage from "./components/Template";
import StaffMemberImportPage from "./components/StaffMemberImport";
import SignaturePage from "./components/Signature";
import ReviewsPage from "./components/Reviews";

import * as sharedSelectors from "../shared/sharedSelectors";
import { switchNavMenu } from "../shared/sharedActions";
import { redirect } from "../shared/router/routerActions";

import SetupPageStepper from "./components/SetupStepper";
import { ApplicationState } from "../ApplicationState";

type SetupPageProps = {
  navTabIsSelected: boolean;
  redirect: typeof redirect;
};

const Setup = (props: SetupPageProps) => {
  const { navTabIsSelected, redirect } = props;

  useEffect(() => {
    if (!navTabIsSelected) switchNavMenu(true);
  });

  return (
    <Box id="setup-container">
      <SetupPageStepper redirect={redirect}>
        <Switch>
          <Route
            path="/:orgId/setup/customize"
            component={TemplatePage}
          ></Route>
          <Route path="/:orgId/setup/reviews" component={ReviewsPage}></Route>
          <Route
            path="/:orgId/setup/AgentImport/:tool"
            component={StaffMemberImportPage}
          ></Route>
          <Route
            path="/:orgId/setup/signature"
            component={SignaturePage}
          ></Route>
        </Switch>
      </SetupPageStepper>
    </Box>
  );
};

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
  return {
    navTabIsSelected: sharedSelectors.navTabSelector(state),
  };
};

const mapDispatchToProps = { switchNavMenu, redirect };

const SetupContainer = connect(mapStateToProps, mapDispatchToProps)(Setup);

export default SetupContainer;

import { ApplicationState } from "../ApplicationState";
import {ReviewSiteModel} from "./SetupModels";

export const agentsSelector = (state: ApplicationState) => {
    return state.setup.import.agents;
}

export const reviewSiteSelector = (state: ApplicationState): ReviewSiteModel[] => {
    return state.setup.reviewSites;
}

export const reviewSiteOptionsSelector = (state: ApplicationState) => {
    return state.setup.reviewSiteOptions;
}

export const agentWithSnippetSelector = (state: ApplicationState) => {
    return state.setup.signature.agents;
};

export const activeAgentsSelector = (state: ApplicationState) => {
    return state.setup.import.agents.filter(a => a.active);
}

export const emailClientSelector = (state: ApplicationState) => {
    return state.setup.import.selectedEmailClient;
}

export const templateSelector = (state: ApplicationState) => {
    if(state.setup.customization.template)
        return state.setup.customization.template;
}

export const selectedIconSetSelector = (state: ApplicationState) => {
    if(state.setup.customization.template)
        return state.setup.customization.template.iconSet;
}

export const savedTemplateSelector = (state: ApplicationState) => {
    return state.setup.customization.isSaved;
}

export const isSnippetCopiedSelector = (state: ApplicationState) => {
    return state.setup.signature.isCopied;
}


export const importedUsersSelector = (state: ApplicationState) => {
    return state.setup.import.result;
}

export const isEmailedToAgentSelector = (state: ApplicationState) => {
    return state.setup.signature.isEmailedToAgent;
}

export const suggestionsSelector = (state: ApplicationState) => {
    return state.setup.suggestions;
}

const SetupIcon = (params) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.023"
    height="28.385"
    viewBox="0 0 25.023 28.385"
    fill={params.isSelected ? "#3b76ef" : "#c8dafe"}
  >
    <path
      id="path6724-4"
      d="M14.508,1.008,2.915,5.016l-.909.316H2V25.107h.026L2,25.174l.905.323,10.921,3.9,12.265-4.08.913-.3L27,25V22.822l.015-.041-.015,0V5.595l.019-.053-.9-.327Zm-.969,2.37V18.7L3.921,21.929V6.7Zm1.923.019,9.617,3.486V22.1l-9.617-3.389Zm-.969,17.014,9.835,3.464L13.85,27.357l-9.73-3.467Z"
      transform="translate(-1.998 -1.008)"
      fillRule="evenodd"
    />
  </svg>
);
export default SetupIcon;

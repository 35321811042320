import { Box, Button, createStyles, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import Icon from "../../../shared/components/Icon";
// import RMSLogo from '../../../svg/RateMyService-logo-new.svg'
import * as validation from '../../../shared/validation/validationHelper';
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { ApplicationState } from "../../../ApplicationState";
import * as errorSelectors from '../../../shared/errors/errorSelectors';
import * as selectors from "../resetPasswordSelectors";
import { ValidationError } from "../../../shared/errors/ErrorModels";
import * as resetPasswordActions from "../resetPasswordActions";
import { forgotPassword } from "../resetPasswordActions";
import * as sharedActions from "../../../shared/sharedActions";
import {ReCaptchaModel} from "../../../shared/SharedModels";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {setValidationErrors} from "../../../shared/errors/errorActions";

const useStyles = makeStyles((theme: Theme) => createStyles({
    btn: {
      backgroundColor: "#F43B3B",
      color: "#fff",
      marginTop: "10px",
    },
    agreement: {
        fontSize: "0.8rem",
    }
}));

type ForgotPasswordPageProps = {
    validationErrors: ValidationError[] | [];
    isForgotPasswordConfirmed: boolean | undefined;
    forgotPassword: typeof forgotPassword;
    verifyReCaptcha: typeof sharedActions.verifyReCaptcha;
    recaptchaResponse?: ReCaptchaModel;
};

const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = (props) => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    const classes = useStyles();
    const { validationErrors, isForgotPasswordConfirmed } = props;

    const [ email, setEmail ] = useState<string>("");

    const handleReCaptchaVerify = useCallback(async (event) => {
        event.preventDefault();
        if (!executeRecaptcha) {
            setValidationErrors([{name: 'recaptcha', message: 'reCaptcha not yet available'}]);
            return;
        }

        const token = await executeRecaptcha('/forgotpassword');
        if (token) {
            props.verifyReCaptcha(token);
        }

    }, [executeRecaptcha]);

    useEffect(() => {
        if (props.recaptchaResponse) {
            if (!props.recaptchaResponse.success) {
                setValidationErrors([{name: 'recaptcha', message: 'reCaptcha was not successful'}]);
                return;
            }
            if (props.recaptchaResponse.action !== '/forgotpassword' || props.recaptchaResponse.score < 0.6) {
                setValidationErrors([{name: 'recaptcha', message: 'reCaptcha did not pass'}]);
                return;
            }

            handleResetPassword();
        }
    }, [props.recaptchaResponse]);

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
        if (!event.target.name) {
            return;
        }

        setEmail(event.target.value);
    }

    const handleEnterPressResetPassword = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if(event.key == "Enter") {
            handleReCaptchaVerify(event);
        }
    };

    const handleResetPassword = () => {
        props.forgotPassword({
            email,
        });
    };

    return (
        <Box className='login-container-wrapper'>
            <Box className='login-container'>
                <Box className='login-box' justifyContent='space-around'>
                    <Box className='login-box-item'>
                        <Box className='sign-box'>
                            {/*<Box className="logo-container">*/}
                            {/*    <Icon src={RMSLogo} />*/}
                            {/*</Box>*/}
                            <Typography className="title" variant='h6'><strong>Reset Password</strong></Typography>

                            {isForgotPasswordConfirmed ?
                                <Box>
                                    <Typography>If account exists, an email will be sent with further instructions. Please click the link when you get it.</Typography>
                                </Box>
                                :
                                <form onKeyPress={handleEnterPressResetPassword} onSubmit={handleReCaptchaVerify}>
                                    <TextField className='sign-box-item' name='email' value={email} label="Email" variant="outlined"
                                        error={validation.hasError(validationErrors, 'Email')}
                                        helperText={validation.getErrorText(validationErrors, 'Email')}
                                        onChange={handleChange} />
                                    <Button
                                        type="submit"
                                        className={`${classes.btn} sign-box-btn`}
                                        variant="contained"
                                    >
                                        Reset
                                    </Button>
                                </form>
                            }
                        </Box>

                    </Box>
                </Box>
            </Box>
        </Box>
    )
};

const mapStateToProps = (state: ApplicationState) => {
    return {
        validationErrors: errorSelectors.validationSelector(state),
        isForgotPasswordConfirmed: selectors.forgotPasswordConfirmedSelector(state),
        recaptchaResponse: state.globals.reCaptchaResponse,
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        forgotPassword: resetPasswordActions.forgotPassword,
        verifyReCaptcha: sharedActions.verifyReCaptcha,
    }, dispatch);
};

const ForgotPasswordPageContainer = connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);

export default ForgotPasswordPageContainer;

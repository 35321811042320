import { ApplicationState } from "../ApplicationState";
import { AdministratorModel, CreditCardModel, InvoiceModel, StripeSetupModel } from "./AccountModels";

export const billingInfoSelector = (state: ApplicationState) => {
    return state.account.billingInfo;
}

export const creditCardsSelector = (state: ApplicationState): CreditCardModel[] => {
    return state.account.creditCards;
}

export const stripeSettingsSelector = (state: ApplicationState): StripeSetupModel => {
    return state.account.stripe;
}

export const invoicesSelector = (state: ApplicationState): InvoiceModel[] =>  {
    return state.account.invoice.all;
}

export const usersSelector = (state: ApplicationState): AdministratorModel[] =>  {
    return state.account.users.all;
}

export const successfulPaymentFlagSelector = (state: ApplicationState): boolean => {
    return state.account.isSuccessfulPayment;
}

export const subscriptionUpdatedFlagSelector = (state: ApplicationState): boolean => {
    return state.account.isSubscriptionUpdated;
}

export const subscriptionDeactivatedFagSelector = (state: ApplicationState): boolean => {
    return state.account.isSubscriptionDeactivated;
}

export const userInvitedFlagSelector = (state: ApplicationState): boolean => {
    return state.account.users.isUserInvited;
};

export const couponCodesSelector = (state: ApplicationState) => {
    return state.account.couponCodes.all;
}
import React from "react";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@material-ui/core";
import { KeyValueModel } from "./SharedModels";


export interface Props {
    id: string,
    className?: string,
    name: string,
    label?: string,
    value?: any,
    items?: KeyValueModel[],
    onChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    displayEmpty?: boolean;
    useValueAsFontFamily?: boolean;
}

export const SelectList: React.SFC<Props> = (props) => {

    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = React.useState(0);

    React.useEffect(() => {
        setLabelWidth(inputLabel.current!.offsetWidth);
    }, []);

    const { id, label, name, value, onChange, className, items, disabled, error, helperText, displayEmpty,useValueAsFontFamily } = props;

    return (
        <FormControl variant="outlined" margin="dense" className={className} error={error} >
            <InputLabel ref={inputLabel} htmlFor={id}>{label}</InputLabel>
            <Select style={{width: "100%"}} value={value} onChange={onChange} displayEmpty={displayEmpty} labelWidth={labelWidth} name={name} id={id} disabled={disabled} >
                {items && items.map(i => <MenuItem key={i.key} style={useValueAsFontFamily ? {fontFamily: i.value} : {}} value={i.value}>{i.value}</MenuItem>)}
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}
import {createStyles, Dialog, DialogContent, DialogTitle, makeStyles, Theme, Typography} from "@material-ui/core";
import TrialIcon from '../../png/trial_icon.png';
import Icon from "./Icon";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => createStyles({
    dialogTitle: {
        paddingTop: "24px",
        fontWeight: "bold",
    },
    dialogContent: {
        marginBottom: "2rem"
    }
}));

const TrialExpiredPopup = () => {
    const classes = useStyles();
    return (
        <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ textAlign: "center" }}
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
                <Icon src={TrialIcon} />
                <Typography variant="h4" component="h2" style={{ fontWeight: "bold" }}>Your Trial Period has expired</Typography>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography>Please speak to your Sales Contact in order to activate a Paid Subscription.</Typography>
            </DialogContent>
        </Dialog>
    );
}

export default TrialExpiredPopup;

import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    FormControl,
    InputAdornment,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@material-ui/core";
import SectionComponent from "../../shared/components/SectionComponent";
import {connect} from "react-redux";

import * as selectors from "../reviewsSelectors";
import * as lookupSelectors from "../../lookups/lookupSelectors";
import * as sharedSelectors from "../../shared/sharedSelectors";
import {ApplicationState} from "../../ApplicationState";
import {appSettings} from "../../config/appSettings";
import {ReviewsModel} from "../reviewsModels";
import {KeyValueModel, UserProfileModel} from "../../shared/SharedModels";
import {setIsSearching} from "../../shared/sharedActions";
import useDebounce from "../../shared/helpers/debounceHook";
import Icon from "../../shared/components/Icon";
import CustomerIcon from "../../svg/feedback/customer.svg";
import ListIcon from "../../svg/feedback/list.svg";
import MagnifierIcon from "../../svg/feedback/magnifier.svg";
import MessageIcon from "../../svg/feedback/message.svg";
import SmileyIcon from "../../svg/feedback/smiley.svg";
import {getAllReviews} from "../reviewsActions";
import Pagination from "@material-ui/lab/Pagination";
import ClockIcon from "../../svg/feedback/clock.svg";
import {redirect} from "../../shared/router/routerActions";
import {Route} from "react-router";
import ViewMessagePopup from "./ViewMessageComponent";
import {Redirect, useHistory} from "react-router-dom";

type ReviewsProps = {
    profile: UserProfileModel | undefined;
    reviews: ReviewsModel[];
    reviewRatingFilter: KeyValueModel[];
    reviewSiteFilter: KeyValueModel[];
    dataRangeFilter: KeyValueModel[];
    pageFilter: KeyValueModel[];
    reviewCount: number;

    getAllReviews: typeof getAllReviews;
    setIsSearching: typeof setIsSearching;
    redirect: typeof redirect;
};

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
        border: "2px solid #fff",
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
    },
    tableRow: {
        height: 30,
    },
    cell: {
        width: "150px",
    },
    msgCell: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    btnCell: {
        width: "200px",
    },
    pagination: {
        marginTop: "1rem",
        display: "flex",
        justifyContent: "center",
    },
    previewBtnDiv: {
        margin: "1rem 0",
        display: "flex",
        justifyContent: "flex-end",
    },
    previewBtn: {
        fontWeight: "bold",
        color: "white",
        backgroundColor: "#e67e22",
        borderRadius: "5px",
    },
}));

const Reviews: React.FC<ReviewsProps> = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const {
        profile,
        reviews,
        reviewCount,
        reviewRatingFilter,
        reviewSiteFilter,
        dataRangeFilter,
        getAllReviews,
    } = props;

    const organization = profile?.organization;
    const organizationId = profile?.organization.id;
    const defaultUserId = profile?.organization.defaultUserId;

    const [searchString, setSearchString] = useState("");
    const [redirect, setRedirect] = useState('');
    const debouncedSearchString = useDebounce(searchString, 500);

    const initialFilters = {
        rating: "",
        reviewSiteId: "",
        dataRange: "",
        page: "0",
        searchString: debouncedSearchString,
    };
    const [filters, setFilters] = useState(initialFilters);

    const initialMsgPopupState = {
        open: false,
        message: "",
    };
    const [messagePopupState, setMessagePopupState] =
        useState(initialMsgPopupState);

    useEffect(() => {
        if (redirect.length > 0) {
            setRedirect('');
        }
    }, [redirect]);

    useEffect(() => {
        getAllReviews(filters);
    }, [filters, organization]);

    useEffect(() => {
        setIsSearching(true);

        setCurrentPage(1);
        setFilters((prev) => {
            return {
                ...prev,
                searchString: debouncedSearchString,
            };
        });
    }, [debouncedSearchString]);

    const handleChange = (
        event: React.ChangeEvent<{ name?: string; value: any }>
    ) => {
        if (event.target.name === "searchString") {
            setSearchString(event.target.value);
            return;
        }

        setCurrentPage(1);
        setFilters((prev) => {
            return {
                ...prev,
                [event.target.name!]: event.target.value,
            };
        });
    };

    const handleOnOpenPopup = (review: ReviewsModel) => {
        setMessagePopupState({
            open: true,
            message: review.text,
        });
        setRedirect(`/org${profile!.organizationId}/reviews/searchresults/${review.id}`);
    };

    const handleOnClosePopup = () => {
        setMessagePopupState(initialMsgPopupState);
        history.push(`/org${profile!.organizationId}/reviews`)
    };

    const handleGetViewedMessage = (id: number) => {
        const viewedReview = reviews.find((f) => f.id === id);
        setMessagePopupState({
            open: true,
            message: viewedReview ? viewedReview.text : "",
        });
    };

    const pageCount = Math.ceil(reviewCount / 10);
    const [currentPage, setCurrentPage] = React.useState(1);
    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setCurrentPage(value);
    };

    useEffect(() => {
        setFilters((prev) => {
            return {
                ...prev,
                page: currentPage.toString(),
            };
        });
    }, [currentPage]);

    const decodeHtml = (html: string) => {
        if (html.trim().length === 0) return "Customer left no comment.";
        const txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    };

    if (redirect) {
        return <Redirect to={redirect} />;
    }

    return (
        <Box id="feedback-container">
            <Box display="flex" justifyContent="space-between">
                <Box className="filter-container">
                    <Typography>Rating</Typography>
                    <FormControl
                        variant="outlined"
                        margin="dense"
                        className="select-holder filter-list"
                    >
                        <InputLabel htmlFor="reviewRatingFilter"/>
                        <Select
                            id="reviewRatingFilter"
                            name="rating"
                            value={filters.rating}
                            displayEmpty
                            onChange={handleChange}
                        >
                            <MenuItem value="">Show All</MenuItem>
                            {reviewRatingFilter &&
                                reviewRatingFilter.map((i) => (
                                    <MenuItem key={i.key} value={i.key}>
                                        {i.value}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box className="filter-container">
                    <Typography>Review Site</Typography>
                    <FormControl
                        variant="outlined"
                        margin="dense"
                        className="select-holder filter-list"
                    >
                        <InputLabel htmlFor="reviewSiteFilter"/>
                        <Select
                            id="reviewSiteFilter"
                            name="reviewSiteId"
                            value={filters.reviewSiteId}
                            displayEmpty
                            onChange={handleChange}
                        >
                            <MenuItem value="">Show All</MenuItem>
                            {reviewSiteFilter &&
                                reviewSiteFilter.map((i) => (
                                    <MenuItem key={i.key} value={i.key}>
                                        {i.value}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box className="filter-container">
                    <Typography>Data Range</Typography>
                    <FormControl
                        variant="outlined"
                        margin="dense"
                        className="select-holder filter-list"
                    >
                        <InputLabel htmlFor="rangeFilter"/>
                        <Select
                            id="rangeFilter"
                            name="dataRange"
                            displayEmpty
                            value={filters.dataRange}
                            onChange={handleChange}
                        >
                            <MenuItem value="">Show All</MenuItem>
                            {dataRangeFilter &&
                                dataRangeFilter.map((i) => (
                                    <MenuItem key={i.key} value={i.key}>
                                        {i.value}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Box>
                <TextField
                    name="searchString"
                    value={searchString}
                    label="Search"
                    variant="outlined"
                    margin="dense"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon src={MagnifierIcon}/>
                            </InputAdornment>
                        ),
                    }}
                    onChange={handleChange}
                />
            </Box>
            <div className={classes.previewBtnDiv}>
                <Button
                    href={`${appSettings.reviewsApp.submitReviewUrl}/${organizationId}/${defaultUserId}/2`}
                    target="_blank"
                    variant="contained"
                    className={classes.previewBtn}
                    disabled={reviews.length < 1 || false}
                >
                    Preview Review Funnel
                </Button>
            </div>
            <SectionComponent marginTop="33px">NEW REVIEWS</SectionComponent>
            <TableContainer>
                <Table
                    className={`${classes.table} tbl`}
                    size="small"
                    aria-label="a dense table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cell} align="center">
                                <Box display="flex" alignItems="center">
                                    <Icon className="margin-right" src={ListIcon}/>
                                    <Typography>Review Site</Typography>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.cell} align="center">
                                <Box display="flex" alignItems="center">
                                    <Icon className="margin-right" src={SmileyIcon}/>
                                    <Typography>Rating</Typography>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.cell} align="center">
                                <Box display="flex" alignItems="center">
                                    <Icon className="margin-right" src={ClockIcon}/>
                                    <Typography>Date and Time</Typography>
                                </Box>
                            </TableCell>
                            <TableCell className={classes.cell} align="center">
                                <Box display="flex" alignItems="center">
                                    <Icon className="margin-right" src={CustomerIcon}/>
                                    <Typography>Customer Name</Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center">
                                <Box display="flex" alignItems="center">
                                    <Icon className="margin-right" src={MessageIcon}/>
                                    <Typography>Message</Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reviews?.length > 0 &&
                            reviews.map((review) => {
                                return (
                                    <TableRow className={classes.tableRow} key={review.id}>
                                        <TableCell align="left">{review.reviewSite}</TableCell>
                                        <TableCell align="center">
                                            <div className="review-rating">{review.rating}</div>
                                        </TableCell>
                                        <TableCell align="center">
                                            {review.time &&
                                                review.time.toLocaleString("en-US", {
                                                    year: "numeric",
                                                    month: "numeric",
                                                    day: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    hour12: false,
                                                })}
                                        </TableCell>
                                        <TableCell align="left">{review.name}</TableCell>
                                        <TableCell className={classes.msgCell} align="left">
                                            {decodeHtml(review.text)}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleOnOpenPopup(review)}
                                            >
                                                View
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={classes.pagination}>
                <Pagination
                    color="primary"
                    count={pageCount}
                    page={currentPage}
                    onChange={handlePageChange}
                />
            </div>
            <Route path="/:orgId/reviews/searchresults/:id">
                {reviews?.length > 0 && (
                    <ViewMessagePopup
                        open={true}
                        message={messagePopupState.message}
                        handleClose={handleOnClosePopup}
                        handleGetMessage={handleGetViewedMessage}
                    />
                )}
            </Route>
        </Box>
    );
};

const mapStateToProps = (state: ApplicationState) => {
    return {
        profile: sharedSelectors.profileSelector(state),
        reviews: selectors.reviewsSelector(state),
        reviewCount: selectors.reviewCountSelector(state),
        isSearching: sharedSelectors.isSearchingSelector(state),

        reviewRatingFilter: lookupSelectors.reviewRatingFilterSelector(state),
        dataRangeFilter: lookupSelectors.dataRangeFilterSelector(state),
        reviewSiteFilter: lookupSelectors.reviewSiteSelector(state),
        pageFilter: lookupSelectors.pageFilterSelector(state),
    };
};

const mapDispatchToProps = {
    getAllReviews,
    setIsSearching,
    redirect,
};

const ReviewsContainer = connect(mapStateToProps, mapDispatchToProps)(Reviews);

export default ReviewsContainer;

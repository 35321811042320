const OfficeWorkerIcon = (params) => (

  // <svg xmlns="http://www.w3.org/2000/svg" width="25.009" height="25.04" viewBox="0 0 25.009 25.04">
  <svg xmlns="http://www.w3.org/2000/svg" className={ params.className ? params.className : "" } width={ params.width ? params.width : 25.009 } height={ params.height ? params.height : 25.04 } viewBox="0 0 25.009 25.04">
    <g id="Group_802" data-name="Group 802" transform="translate(-115.5 -75.5)">
      <g id="Group_450" data-name="Group 450" transform="translate(-5984 -6156)">
        <g id="g2390" transform="translate(6100 6232)">
          <ellipse id="circle2373" cx="5.54" cy="5.559" rx="5.54" ry="5.559" transform="translate(6.466 0)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
          <path id="path2385" d="M-1921.812-168.54h23.936m-23.936,0q-.037-.45-.036-.9a11.792,11.792,0,0,1,12-11.568,11.793,11.793,0,0,1,12,11.568h0q0,.451-.037.9" transform="translate(1921.848 192.54)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
        </g>
        <path id="Path_443" data-name="Path 443" d="M46.658,53.876,45.8,47.239a1.448,1.448,0,0,0,.6-1.15h0A1.541,1.541,0,0,0,44.829,44.6a1.558,1.558,0,0,0-1.568,1.489h0a1.411,1.411,0,0,0,.6,1.15L43,53.876h0V53.9a.282.282,0,0,0,.078.183.3.3,0,0,0,.209.078h3.083a.3.3,0,0,0,.209-.078.237.237,0,0,0,.078-.183v-.026Z" transform="translate(6068.077 6200.602)"/>
      </g>
    </g>
  </svg>
)

export default OfficeWorkerIcon;
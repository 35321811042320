import React, { useState } from 'react';
import { Box, Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core';
import { OrganizationModel } from '../../shared/SharedModels';
import { UpdateBillingInfoModel } from '../AccountModels';
import { updateBillingInfo } from '../accountActions';
import { numberWithDivider } from "../../shared/helpers"; 

import * as validation from '../../shared/validation/validationHelper';
import { ValidationError } from '../../shared/errors/ErrorModels';

type BillingInfoProps = {
    org: OrganizationModel,
    validationErrors: ValidationError[],

    updateBillingInfo: typeof updateBillingInfo,
};

type UpdateBillingInfoPopupProps = {
    detailsToUpdate: UpdateBillingInfoModel,
    open: boolean,
    validationErrors: ValidationError[],

    handleClose: () => void,
    handleChange: (event: React.ChangeEvent<{ name?: string; value: any }>) => void,
    handleSave: () => void,
};

const UpdateBillingInfoPopup: React.FC<UpdateBillingInfoPopupProps> = (props) => {
    const { detailsToUpdate, open, validationErrors, handleClose, handleChange, handleSave } = props;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Update Details</DialogTitle>
            <DialogContent>
                <TextField 
                    name='organizationFullName'
                    value={detailsToUpdate.organizationFullName}
                    label="Company Name"
                    variant="outlined"
                    margin="dense"
                    error={validation.hasError(validationErrors, 'OrganizationName')}
                    helperText={validation.getErrorText(validationErrors, 'OrganizationName')}
                    onChange={handleChange}
                />
                <TextField
                    name='user'
                    value={detailsToUpdate.user}
                    label="Full Name"
                    variant="outlined"
                    margin="dense"
                    error={validation.hasError(validationErrors, 'FullName')}
                    helperText={validation.getErrorText(validationErrors, 'FullName')}
                    onChange={handleChange}
                />
                <TextField
                    name='email'
                    value={detailsToUpdate.email}
                    label="Email"
                    variant="outlined"
                    margin="dense"
                    error={validation.hasError(validationErrors, 'Email')}
                    helperText={validation.getErrorText(validationErrors, 'Email')}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button color="secondary" onClick={handleSave}>SAVE</Button>
            </DialogActions>
        </Dialog>
    )
}

const BillingInfo = (props: BillingInfoProps) => {
    const { org, validationErrors, updateBillingInfo } = props;
    const initialState = {
        organizationFullName: org.name,
        user: org.defaultUserFullName,
        email: org.defaultEmail,
    };

    const [billingInfo, setBillingInfo] = useState<UpdateBillingInfoModel>(initialState);

    const [openUpdateBillingInfoPopup, setOpenUpdateBillingInfoPopup] = useState<boolean>(false);

    const handleUpdatePopupOpen = () => {
        setBillingInfo(initialState);
        setOpenUpdateBillingInfoPopup(true);
    };

    const handleCancelClick = () => {
        handleClose();
    };

    const handleSaveClick = () => {
        updateBillingInfo(billingInfo);
        props.org.name = billingInfo.organizationFullName;
        org.defaultUserFullName = billingInfo.user;
        org.defaultEmail = billingInfo.email;
        // implement normal save & close popup flow
        handleClose();
    };

    const handleClose = () => {
        setOpenUpdateBillingInfoPopup(false);
    }

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
        if (!event.target.name) {
            return;
        }

        setBillingInfo(prev => {
            return {
                ...prev,
                [event.target.name!]: event.target.value, 
            }
        });
    };

    return (
        <Box id="billinginfo-container">
            <Box className="billinginfo-content">
                <Box className="billinginfo-company">
                    {/* change margin top */}
                    <Typography variant="h4">Org ID: {numberWithDivider(props.org.id)}</Typography>
                    <Box className="company-name">
                        <Typography>Company Name:</Typography>
                        <Typography variant="h5"><strong>{props.org.name}</strong></Typography>
                    </Box>
                    <Button variant="contained" color="secondary" onClick={handleUpdatePopupOpen}>Update Details</Button>
                </Box>
                <Box className="billinginfo-deputy">
                    <Box className="fullName">
                        <Typography>Full Name</Typography>
                        <Typography><strong>{org.defaultUserFullName}</strong></Typography>
                    </Box>
                    <Box>
                        <Typography>E-mail Address</Typography> 
                        <Typography color="primary">{org.defaultEmail}</Typography>
                    </Box>
                </Box>
            </Box>
            <UpdateBillingInfoPopup
                open={openUpdateBillingInfoPopup}
                detailsToUpdate={billingInfo}
                validationErrors={validationErrors}

                handleClose={handleCancelClick}
                handleSave={handleSaveClick}
                handleChange={handleChange}
            />
        </Box>
    )
}

export default BillingInfo;
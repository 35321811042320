import React, { useState } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import * as select from './errorSelectors';
import { removeError } from './errorActions';
import { AlertModel, CustomError } from './ErrorModels';
import { ApplicationState } from '../../ApplicationState';

function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type Props = {
    errs: CustomError[],
    removeError: typeof removeError,
}

const SnackBarError = (props: Props) => {
    const { errs, removeError } = props;
    const [open, setOpen] = useState(true);

    const handleForceErrorClear = (errId: number) => {
        removeError(errId);
    }

    // const handleClose = (event: React.SyntheticEvent<any>, reason: any) => {
    //     if (reason === 'clickaway') {
    //       return;
    //     }
    //     setOpen(false);
    //     // handleForceErrorClear(err.id);
    // }

    const handleClose = (id: number) => {
        // setOpen(false);
        handleForceErrorClear(id);
    }

    return (
        <Snackbar open={open} autoHideDuration={6000} >
            <React.Fragment>
                { errs.map((err: AlertModel, index: number) => {
                    return (
                        <Alert key={index} id={err.id} onClose={() => handleClose(err.id)} severity={ err.severity ? err.severity : "error" }>
                            { err.message || "Internal error" }
                        </Alert>)
                }) }
            </React.Fragment>
        </Snackbar>
    )
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        errs: select.snackBarErrorsSelector(state),
    }  
} 

const mapDispatchToProps = { removeError }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SnackBarError);
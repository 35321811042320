import { ApplicationState } from "../ApplicationState";

export const customerSelector = (state: ApplicationState) => {
    return state.feedbackSubmission.customer;
}

export const submissionRedirectSelector = (state: ApplicationState) => {
    return state.feedbackSubmission.customer?.submissionRedirect;
}

export const isSubmitSucceededSelector = (state: ApplicationState) => {
    return state.feedbackSubmission.isSubmitSucceeded;
}

export const feedbackSelector = (state: ApplicationState) => {
    return state.feedback.all;
}

export const viewedFeedbackSelector = (state: ApplicationState) => {
    return state.feedback.viewed;
}

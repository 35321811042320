import React, { RefObject, useEffect } from "react";
import { Box } from '@material-ui/core';
import Chart, { ChartConfiguration } from 'chart.js/auto';
import { SummaryByDateRangeModel } from "../ReportingModels";
import SectionComponent from "../../shared/components/SectionComponent";

type ChartProps = {
    chartSummary?: SummaryByDateRangeModel,
};

declare const window: any;

const ChartComponent: React.FC<ChartProps> = (props) => {

    const chartRef: RefObject<HTMLCanvasElement> = React.useRef<HTMLCanvasElement>(null);

    const config: ChartConfiguration = {
        type: "line",
        data: {
            labels: props.chartSummary && props.chartSummary.labels,
            datasets: props.chartSummary ? props.chartSummary.summaries : [],
        },
        options: {
            plugins: {
                legend: {
                    position: "right",

                    labels: {
                        usePointStyle: true,

                    }
                },
            },
            layout: {
                padding: {
                    left: 25,
                    top: 25,
                    bottom: 25,
                }
            },
            scales: {
                x: {
                    grid: {
                        drawOnChartArea: false,
                    }
                },
                y: {
                    ticks: {
                        precision: 0,
                    },
                    min: 0,
                },

            }
        } 
    };



    useEffect(() => {

        if(chartRef.current) {
            if(window.chart instanceof Chart)
            {
                window.chart.destroy();
            }

            const ctx = chartRef.current!.getContext('2d');
            if(ctx) window.chart = new Chart(ctx, config);
        }
    }, [props.chartSummary]);

    return (
        <>
            {
                props.chartSummary &&
                <>
                    <SectionComponent width="558px" marginTop="40px">
                        RATING OVER TIME (LAST 12 MONTHS)
                    </SectionComponent>
                    <Box className="chart-container content-block">
                        <canvas className="chart-canvas" ref={chartRef} ></canvas>
                    </Box>
                </>
            }
        </>
    )
}

export default ChartComponent;
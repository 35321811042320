import { ActionType, getType } from 'typesafe-actions';

import * as actions from './errorActions';
import { ErrorsModel } from "./ErrorModels";

export type ErrorsAction = ActionType<typeof actions>;

const initialErrorsState = {
    stack: [],
    server: null,
    validation: [],
}

const errorsReducer = (state: ErrorsModel = initialErrorsState, action: ErrorsAction) => {
    switch(action.type) {
        case getType(actions.addError):
            return {
                ...state,
                stack: [ ...state.stack, action.payload],
            };
        case getType(actions.removeError):
            return {
                ...state,
                stack: state.stack.filter(err => err.id !== action.payload),
            }
        case getType(actions.addServerError):
            return {
                ...state,
                server: action.payload,
            };
        case getType(actions.removeServerError):
            return {
                ...state,
                server: null,
            }
        case getType(actions.setErrors):
            return {
                ...state,
                stack: action.payload,
            }
        case getType(actions.clearErrors):
            return {
                ...state,
                stack: [],
            }
        case getType(actions.setValidationErrors):
            return {
                ...state,
                validation: action.payload,
            }
        case getType(actions.clearValidationErrors):
            return {
                ...state,
                validation: [],
            }
        case getType(actions.addAlert):
            return {
                ...state,
                stack: [ ...state.stack, action.payload],
            }
        default:
            return state;
    }
}

export default errorsReducer;
const TutorialIcon = (params) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="svg25"
    version="1.1"
    viewBox="0 0 179 60"
    height="60"
    width="179"
  >
    <defs id="defs13">
      <filter
        filterUnits="userSpaceOnUse"
        height="60"
        width="179"
        y="0"
        x="0"
        id="Rectangle_4696"
      >
        <feOffset id="feOffset2" input="SourceAlpha" dy="1" />
        <feGaussianBlur id="feGaussianBlur4" result="blur" stdDeviation="1" />
        <feFlood id="feFlood6" floodOpacity="0.349" />
        <feComposite
          result="result1"
          id="feComposite8"
          in2="blur"
          operator="in"
        />
        <feComposite id="feComposite10" in2="result1" in="SourceGraphic" />
      </filter>
    </defs>
    <g transform="translate(-13 -929)" id="rms-video-support-btn">
      <g
        id="g16"
        filter="url(#Rectangle_4696)"
        transform="matrix(1, 0, 0, 1, 13, 929)"
      >
        <rect
          fill="#fff"
          transform="translate(3 2)"
          rx="27"
          height="54"
          width="173"
          data-name="Rectangle 4696"
          id="Rectangle_4696-2"
        />
      </g>
      <g transform="translate(-9)" data-name="Group 6084" id="Group_6084">
        <rect
          strokeWidth="2"
          strokeLinejoin="round"
          strokeLinecap="round"
          stroke="#3b76ef"
          fill="none"
          transform="translate(41.706 947.869)"
          rx="3.927"
          height="21.619"
          width="27.023"
          data-name="Rectangle 4697"
          id="Rectangle_4697"
        />
        <path
          strokeWidth="2"
          strokeLinejoin="round"
          strokeLinecap="round"
          stroke="#3b76ef"
          fill="none"
          transform="translate(39.428 945.898)"
          d="M12.24,7.234l8.59,4.584a1.091,1.091,0,0,1,0,1.924l-8.59,4.584a1.091,1.091,0,0,1-1.6-.962V8.2A1.091,1.091,0,0,1,12.24,7.234Z"
          data-name="Path 3786"
          id="Path_3786"
        />
      </g>
      <text
        fontWeight="700"
        fontFamily="Nunito-Regular, Nunito"
        fontSize="15"
        fill="#4b5a7a"
        transform="translate(69 964)"
        data-name="Video Support"
        id="Video_Support"
      >
        <tspan id="tspan21" y="0" x="0">
          Video Support
        </tspan>
      </text>
    </g>
  </svg>
);

export default TutorialIcon;

import { getType } from 'typesafe-actions';
import { EmailClientTypes } from './setupEnums';
import { SetupModel } from './SetupModels';

import * as actions from "./setupActions";

const initialSetupState: SetupModel = {
    import: {
        agents: [],
        selectedEmailClient: EmailClientTypes.gsuite,
        result: [],
    },
    customization: {
        isSaved: false,
    },
    signature: {
        agents: [],
        isCopied: false,
        isEmailedToAgent: false,
    },
    surveyForm: [],
    reviewSites: [],
    reviewSiteOptions: [],
    suggestions: [],
}

const setupReducer = (state: SetupModel = initialSetupState, action: any) => {

    switch(action.type) {
        case getType(actions.getAgentsCompleted):
            return {
                ...state,
                import: {
                    ...state.import,
                    agents: action.payload,
                },
            }
        case getType(actions.editAgentCompleted):
            return {
                ...state,
                import: {
                    ...state.import,
                    // or how
                    agents: state.import.agents.map(agent =>
                        agent.id === action.payload.id ? { ...agent, ...action.payload } : agent),
                },
            }
        case getType(actions.addAgentCompleted):
            return {
                ...state,
                import: {
                    ...state.import,
                    agents: [...state.import.agents, action.payload],
                },
            }
        case getType(actions.selectEmailClient):
            return {
                ...state,
                import: {
                    ...state.import,
                    selectedEmailClient: action.payload,
                },
            }
        case getType(actions.getTemplateCompleted):
            return {
                ...state,
                customization: {
                    ...state.customization,
                    template: action.payload,
                }
            }
        case getType(actions.updateTemplate):
            return {
                ...state,
                customization: {
                    ...state.customization,
                    template: action.payload,
                    isSaved: true,
                }
            }
        case getType(actions.changeSaveTemplateFlag):
            return {
                ...state,
                customization: {
                    ...state.customization,
                    isSaved: action.payload,
                }
            }
        case getType(actions.importCompleted):
            return {
                ...state,
                import: {
                    ...state.import,
                    result: action.payload,
                }
            }
        case getType(actions.clearImportResult):
            return {
                ...state,
                import: {
                    ...state.import,
                    result: [],
                }
            }
        case getType(actions.getAgentsWithSnippetByOrgCompleted):
            return {
                ...state,
                signature: {
                    ...state.signature,
                    agents: action.payload,
                },
            }
        case getType(actions.changeCopySnippetFlag):
            return {
                ...state,
                signature: {
                    ...state.signature,
                    isCopied: action.payload,
                }
            }
        case getType(actions.setAlertNotification):
            return {
                ...state,
                signature: {
                    ...state.signature,
                    isEmailedToAgent: action.payload,
                }
            }
        case getType(actions.getSuggestionsCompleted):
            return {
                ...state,
                suggestions: action.payload,
            }
        case getType(actions.toggleReviewSiteIsActiveCompleted):
            return {
                ...state ,
                reviewSites: state.reviewSites.map(site =>
                    site.id === action.payload.id ? { ...site, ...action.payload } : site),
            }
        case getType(actions.addReviewSiteCompleted):
            return {
                ...state ,
                reviewSites: [...state.reviewSites ,action.payload],
            }
        case getType(actions.deleteReviewSiteCompleted):
            return {
                ...state ,
                reviewSites: state.reviewSites.filter(u => u.id !== action.payload),
            }
        case getType(actions.getReviewSitesCompleted):
            return {
                ...state ,
                reviewSites: action.payload,
            }
        case getType(actions.getReviewSiteOptionsCompleted):
            return {
                ...state ,
                reviewSiteOptions: action.payload,
            }
        case getType(actions.updateTemplateFeedbackOption):
            return {
                ...state,
                customization: {
                    ...state.customization,
                    template: {
                        ...state.customization.template,
                        feedbackOption: action.payload.feedbackOption,
                    }
                }
            }
        default:
            return {
                ...state,
            };
    }
}

export default setupReducer;

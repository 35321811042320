import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

type ViewMessagePopupProps = {
    open: boolean,
    message: string,

    handleClose: () => void,
    handleGetMessage: (id: number) => void,
}

const useStyles = makeStyles((theme) => ({
    popup: {
        overflow: "hidden",
        textOverflow: "ellipsis",
    }
}));

const ViewMessagePopup: React.FunctionComponent<RouteComponentProps<{ id?: string }> & ViewMessagePopupProps> = (props) => {
    const classes = useStyles();
    const { id } = props.match.params;

    useEffect(() => {        
        if(!props.message) props.handleGetMessage(Number(id));
        
    }, []);

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            fullWidth={true}
            maxWidth="sm"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Message
            </DialogTitle>
            <DialogContent className={classes.popup}>
                <Box display="flex">
                    <Typography>{props.message}</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )

}

export default withRouter(ViewMessagePopup);
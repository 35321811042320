import { Feedback } from "./FeedbackData"
import { FeedbackModel, Filters } from "./FeedbackModels"

export const feedbackToModel = (data: Feedback): FeedbackModel => {
    return {
        id: data.id,
        createdDate: data.createdDate ? new Date(data.createdDate) : undefined,
        organizationId: data.organizationId,
        agentId: data.agentId,
        agentName: data.agentName,
        customerName: data.customerName,
        message: data.message,
        
        // 
        iconPath: data.iconPath,
        iconType: data.iconType,
        customerSatisfactionType: data.customerSatisfactionType,
        surveyAnswerId: data.surveyAnswerId,
        surveyId: data.surveyId,
        surveyAnswerTemplateId: data.surveyAnswerTemplateId,
        rate: data.rate,
        backgroundColor: data.backgroundColor,
        color: data.color,
        isArchived: data.isArchived,
        organisationFeedback: data.organisationFeedback,
    }
}

export const feedbackFromModel = (model: FeedbackModel): Feedback => {
    return {
        id: model.id,
        createdDate: model.createdDate ? model.createdDate : undefined,
        organizationId: model.organizationId,
        agentId: model.agentId,
        agentName: model.agentName,
        customerName: model.customerName,
        message: model.message,
        
        // 
        iconPath: model.iconPath,
        iconType: model.iconType,
        customerSatisfactionType: model.customerSatisfactionType,
        surveyAnswerId: model.surveyAnswerId,
        surveyId: model.surveyId,
        surveyAnswerTemplateId: model.surveyAnswerTemplateId,
        rate: model.rate,
        backgroundColor: model.backgroundColor,
        color: model.color,
        isArchived: model.isArchived,
        organisationFeedback: model.organisationFeedback,
        
        // SurveyAnswerId: model.SurveyAnswerId,
    }
}

export const filtersFromModel = (model: Filters) => {
    const filters = Object.keys(model);
    const data = Object.assign(model);

    filters.forEach(k => {
        if(data[k] === "showall") data[k] = "";
    });

    return data;
}
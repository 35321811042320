import { ForgotPassword, ResetPassword } from "./ResetPasswordData";
import { ForgotPasswordModel, ResetPasswordModel } from "./ResetPasswordModels";

export const forgotPasswordFromModel = (model: ForgotPasswordModel): ForgotPassword => {
    return {
        email: model.email,
    }
};

export const resetPasswordFromModel = (model: ResetPasswordModel): ResetPassword => {
    return {
        password: model.password,
        confirmPassword: model.confirmPassword,
        email: model.email,
        token: model.token,
    }
};
import React, {useEffect} from "react";
import {Route} from "react-router-dom";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {appSettings} from "../../config/appSettings";
import {Box} from "@material-ui/core";
import {getCurrentDomainName} from "../helpers";
import Loading from "./Loading";

const ReCaptchaRouteComponent: React.FC<any> = (props) => {
    const [siteKey, setSiteKey] = React.useState<string>(appSettings.reCaptchaSiteKey);

    useEffect(() => {
        if (props.whiteLabel && props.whiteLabel.recaptchaSiteKey) {
            setSiteKey(props.whiteLabel.recaptchaSiteKey);
        }
    }, [props.whiteLabel]);

    if (!siteKey) {
        return <Box display={"flex"} justifyContent={"center"} padding={"1rem"}><Loading message={"Validating your browser."} /></Box>;
    }
    return (
        <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
            <Route
                {...props}
            >
                {props.children}
            </Route>
        </GoogleReCaptchaProvider>

    );
}

export default ReCaptchaRouteComponent;

const ReportingIcon = (params) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.023"
    height="29.178"
    viewBox="0 0 25.023 29.178"
    fill={params.isSelected ? "#3b76ef" : "#c8dafe"}
  >
    <g id="XMLID_71_" transform="translate(10 -141.1)">
      <path
        id="XMLID_89_"
        d="M45.84,188.39a2.865,2.865,0,0,1-2.9-2.827v-4.429L30.593,181.1a.6.6,0,0,0-.593.57v23.472a.637.637,0,0,0,.621.581H49.944a.549.549,0,0,0,.519-.581V188.39Zm-9,12.6a1.14,1.14,0,0,1-2.28,0V188.675a1.14,1.14,0,1,1,2.28,0Zm4.56,0a1.14,1.14,0,0,1-2.28,0v-9.348a1.14,1.14,0,1,1,2.28,0Zm4.56,0a1.14,1.14,0,0,1-2.28,0V194.6a1.14,1.14,0,0,1,2.28,0Z"
        transform="translate(-37.72 -37.72)"
        fill="none"
      />
      <path
        id="XMLID_113_"
        d="M297.621,211.666h2.964L297,208.2v2.924A.6.6,0,0,0,297.621,211.666Z"
        transform="translate(-289.501 -63.275)"
        fill="none"
      />
      <path
        id="XMLID_116_"
        d="M14.464,148.493l-6.937-6.715a2.291,2.291,0,0,0-1.59-.644L-7.127,141.1A2.863,2.863,0,0,0-10,143.95v23.472a2.919,2.919,0,0,0,2.9,2.856H12.224a2.832,2.832,0,0,0,2.8-2.861V149.855A1.875,1.875,0,0,0,14.464,148.493ZM7.5,144.925l3.585,3.466H8.12a.6.6,0,0,1-.621-.547ZM12.224,168H-7.1a.632.632,0,0,1-.621-.581V143.95a.6.6,0,0,1,.593-.57l12.346.034v4.429a2.869,2.869,0,0,0,2.9,2.827h4.623v16.746A.549.549,0,0,1,12.224,168Z"
        transform="translate(0 0)"
      />
      <path
        id="XMLID_223_"
        d="M111.14,294a1.143,1.143,0,0,0-1.14,1.14v12.312a1.14,1.14,0,0,0,2.28,0V295.14A1.143,1.143,0,0,0,111.14,294Z"
        transform="translate(-113.16 -144.185)"
      />
      <path
        id="XMLID_305_"
        d="M191.14,346a1.143,1.143,0,0,0-1.14,1.14v9.348a1.14,1.14,0,0,0,2.28,0V347.14A1.143,1.143,0,0,0,191.14,346Z"
        transform="translate(-188.6 -193.221)"
      />
      <path
        id="XMLID_307_"
        d="M271.14,398a1.143,1.143,0,0,0-1.14,1.14v6.384a1.14,1.14,0,1,0,2.28,0V399.14A1.143,1.143,0,0,0,271.14,398Z"
        transform="translate(-264.04 -242.257)"
      />
    </g>
  </svg>
);
export default ReportingIcon;

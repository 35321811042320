import { call, put, takeLatest } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import * as actions from "./resetPasswordActions";
import * as sharedActions from "../../shared/sharedActions";
import * as errorActions from "../../shared/errors/errorActions";
import * as mappers from "./resetPasswordMappers";
import * as authenticationApi from "../../api/authenticationApi";
import { BadRequestError } from "../../auth/ApiError";
import { customErrorMapper } from "../../shared/errors/ErrorMappers";
import * as authApi from "../../api/authenticationApi";
import {Tina4SignInTokenResult} from "../SignUpPage/signUpData";
import {logout} from "../../auth/authAPI";
import {appSettings} from "../../config/appSettings";
import {ValidationError} from "../../shared/errors/ErrorModels";

const apiName = appSettings.apisMetadata.find(x => x.id === 'rms')!.name;

export default function* resetPasswordSaga() {
    yield takeLatest(getType(actions.forgotPassword), forgotPassword);
    yield takeLatest(getType(actions.resetPassword), resetPassword);
}

function* forgotPassword(action: ActionType<typeof actions.forgotPassword>) {
    const model = action.payload;
    const url = 'forgot-token'

    try {
        yield call(logout, apiName);
        const data = mappers.forgotPasswordFromModel(model);

        const authResponseData: Response = yield call(() => authApi.initialAuthToken(url));

        const authResponseBody: Tina4SignInTokenResult = yield call(() => authResponseData);

        yield call(() => authenticationApi.forgotPassword(data,  authResponseBody.access_token));

        yield put(sharedActions.setForgotPasswordConfirmed(true));
    } catch(e) {
        if(e instanceof BadRequestError) {

            const err = customErrorMapper(e.message);
            yield put(errorActions.addError(err));
        }

    } finally {

    }
}

function* resetPassword(action: ActionType<typeof actions.resetPassword>) {
    const model = action.payload;
    const url = 'reset-token'
    let validationErrors: ValidationError[] = [];
    const data = mappers.resetPasswordFromModel(model);
    if (!data.password) {
        validationErrors.push({name: 'Password', message: 'Password is required'});
    }
    if (data.password.length < 4) {
        validationErrors.push({name: 'Password', message: 'Password cannot be shorter than 4 symbols'});
    }
    if (!data.confirmPassword) {
        validationErrors.push({name: 'PasswordConfirmation', message: 'Password confirmation is required'});
    }
    if (data.password !== data.confirmPassword) {
        validationErrors.push({name: 'PasswordConfirmation', message: 'Password confirmation does not match'});
    }
    if (validationErrors.length > 0) {
        yield put(errorActions.setValidationErrors(validationErrors));

        return false;
    }
    try {
        yield call(logout, apiName);
        const authResponseData: Response = yield call(() => authApi.initialAuthToken(url));

        const authResponseBody: Tina4SignInTokenResult = yield call(() => authResponseData);

        yield call(() => authenticationApi.resetPassword(data,  authResponseBody.access_token));

        yield put(sharedActions.setResetPasswordConfirmed(true));
    } catch(e) {
        if(e instanceof BadRequestError) {

            const err = customErrorMapper(e.message);
            yield put(errorActions.addError(err));
        }

    } finally {

    }
}

import { call, put, takeLatest } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import * as actions from "./settingsActions";
import * as sharedActions from "../shared/sharedActions";
import {AddNotificationSettings, NotificationSettings, UpdateNotificationSettings} from "./SettingsApiData";
import * as api from "../api/index";
import * as mappers from "./settingsMappers";
import {SurveySetting} from "./SettingsModels";

export default function* settingsSaga() {
    yield takeLatest(getType(actions.getNotificationSettings), getNotificationSettings);
    yield takeLatest(getType(actions.addNotificationSettings), addNotificationSettings);
    yield takeLatest(getType(actions.editNotificationSettings), editNotificationSettings);
    yield takeLatest(getType(actions.uploadLogo), uploadLogo);
    yield takeLatest(getType(actions.uploadAgencyLogo), uploadAgencyLogo);
    yield takeLatest(getType(actions.uploadFavicon), uploadFavicon);
    yield takeLatest(getType(actions.updateAgencySettings), updateAgencySettings);
    yield takeLatest(getType(actions.getSurveySettings), getSurveySettings);
    yield takeLatest(getType(actions.toggleAnonymousSetting), toggleAnonymousSetting);
}

function* getNotificationSettings(action: ActionType<typeof actions.getNotificationSettings>){
    try {

        const settings: NotificationSettings[] = yield call(api.getNotificationSettings);
        const model = settings.map(s => mappers.notificationSettingsToModel(s));

        yield put(actions.getNotificationSettingsCompleted(model));

    } catch(e) {
        console.error(e);
    } finally {
        // stop loader
    }
}

function* addNotificationSettings(action: ActionType<typeof actions.addNotificationSettings>){
    const model = action.payload;

    const data: AddNotificationSettings = {
        surveyId: model.surveyId,
        enabled: model.enabled,
        emailAddresses: model.emailAddresses,
        iconPath: model.iconPath,
        agentEnabled: model.agentEnabled,
        organizationId: model.organizationId,
        description: model.description,
    };

    try {
        yield call(api.addNotificationSettings, data);
        yield put(actions.getNotificationSettings());

    } catch(e) {
        console.error(e);
    } finally {
        // stop loader
    }

}

function* editNotificationSettings(action: ActionType<typeof actions.editNotificationSettings>){
    const model = action.payload;

    const data: UpdateNotificationSettings = {
        id: model.id,
        surveyId: model.surveyId,
        enabled: model.enabled,
        emailAddresses: model.notificationEmail,
        agentEnabled: model.agentEnabled
    };

    try {
        yield call(api.editNotificationSettings, data);
        yield put(actions.editNotificationSettingsCompleted(model));

    } catch(e) {
        console.error(e);
    } finally {
        // stop loader
    }
}

function* uploadFavicon(action: ActionType<typeof actions.uploadFavicon>){
    try {
        yield call(api.uploadFavicon, {...action.payload});
        yield put(sharedActions.getProfile());
    } catch (e) {
        console.error(e);
    }
}

function* uploadLogo(action: ActionType<typeof actions.uploadLogo>){
    try {
        yield call(api.uploadLogo, {...action.payload});
        yield put(sharedActions.getProfile());

    } catch(e) {
        console.error(e);
    } finally {
        // stop loader
    }
}

function* uploadAgencyLogo(action: ActionType<typeof actions.uploadAgencyLogo>) {
    try {
        yield call(api.uploadAgencyLogo, {...action.payload});
        yield put(sharedActions.getProfile());

    } catch(e) {
        console.error(e);
    } finally {
        // stop loader
    }
}

function* updateAgencySettings(action: ActionType<typeof actions.updateAgencySettings>) {
    try {
        yield call(api.updateAgencySettings, {...action.payload});
        yield put(sharedActions.getProfile());

    } catch(e) {
        console.error(e);
    } finally {
        // stop loader
    }
}

function* getSurveySettings(action: ActionType<typeof actions.getSurveySettings>) {
    try {
        const setting: SurveySetting = yield call(api.getSurveySettings);
        yield put(actions.getSurveySettingsCompleted(setting));

    } catch(e) {
        console.error(e);
    } finally {
        // stop loader
    }
}

function* toggleAnonymousSetting(action: ActionType<typeof actions.toggleAnonymousSetting>) {
    try {
        yield call(api.toggleAnonymousSetting, {...action.payload});
        yield put(actions.toggleAnonymousSettingCompleted(action.payload));

    } catch(e) {
        console.error(e);
    } finally {
        // stop loader
    }
}

import { deprecated } from 'typesafe-actions';
import {AgencySettings, CSATScale, LogoUpload, SurveySetting} from "./SettingsModels";
import {AddNotificationSettings} from "./SettingsApiData";

const { createStandardAction } = deprecated;

export const getNotificationSettings = createStandardAction('settings/GET_NOTIFICATION_SETTINGS')();
export const uploadLogo = createStandardAction('settings/UPLOAD_LOGO')<LogoUpload>();

export const uploadAgencyLogo = createStandardAction('settings/UPLOAD_AGENCY_LOGO')<LogoUpload>();

export const uploadFavicon = createStandardAction('settings/UPLOAD_FAVICON')<LogoUpload>();

export const updateAgencySettings = createStandardAction('settings/UPDATE_AGENCY_SETTINGS')<AgencySettings>();

export const getNotificationSettingsCompleted = createStandardAction('settings/GET_NOTIFICATION_SETTINGS_COMPLETED')<CSATScale[]>();
export const addNotificationSettings = createStandardAction('settings/ADD_NOTIFICATION_SETTINGS')<AddNotificationSettings>();
export const editNotificationSettings = createStandardAction('settings/EDIT_NOTIFICATION_SETTINGS')<CSATScale>();
export const editNotificationSettingsCompleted = createStandardAction('settings/EDIT_NOTIFICATION_SETTINGS_COMPLETED')<CSATScale>();

export const getSurveySettings = createStandardAction('settings/GET_SURVEY_SETTINGS')();
export const getSurveySettingsCompleted = createStandardAction('settings/GET_SURVEY_SETTINGS_COMPLETED')<SurveySetting>();

export const toggleAnonymousSetting = createStandardAction('settings/TOGGLE_ANONYMOUS_FEEDBACK')<SurveySetting>();
export const toggleAnonymousSettingCompleted = createStandardAction('settings/TOGGLE_ANONYMOUS_FEEDBACK_COMPLETED')<SurveySetting>();
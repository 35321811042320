import { Box } from "@material-ui/core";
import { RouteComponentProps, useLocation } from "react-router";
import SubmitFeedbackPage from "./SubmitFeedbackPage";
import { appSettings } from "../../config/appSettings";
import { useEffect, useState } from "react";
import Loading from "../../shared/components/Loading";
import {useHistory, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getFormOptions} from "../feedbackActions";
import * as selectors from "../feedbackSelectors";
import {submissionRedirectModel} from "../FeedbackModels";

const apiName = appSettings.apisMetadata.find((x) => x.id === "rms")!.name;
const apiUrlRoot = appSettings.apisMetadata.find(
    (x) => x.id === "rms"
)!.baseAddress;

interface MatchParams {
    orgid: string;
    agentid: string;
    type: string;
}

interface SubmitFeedbackWrapperActions {
    getFormOptions: typeof getFormOptions;
    submissionRedirect?: submissionRedirectModel;
}

type SubmitFeedbackWrapperProps = RouteComponentProps<MatchParams> & SubmitFeedbackWrapperActions;

const SubmitFeedbackWrapper: React.FC<SubmitFeedbackWrapperProps> = (props) => {
    const history = useHistory();
    const { type, orgid, agentid } = props.match.params;
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const [redirectToMiRatings, setRedirectToMiRatings] = useState(false);
    const [currentRedirectId, setCurrentRedirectId] = useState(0);
    const [siteKey, setSiteKey] = useState<number>(0);
    const [publicReviewLinkLabel, setPublicReviewLinkLabel] = useState("Choose public review over private discussion, click here");

    const surveyAnswerId = query.get("surveyAnswerId") || "";
    const organisationFeedback = Number(query.get("organisationFeedback")) || 0;
    const starId = Number(query.get("starId")) || 0;
    const organizationId = orgid.replace("org", "");
    const agentId = agentid.replace("agent", "");

    useEffect(() => {
        // get form options here
        let logReview = false;
        if (type === "ExceededExpectations" || type === "VerySatisfied") {
            logReview = true;
        }
        if (surveyAnswerId !== "") {
            props.getFormOptions({
                organizationId,
                agentId,
                answerId: surveyAnswerId,
                submitRate: type.toLowerCase(),
                logReview: logReview,
            });
        }
    }, []);

    useEffect(() => {
        if (props.submissionRedirect) {
            const feedbackOption = props.submissionRedirect.feedbackOption || 'internal';
            setCurrentRedirectId(Number(props.submissionRedirect.reviewSiteId) || 2);
            if (feedbackOption === 'internal') {
                setRedirectToMiRatings(false);
            }
            if (type === "ExceededExpectations" || type === "VerySatisfied") {
                if (feedbackOption === 'external' && props.submissionRedirect.url) {
                    window.location.replace(props.submissionRedirect.url);
                }
                if (feedbackOption === 'funnel') {
                    if (props.submissionRedirect.organisationReviewSiteId) {
                        setSiteKey(props.submissionRedirect.organisationReviewSiteId);
                    }
                    setRedirectToMiRatings(true);
                }
            }
        }
    }, [props.submissionRedirect]);

    useEffect(() => {
        if (redirectToMiRatings) {
            window.location.href = `${appSettings.reviewsApp.submitReviewUrl}/${organizationId}/${agentId}/${currentRedirectId}${siteKey ? `?site_key=${siteKey}` : ''}`;
        }
    }, [redirectToMiRatings, currentRedirectId]);

    if (redirectToMiRatings) {
        return (
            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                <Loading />
            </Box>
        );
    }


    return (
        <Box id="feedback-submit-container" className="anonymous-page-container">
            <Box className="anonymous-page">
                <SubmitFeedbackPage
                    organizationId={organizationId}
                    agentId={agentId}
                    surveyAnswerId={surveyAnswerId}
                    submitRate={type}
                    organisationFeedback={organisationFeedback}
                    starId={starId}
                    reviewSiteId={2}
                    setPublicReviewLabel={setPublicReviewLinkLabel}
                />
            </Box>
            {props.submissionRedirect && props.submissionRedirect.url && publicReviewLinkLabel ?
                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                    <p>
                        <a href={props.submissionRedirect.url} target={"_blank"}>{publicReviewLinkLabel}</a>
                    </p>
                </Box>
            : null}
        </Box>
    );
};

const mapStateToProps = (state: any) => ({
    submissionRedirect: selectors.submissionRedirectSelector(state),
});

const mapDispatchToProps = { getFormOptions};

const SubmitFeedbackWrapperContainer = connect(mapStateToProps, mapDispatchToProps)(withRouter(SubmitFeedbackWrapper));
export default SubmitFeedbackWrapperContainer;

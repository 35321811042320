import { takeLatest, call, put } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import {Review} from "./ReviewsData";
import {ReviewsModel} from "./reviewsModels";
import * as actions from "./reviewsActions";
import * as sharedActions from "../shared/sharedActions";
import * as api from "../api/index";
import * as mappers from "./reviewsMappers";

export default function* reviewsSaga(){
    yield takeLatest(getType(actions.getAllReviews), getAllReviews);
}

function* getAllReviews(action: ActionType<typeof actions.getAllReviews>) {
    try {
        const filters = mappers.filtersFromModel(action.payload);

        const reviewData: any[] = yield call(api.getAllReviews,filters);
        const reviews: Review[] = reviewData[0];
        const reviewCount = reviewData[1];

        const model: ReviewsModel[] = reviews.map(item => mappers.reviewToModel(item));
        yield put(actions.getAllReviewsCompleted(model));
        yield put(actions.getReviewCountCompleted(reviewCount));
        yield put(sharedActions.setIsSearching(false));

    } catch(e) {

    } finally {

    }
}
import * as actions from "./reviewsActions";
import { getType } from "typesafe-actions";
import {ReviewsSystemModel} from "./reviewsModels";


const initialReviewsState: ReviewsSystemModel = {
    reviews: [],
    reviewCount: 0,
};

const reviewsReducer = (state: ReviewsSystemModel = initialReviewsState, action: any) => {
    switch(action.type) {
        case getType(actions.getAllReviewsCompleted):
            return {
                ...state,
                reviews: [...action.payload],
            };

        case getType(actions.getReviewCountCompleted):
            return {
                ...state,
                reviewCount: [action.payload],
            }

        default:
            return state;
    }
}

export { reviewsReducer };
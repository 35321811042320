import { Store, Middleware, AnyAction } from 'redux';
import { ActionType, getType } from 'typesafe-actions';

import { TokenPair } from './redux/reducers';
import * as authActions from "./redux/actions";
import ApiMetadata from "./ApiMetadata";
import OAuthTokenResponse from './OAuthTokenResponse';
import { isExpired, getExpirationDate } from './_helpers/commonFunctions';

export default function createAuthMiddleware(options: MiddlewareOptions): Middleware {
    const { history, authorizationServerUrl, authorizationEndpoint, clientAppServerUrl } = options;
    const apis = new Array<ApiMetadata>();

    //ActionType<typeof authActions>
    const authMiddleware: Middleware = store => next => async (action: any) => {

        const getTokens = <T>(apiName: string) => {
            let tokens = {
                accessToken: "",
                refreshToken: "",
                primaryToken: "",
            };

            const api = getApi(apiName);

            if (api) {
                // fix!
                const str = localStorage.getItem(apiName);
                const receivedData = str ? JSON.parse(str) : {};
                tokens.accessToken = receivedData.accessToken || receivedData.access_token;
                tokens.refreshToken = receivedData.refreshToken || receivedData.refresh_token;
                tokens.primaryToken = receivedData.primaryToken || receivedData.primaryToken;
            }

            return tokens;
        }

        const setToken = (apiName: string, tokens: TokenPair) => {
            const json = JSON.stringify(tokens);

            localStorage.setItem(apiName, json);
            next(authActions.setTokens(tokens));
        }

        const getApi = (apiName: string) => {
            let api;

            const foundApis = apis.filter(element => element.name === apiName);

            if (foundApis) {
              api = foundApis[0];
            }

            return api;
        }

        // in any cases this function return token
        // what if error; middleware error
        const acquireTokens = async (apiName: string) => {
            let accessToken = null;
            let authorizationRequest;
            const api = getApi(apiName);
            // check it
            if(api) authorizationRequest = api.authorizationRequest;

            // condition if authorizationRequest exist and is a function
            // in function something maybe wrong or wrong structure of tokens object !!!
            try {
                //if(authorizationRequest && ) {
                if(authorizationRequest) {
                    const tokens = await authorizationRequest();

                    // notice: what if return value - null or object { accessToken: null, refreshToken: null };
                    // this case should be handled also in refresh function !!!

                    if(tokens && tokens.accessToken) {
                        // similarly authAPI.login function
                        setToken(apiName, tokens);
                        return next(authActions.requestToken(apiName));

                        // needed or not ???
                        // return;
                    }
                }

                // there need to be automatically (default) authorization ??
                // store.dispatch(authActions.receiveToken({ token: null }));

            } catch(e) {
                store.dispatch(authActions.middlewareError({ err: e }));

            } finally {
                return next(action);
            }
        }

        const refreshTokens = async (apiName: string, refreshToken: string | null) => {

            // api should be declared earlier
            const opts = {
                method: "POST",
                body: JSON.stringify({ refreshToken })
            }

            // need to handle response, error if happened and handle if reponse receieved in wrong format;
            try {
                const result = await fetch('http://localhost:3331/auth/refresh', opts);
                const data = await result.json() || result.text();

                if(result.ok) {
                    setToken(apiName, data);
                    next(authActions.requestToken(apiName));
                } else {
                    const error = (data && data.message) || result.statusText;
                    throw new Error(error);
                }

            } catch(e) {
                console.log('middleware error happened');
                store.dispatch(authActions.middlewareError({ err: e }));
                // needed or not ??
                return;
            }


            // fetch('/auth/refresh', opts)
            //     .then(async res => {
            //         const data = await res.json() || res.text();
            //         store.dispatch(authActions.setTokens(data));
            //         // next(authActions.receiveToken(data));

            //         console.log('mock', store.dispatch.mock.calls );
            //         // todo: verify response content type json|text

            //         // return res.text();
            //     });
                // .then(body => {

                //     // for real response
                //     // <------------------------------------------->
                //     // try {
                //     //     body = JSON.parse(body);
                //     // } catch {}

                //     // if (!body.ok) throw Error(body.message);

                //     return body;
                //     // <------------------------------------------->
                // })
                // .then(res => {

                //     // const tokens = JSON.parse(res || '');

                //     store.dispatch(authActions.receiveToken({ token: res.accessToken }));
                //     store.dispatch(authActions.setTokens(res));

                //     console.log('mock', store.dispatch.mock.calls );
                //     console.log('', res.accessToken);
                // })
                // .catch(e => {
                //     store.dispatch(authActions.middlewareError({ err: e }));
                // })
        }

        const handleAddApiMetadata = (action: ActionType<typeof authActions.addApiMetadata>) => {
            const apiName = action.payload.name;

            if (apis.filter(element => element.name == apiName).length === 0) {
              apis.push(action.payload);
            }
        }

        console.log('middleware: ' + action.type);
        switch (action.type) {

            case getType(authActions.requestToken): {
                const apiName = action.payload;

                const tokens: TokenPair | undefined = getTokens(apiName);
                // getToken fail (!!!)

                // flow: if accessToken is empty on localStorage should try to execute delegate function and return retrieved or null tokens;
                if (!tokens || !tokens.accessToken) {
                    await acquireTokens(apiName);
                }

                const exp: boolean = isExpired(getExpirationDate(tokens.accessToken!));

                // call this after the real token received
                if(!exp) {
                    const response: OAuthTokenResponse = {
                        token: tokens.accessToken!,
                        apiName,
                    };

                    store.dispatch(authActions.receiveToken(response));
                } else {
                    // await refreshTokens(apiName, tokens.refreshToken);
                    await acquireTokens(apiName);
                    break;
                }

                return next(action);
                // break;
            }

            // case getType(authActions.acquireTokens): {
            //     await acquireTokens(action.payload);

            //     break;
            // }

            // case getType(authActions.refreshTokens): {
            //     // test with async/await
            //     await refreshTokens(action.payload);

            //     break;
            // }

            case getType(authActions.addApiMetadata): {
                handleAddApiMetadata(action);
                break;
            }

            case getType(authActions.login): {
                const { tokens, apiName } = action.payload;
                setToken(apiName, tokens);
                return next(action);
            }

            case getType(authActions.setPrimaryToken): {
                const { tokens, name } = action.payload;

                setToken(name, tokens);
                return next(action);
            }

            case getType(authActions.setAgentToken): {
                const { tokens, apiName } = action.payload;

                setToken(apiName, tokens);
                return next(action);
            }

            default:
                return next(action);
        }
    }

    return authMiddleware;
}

export const registerApi = (store: Store<any, AnyAction>) => (apiMetadata: ApiMetadata) => {
    store.dispatch(authActions.addApiMetadata(apiMetadata));
}

export interface MiddlewareOptions {
    history: History,
    authorizationServerUrl: string;
    authorizationEndpoint: string;
    clientAppServerUrl: string;
    authorizationRequest?: () => TokenPair;
}

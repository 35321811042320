import { FeedbackSystemModel, FeedbackSubmitModel, FeedbackModel } from "./FeedbackModels";
import * as actions from "./feedbackActions";
import { getType } from "typesafe-actions";

const initialFeedbackSubmitState: FeedbackSubmitModel = {
    isSubmitSucceeded: false,
}

const initialFeedbackState: FeedbackSystemModel = {
    all: [],
};

const feedbackSubmitReducer = (state: FeedbackSubmitModel = initialFeedbackSubmitState, action: any) => {
    switch(action.type) {
        case getType(actions.getFormOptionsCompleted):
            return {
                ...state,
                customer: {
                    ...state.customer,
                    ...action.payload
                }
            }
        case getType(actions.leaveFeedbackCompleted):
            return {
                ...state,
                isSubmitSucceeded: true,
            }
        default:
            return {
                ...state,
            }
    }
}

const feedbackReducer = (state: FeedbackSystemModel = initialFeedbackState, action: any) => {
    switch(action.type) {
        case getType(actions.getAllFeedbackCompleted):
            return {
                ...state,
                all: [...action.payload],
            };

        case getType(actions.getFeedbackCompleted):
            return {
                ...state,
                viewed: action.payload,
            }

        case getType(actions.toggleFeedbackIsArchivedCompleted):
            return {
                ...state.viewed ,
                viewed: action.payload,
            }
        default:
            return state;
    }
}

export { feedbackSubmitReducer, feedbackReducer };

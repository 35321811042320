import { createAction, deprecated } from 'typesafe-actions';

import { CredentialsModel, SigninModel } from './signInModels';

const { createStandardAction } = deprecated;
export const verifyIdToken = (code: any, provider: string) => createAction('signin/VERIFY_ID_TOKEN', action => {
    return (code: any, provider: string) => action(code, provider);
});

export const signIn = createStandardAction('signin/VERIFY')<SigninModel>();

export const switchOrganization = createStandardAction('signin/SWITCH_ORGANIZATION')<number>();

export const validateCredentials = createStandardAction('signin/VALIDATE_CREDENTIALS')<CredentialsModel>();
export const validateCredentialsCompleted = createStandardAction('signin/VALIDATE_CREDENTIALS_COMPLETED')<boolean>();


import { ApplicationState } from "../ApplicationState";

export const languageSelector = (state: ApplicationState) => {
    return state.settings.language;
};

export const csatSettingsSelector = (state: ApplicationState) => {
    return state.settings.csat;
};

export const logoSelector = (state: ApplicationState) => {
    return state.globals.profile ? state.globals.profile?.organization.logoPath : "";
};
import { action, createAction, getType } from 'typesafe-actions';

import AuthTypes from './constants';
import ApiMetadata from '../ApiMetadata';
import { TokenPair } from './reducers';
import OAuthTokenResponse from '../OAuthTokenResponse';

export const setTokens = createAction(AuthTypes.SET_TOKENS)<TokenPair>();
export const setPrimaryToken = createAction(AuthTypes.SET_PRIMARY_TOKEN)<{ name: string, tokens: { primary_token: string, grant_type: string, expires_in: string } }>();
export const removeAgentToken = createAction(AuthTypes.REMOVE_AGENT_TOKEN)();
export const setAgentToken = createAction(AuthTypes.SET_AGENT_TOKEN)<{ apiName: string, tokens: {access_token: string, grant_type: string, expires_in: string} }>();
export const removeTokens = createAction(AuthTypes.REMOVE_TOKENS)();
export const refreshTokens = createAction(AuthTypes.REFRESH_TOKENS)<{ refreshToken: string | null }>();
export const acquireTokens = createAction(AuthTypes.ACQUIRE_TOKENS)<{ refreshToken: string | null }>();

// export const login = createAction(AuthTypes.LOGIN)<{ apiName: string, tokens: TokenPair }>();
export const login = createAction(AuthTypes.LOGIN)<{ apiName: string, tokens: {access_token: string, grant_type: string, expires_in: string} }>();

export const requestToken = createAction("auth/REQUEST_TOKEN")<string>();
export const receiveToken = createAction("auth/RECEIVE_TOKEN")<OAuthTokenResponse>();
export const middlewareError = createAction("auth/MIDDLEWARE_ERROR")<{ err: Error }>();
export const addApiMetadata = createAction("auth/ADD_API_METADATA")<ApiMetadata>();

interface SetTokens {
    type: AuthTypes.SET_TOKENS,
    payload: TokenPair,
}

interface RemoveAgentToken {
    type: AuthTypes.REMOVE_AGENT_TOKEN,
    payload: TokenPair,
}

interface SetAgentToken {
    type: AuthTypes.SET_AGENT_TOKEN,
    payload: TokenPair,
}

interface RemoveTokens {
    type: AuthTypes.REMOVE_TOKENS,
    payload: TokenPair,
}

interface RefreshTokens {
    type: AuthTypes.REFRESH_TOKENS,
    payload: {
        refreshToken: string,
    }
}

interface AcquireTokens {
    type: AuthTypes.ACQUIRE_TOKENS,
    payload: {
        url: string,
    }
}

export type AuthActions =
    SetTokens
    | RemoveAgentToken
    | SetAgentToken
    | RemoveTokens
    | RefreshTokens
    | AcquireTokens;


import { Box, Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle,
    makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { useState } from "react";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
import { setReenteringFlag, setVerifying } from "../../../shared/sharedActions";
import { VerifyingModel } from "../../../shared/SharedModels";
import { confirmEmailVerification, reenterSignupEmail, resendOTP } from "../signUpActions";
import { redirect } from "../../../shared/router/routerActions";
import { ReenterSignupEmailModel } from "../signUpModels";
import PhoneIcon from "../../../svg/phone.svg";
import Icon from "../../../shared/components/Icon";

const useStyles = makeStyles((theme: Theme) => createStyles({
    btn: {
      backgroundColor: "#F43B3B",
      color: "#fff",
      marginTop: "10px",
    },
    agreement: {
        fontSize: "0.8rem",
    },
    dialogTitle: {
        paddingTop: "24px",
        fontWeight: "bold",
    },
    dialogActions: {
        paddingBottom: "16px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    spamText: {
        fontWeight: "bold",
        color: "red",
    }
}));

type VerifyingPopupProps = {
    open: boolean,
    email: string,
    verifying: VerifyingModel,

    handleConfirm: typeof confirmEmailVerification,
    handleResend: typeof resendOTP,
    handleClose: typeof setVerifying,
    handleReenterSignupEmail: typeof reenterSignupEmail,
    redirect: typeof redirect,
    handleReenteringFlag: typeof setReenteringFlag,
};

const VerifyingPopup: React.FC<VerifyingPopupProps> = (props) => {
    const { open, email, verifying } = props;
    const classes = useStyles();
    const [otp, setOtp] = useState<string>("");
    const [reenteredEmail, setReenteredEmail] = useState<ReenterSignupEmailModel>({ email: "", });

    const handleConfirm = () => {
      props.handleConfirm({
        verificationCode: otp,
      });
    };

    const handleResend = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        props.handleResend();
    };

    const handleReenterEmail = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        props.handleReenteringFlag(true);
    }

    const handleConfirmEmailChange = () => {
        props.handleReenterSignupEmail(reenteredEmail);
    };

    const handleCancelEmailChange = () => {
        props.handleReenteringFlag(false);
    };

    const handleReenteredEmailChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
        if(!event.target.name) return;

        setReenteredEmail(prev => {
            return {
                ...prev,
                [event.target.name!]: event.target.value,
            }
        });
    }

    const handleRedirectTo = (href: string) => {
        props.redirect(href);
    };

    const handleClose = () => {
        props.handleClose({
            isVerified: false,
            isExpired: undefined,
        });
    };

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ textAlign: "center" }}
            fullWidth={true}
            maxWidth="sm"

        >
            <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
                <Icon src={PhoneIcon} />
                <Typography variant="h4" component="h2" style={{ fontWeight: "bold" }}>Check your email for a code</Typography>
            </DialogTitle>
            {
                verifying.isExpired
                    ?
                        <DialogContent className="dialog-content">
                            <Typography>Code has been expired. Please <Link to="#" onClick={handleResend}>CLICK-HERE</Link> to resend verification code</Typography>

                        </DialogContent>
                    :
                    verifying.isReentering
                    ?
                    <>
                        <DialogContent>
                            <TextField className='sign-box-item' name='email' value={reenteredEmail.email} label="Email" variant="outlined"
                                // error={validation.hasError(validationErrors, 'Email')}
                                // helperText={validation.getErrorText(validationErrors, 'Email')}
                                onChange={handleReenteredEmailChange}
                            />
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                type="submit"
                                className={`sign-box-btn`}
                                color="primary"
                                variant="contained"
                                onClick={handleConfirmEmailChange}
                            >
                                Confirm
                            </Button>
                            <Button
                                className={`sign-box-btn`}
                                style={{ marginLeft: "0", marginTop: "5px", }}
                                variant="outlined"
                                onClick={handleCancelEmailChange}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </>
                    :
                    <>
                        <DialogContent className="dialog-content">
                            <Typography style={{ fontWeight: "bold" }} gutterBottom >We sent a code to {email}
                                <br/>
                                to verify your E-mail
                            </Typography>
                            <OtpInput
                                value={otp}
                                containerStyle={{ justifyContent: "center", color: "#E5E5E5" }}
                                renderInput={(props) => <input {...props} /> }
                                inputStyle="otp-inputElement"
                                onChange={setOtp}
                                numInputs={6}
                                renderSeparator={<span>-</span>}
                            />
                        </DialogContent>
                        <DialogActions
                            className={classes.dialogActions}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ width: "275px" }}
                                onClick={handleConfirm}
                            >
                                Confirm
                            </Button>
                            <Box display="flex" justifyContent="space-between" my="10px" >
                                <Button
                                    component="a"
                                    onClick={() => handleRedirectTo("https://mail.google.com")}
                                >
                                    Open Gmail
                                </Button>
                                <Button
                                    onClick={() => handleRedirectTo("https://login.live.com/login.srf")}
                                >
                                    Open Outlook
                                </Button>
                            </Box>
                            <Typography variant="body2">
                                Can't find email? Check your <span className={classes.spamText}>SPAM Folder</span>, or
                                <br/>
                                <Link to="#" onClick={handleReenterEmail}>re-enter your email and try again.</Link>
                            </Typography>
                        </DialogActions>
                    </>
            }

        </Dialog>
    )
}

export default VerifyingPopup;

interface IconProps {
    src: string,
    className?: string,
};

const Icon = (props: IconProps) => {
    return (
        <img src={props.src} className={props.className} alt="Icon" />
    )
}

export default Icon;
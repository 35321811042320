import {Reducer} from 'redux';

import AuthTypes from './constants';
import {AuthActions} from './actions';

const preloadedState = {
    tokens: {
        accessToken: null,
        refreshToken: null,
        primaryToken: null,
    }
}

export interface AuthState {
    tokens: TokenPair
}

export type TokenPair = {
    accessToken: string | null,
    refreshToken?: string | null,
    primaryToken?: string | null,
}

const authReducer: Reducer<AuthState, AuthActions> = (state = preloadedState, action): any => {
    switch (action.type) {
        case AuthTypes.SET_TOKENS:
            return {
                ...state,
                tokens: {
                    accessToken: action.payload.accessToken,
                    refreshToken: action.payload.refreshToken,
                    primaryToken: action.payload.primaryToken,
                }
            }

        case AuthTypes.SET_AGENT_TOKEN:
            return {
                ...state,
                tokens: {
                    primaryToken: action.payload.primaryToken
                }
            }

        case AuthTypes.REMOVE_AGENT_TOKEN:
            return {
                ...state,
                tokens: {
                    accessToken: null,
                }
            }

            case AuthTypes.REMOVE_TOKENS:
                // @ts-ignore
                return {
                ...state,
                tokens: {
                    accessToken: null,
                    refreshToken: null,
                    primaryToken: null,
                }
            }

        default:
            return state
    }

}

export default authReducer;

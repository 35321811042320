import { Review } from "./ReviewsData"
import {Filters, ReviewsModel} from "./reviewsModels"

export const reviewToModel = (data: Review): ReviewsModel => {
    return {
        id: data.id,
        name: data.author,
        text: data.text,
        reviewSite: data.name,
        rating: data.rating,
        reviewLink: data.reviewLink,
        time: data.time ? new Date(data.time) : undefined,
    }
}

export const filtersFromModel = (model: Filters) => {
    const filters = Object.keys(model);
    const data = Object.assign(model);

    filters.forEach(k => {
        if(data[k] === "showall") data[k] = "";
    });

    return data;
}
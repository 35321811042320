import {ReportingModel, SummaryEntryModel, SummaryUnitModel} from "./ReportingModels";
import * as feedbackActions from "../Feedback/feedbackActions";
import * as actions from "./reportingActions";
import { getType } from "typesafe-actions";

const initialReportingState: ReportingModel = {
    tableSummary: [],
    filters: {
        agentId: "",
        reportType: "",
    }
};

const unitDefaults = {
    VerySatisfied: {type: "VerySatisfied", count: 0, backgroundColor: "#00ff00", color: "#000000", rate: 0},
    ExceededExpectations: {type: "ExceededExpectations", count: 0, backgroundColor: "#00ff00", color: "#000000", rate: 0},
    Satisfied: {type: "Satisfied", count: 0, backgroundColor: "#00ff00", color: "#000000", rate: 0},
    Dissatisfied: {type: "Dissatisfied", count: 0, backgroundColor: "#00ff00", color: "#000000", rate: 0},
    VeryDissatisfied: {type: "VeryDissatisfied", count: 0, backgroundColor: "#00ff00", color: "#000000", rate: 0},
}

const summaryDefaults = {
    VerySatisfied: {label: "VerySatisfied", data: [], tension: 0.3, borderColor: "#00ff00", backgroundColor: "#00ff00"},
    ExceededExpectations: {label: "ExceededExpectations", data: [], tension: 0.3, borderColor: "#00ff00", backgroundColor: "#00ff00"},
    Satisfied: {label: "Satisfied", data: [], tension: 0.3, borderColor: "#00ff00", backgroundColor: "#00ff00"},
    Dissatisfied: {label: "Dissatisfied", data: [], tension: 0.3, borderColor: "#00ff00", backgroundColor: "#00ff00"},
    VeryDissatisfied: {label: "VeryDissatisfied", data: [], tension: 0.3, borderColor: "#00ff00", backgroundColor: "#00ff00"},
}

const reportingReducer = (state: ReportingModel = initialReportingState, action: any) => {
    switch(action.type) {
        case getType(feedbackActions.clearFeedbackSummary):
            return {
                ...state,
                tableSummary: [],
            };
        case getType(feedbackActions.getReportSummaryCompleted):
            // manipulating the summary data here before setting the state
            let tableSummary = state.tableSummary;
            let chartSummary = state.chartSummary;
            if (action.payload.tableSummary.length > 0) {
                tableSummary = action.payload.tableSummary.map((tableSummary: SummaryEntryModel) => {
                    let units: SummaryUnitModel[] = tableSummary.units;

                    const VerySatisfiedUnits: SummaryUnitModel = units.find((unit: any) => unit.type === "VerySatisfied") ||  unitDefaults.VerySatisfied;
                    const ExceededExpectationsUnits: SummaryUnitModel = units.find((unit: any) => unit.type === "ExceededExpectations") || unitDefaults.ExceededExpectations;
                    const SatisfiedUnits: SummaryUnitModel = units.find((unit: any) => unit.type === "Satisfied") || unitDefaults.Satisfied;
                    const DissatisfiedUnits: SummaryUnitModel = units.find((unit: any) => unit.type === "Dissatisfied") || unitDefaults.Dissatisfied;
                    const VeryDissatisfiedUnits: SummaryUnitModel = units.find((unit: any) => unit.type === "VeryDissatisfied") || unitDefaults.VeryDissatisfied;
                    tableSummary.units = [
                        {...VerySatisfiedUnits, count: ExceededExpectationsUnits.count + VerySatisfiedUnits.count },
                        SatisfiedUnits,
                        {...DissatisfiedUnits, count: DissatisfiedUnits.count + VeryDissatisfiedUnits.count}
                    ]
                    return tableSummary;
                });
            }
            if (action.payload.chartSummary) {
                chartSummary = action.payload.chartSummary;
                if (chartSummary) {
                    const VerySatisfiedSummary: any = chartSummary.summaries.find((summary: any) => summary.label === "VerySatisfied") || summaryDefaults.VerySatisfied;
                    const ExceededExpectationsSummary: any = chartSummary.summaries.find((summary: any) => summary.label === "ExceededExpectations") || summaryDefaults.ExceededExpectations;
                    const SatisfiedSummary: any = chartSummary.summaries.find((summary: any) => summary.label === "Satisfied") || summaryDefaults.Satisfied;
                    const DissatisfiedSummary: any = chartSummary.summaries.find((summary: any) => summary.label === "Dissatisfied") || summaryDefaults.Dissatisfied;
                    const VeryDissatisfiedSummary: any = chartSummary.summaries.find((summary: any) => summary.label === "VeryDissatisfied") || summaryDefaults.VeryDissatisfied;
                    const verySatisfiedData = VerySatisfiedSummary.data.map((data: any, index: number) => data + ExceededExpectationsSummary.data[index]);
                    const veryDissatisfiedData = VeryDissatisfiedSummary.data.map((data: any, index: number) => data + DissatisfiedSummary.data[index]);
                    chartSummary.summaries = [
                        {...VerySatisfiedSummary, data: verySatisfiedData},
                        SatisfiedSummary,
                        {...DissatisfiedSummary, data: veryDissatisfiedData}
                    ]
                }
            }
            return {
                ...state,
                tableSummary: tableSummary,
                chartSummary: chartSummary,
            }
        case getType(actions.setFilterValue):
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.name]: action.payload.value,
                }
            }
        default:
            return state;
    }
}

export default reportingReducer;

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

type ViewMessagePopupProps = {
    open: boolean,
    message: string,

    handleClose: () => void,
    handleGetArchiveMessage: (id: number) => void,
    handleConfirmArchive: (id: number) => void
}

const useStyles = makeStyles((theme) => ({
    popup: {
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    confirmBtn : {
        fontWeight: 'bold',
        color: "#66bb6a",
        border: '1px solid',
        borderRadius: '35px',
        borderColor: '#66bb6a',
        padding: '5px 15px',
        marginLeft: '3px',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        '&:hover': {
            backgroundColor: ' rgba(0, 0, 0, 0.1)',
        },
    },
    buttons: {
        justifyContent: "space-between",
    }
}));

const ArchiveMessagePopup: React.FunctionComponent<RouteComponentProps<{ id?: string }> & ViewMessagePopupProps> = (props) => {
    const classes = useStyles();
    const { id } = props.match.params;
    let feedbackId = Number(id);

    useEffect(() => {
        if(!props.message && id) props.handleGetArchiveMessage(Number(id));
    }, []);

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            fullWidth={true}
            maxWidth="sm"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Message
            </DialogTitle>
            <DialogContent className={classes.popup}>
                <Box display="flex">
                    <Typography>{props.message}</Typography>
                </Box>
            </DialogContent>
            <DialogActions className={classes.buttons}>
                <Button className={classes.confirmBtn} variant="outlined" onClick={() => props.handleConfirmArchive(feedbackId)}>YES</Button>
                <Button variant="outlined" onClick={props.handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )

}

export default withRouter(ArchiveMessagePopup);

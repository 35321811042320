import { takeLatest, call, put, all, take } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import { Feedback, GetFormOptionsParams, SurveyAnswerFormDetails } from "./FeedbackData";
import {FeedbackModel, SurveyAnswerFormDetailsModel, ToggleFeedbackModel} from "./FeedbackModels";
import * as actions from "./feedbackActions";
import * as sharedActions from "../shared/sharedActions";
import * as errorActions from "../shared/errors/errorActions";
import * as loadingActions from "../shared/loading/loadingStateActions";
import * as api from "../api/index";
import * as mappers from "./feedbackMappers";
import * as reportingMappers from "../ReportingPage/reportingMappers";
import { SummaryByDateRange, SummaryEntry } from "../ReportingPage/ReportingData";
import { SurveyType } from "../lookups/LookupsApiData";
import { ValidationError } from "../shared/errors/ErrorModels";
import { appSettings } from '../config/appSettings';
import * as authApi from "../api/authenticationApi";
import {Tina4SignInTokenResult} from "../EntryPages/SignUpPage/signUpData";
import {addQueryParams} from "../shared/helpers/urlHelpers";

const apiName = appSettings.apisMetadata.find(x => x.id === 'rms')!.name;
const apiUrlRoot = appSettings.apisMetadata.find(x => x.id === 'rms')!.baseAddress;

export default function* feedbackSaga(){
    yield takeLatest(getType(actions.getFormOptions), getFormOptions);
    yield takeLatest(getType(actions.leaveFeedback), leaveFeedback);
    yield takeLatest(getType(actions.getAllFeedback), getAllFeedback);
    yield takeLatest(getType(actions.getFeedback), getFeedback);
    yield takeLatest(getType(actions.getReportSummary), getReportSummary);
    yield takeLatest(getType(actions.validateFeedbackModel), validateFeedbackModel);
    yield takeLatest(getType(actions.toggleFeedbackIsArchived), toggleFeedbackIsArchived);
}

function* getFormOptions(action: ActionType<typeof actions.getFormOptions>) {
    try {
        const params: GetFormOptionsParams = {
            organizationId: Number(action.payload.organizationId),
            agentId: Number(action.payload.agentId),
            surveyAnswerId: action.payload.answerId,
            submitRate: action.payload.submitRate,
            logReview: action.payload.logReview || false,
        };

        //const opts: SurveyAnswerFormDetails = yield call(api.getSurveyAnswerFormDetails, params);
        const formOptionsUrl = addQueryParams(apiUrlRoot + `/${apiName}/feedback/form-options`, params as any as Record<string, string>);
        const response: Response = yield fetch(formOptionsUrl);
        if (!response.ok) {
            throw Error();
        }

        const opts: SurveyAnswerFormDetails = yield response.json();
        const model: SurveyAnswerFormDetailsModel = { ...opts };
        yield put(actions.getFormOptionsCompleted(model));

    } catch(e) {

    } finally {

    }
}

function* getAllFeedback(action: ActionType<typeof actions.getAllFeedback>) {
    try {
        const filters = mappers.filtersFromModel(action.payload);

        const feedback: Feedback[] = yield call(api.getAllFeedback, filters);

        const model: FeedbackModel[] = feedback.map(item => mappers.feedbackToModel(item));
        yield put(actions.getAllFeedbackCompleted(model));
        yield put(sharedActions.setIsSearching(false));

    } catch(e) {

    } finally {

    }
}

function* getFeedback(action: ActionType<typeof actions.getFeedback>) {
    try {
        const feedback: Feedback = yield call(api.getFeedback, action.payload);
        const model = mappers.feedbackToModel(feedback);

        return model;

    } catch(e) {

    } finally {

    }
}

function* getReportSummary(action: ActionType<typeof actions.getReportSummary>) {
    try {
        yield put(actions.clearFeedbackSummary());
        const filters = action.payload;

        const [tableSummary, tableSummary7, tableSummary30, tableSummary90, chartSummary, surveyTypes]: [SummaryEntry, SummaryEntry, SummaryEntry, SummaryEntry, SummaryByDateRange, SurveyType[]] = yield all([
            call(() => api.getReportSummary(filters)),
            call(api.getReportSummary, filters, { periodType: "d", period: 7 }),
            call(api.getReportSummary, filters, { periodType: "d", period: 30 }),
            call(api.getReportSummary, filters, { periodType: "d", period: 90 }),
            call(api.getGroupedFeedbackSummary, { periodType: "m", period: 12 }, filters),
            call(api.getSurveyTypes)
        ]);

        const tableSummaryModel = reportingMappers.summaryEntryToModel(tableSummary);
        const tableSummary7Model = reportingMappers.summaryEntryToModel(tableSummary7);
        const tableSummary30Model = reportingMappers.summaryEntryToModel(tableSummary30);
        const tableSummary90Model = reportingMappers.summaryEntryToModel(tableSummary90);
        const chartSummaryModel = reportingMappers.summaryByDateRangeToModel(chartSummary, surveyTypes);

        yield put(actions.getReportSummaryCompleted({
            tableSummary: [tableSummary7Model, tableSummary30Model, tableSummary90Model, tableSummaryModel],
            chartSummary: chartSummaryModel,
        }));

    } catch(e) {

    } finally {

    }
}

function* leaveFeedback(action: ActionType<typeof actions.leaveFeedback>) {

    yield put(loadingActions.begin());

    try {
        const model = action.payload;
        const url = 'feedback/token'
        yield put(actions.validateFeedbackModel({ ...model }));

        const isValid: ActionType<typeof actions.feedbackModelValidated> = yield take(getType(actions.feedbackModelValidated));
        if (!isValid.payload) {
            return;
        }

        const data = mappers.feedbackFromModel(action.payload);

        const authResponseBody: Tina4SignInTokenResult = yield call(() => authApi.initialAuthToken(url));

        // const response: Response = yield call(api.leaveFeedback, data);
        const response: Response = yield call(() => api.leaveFeedback(data, authResponseBody.access_token));

        if(response.ok) yield put(actions.leaveFeedbackCompleted());

    } catch(e) {
        yield put(loadingActions.reset());
    } finally {
        yield put(loadingActions.complete());
    }
}

function* validateFeedbackModel(action: ActionType<typeof actions.validateFeedbackModel>) {
    const model = action.payload;
    const isValid: boolean = yield validateFeedbackMessageModelBase(model);
    yield put(actions.feedbackModelValidated(isValid));
}

function* validateFeedbackMessageModelBase(model: FeedbackModel) {
    yield put(errorActions.clearValidationErrors());
    yield put(errorActions.clearErrors());

    const validationErrors: ValidationError[] = [];

    if(!model.customerName) {
        validationErrors.push({ name: 'CustomerFullName', message: 'Full name cannot be empty' });
    }

    if(!model.message) {
        validationErrors.push({ name: 'FeedbackMessage', message: 'Message cannot be empty' });
    }

    if (validationErrors.length) {
        yield put(errorActions.setValidationErrors(validationErrors));
        return false;
    }

    return true;
}

function* toggleFeedbackIsArchived(action: ActionType<typeof actions.toggleFeedbackIsArchived>) {

    yield put(loadingActions.begin());

    try {
        const data = action.payload;

        const feedback: ToggleFeedbackModel = yield call(() => api.toggleFeedbackIsArchived(data));

        yield put(actions.toggleFeedbackIsArchivedCompleted());

    } catch(e) {
        yield put(loadingActions.reset());
    } finally {
        yield put(loadingActions.complete());
    }
}



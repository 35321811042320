import { Box } from "@material-ui/core";
import React from "react";
import SectionComponent from "../../shared/components/SectionComponent";
import { uploadLogo } from "../settingsActions";
import ImageUploadComponent from "./ImageUploadComponent";

type LogoSettingsProps = {
    logo: string,

    uploadLogo: typeof uploadLogo,
};

const LogoSettings = (props: LogoSettingsProps) => {
    const { logo } = props;

    const uploadFileHandler = (file: File) => {
        if(file) props.uploadLogo({file: file});
        return true;
    };

    return (

        <Box>
            <SectionComponent marginTop="39px">COMPANY LOGO</SectionComponent>
            <Box className="logosettings-container content-block">
                <ImageUploadComponent currentImage={logo} uploadFileAction={uploadFileHandler} inputLabel={"Current Logo"} />
            </Box>
        </Box>

    )
}

export default LogoSettings;

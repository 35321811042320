import React from "react";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {appSettings} from "../../config/appSettings";

const staticDataUrl = appSettings.app.staticDataUrl;

type IconSetComponentProps = {
    actionCount: number;

    icon: {
        exceededExpectation: string;
        satisfied: string;
        dissatisfied: string;
        verydissatisfied: string;
        great: string;
    };
    spacing?: number;
    size?: number;
};

const useStyles = makeStyles((theme) => ({
    iconTitle: {
        lineHeight: "45px",
    },
    centerIcon: {
        padding: "0 3rem",
    },
}));

const IconSetComponent = ({icon, actionCount, spacing, size}: IconSetComponentProps) => {
    const classes = useStyles();

    let satisfiedIcon: boolean = true;

    if (!icon.satisfied || actionCount === 2) {
        satisfiedIcon = !satisfiedIcon;
    }
    const iconSize = `${size ? size : 50}px`;

    const iconBoxStyle = {
        margin: `0 ${spacing ? spacing/2 : 10}px`,
    }

    return (
        <>
            <Box style={iconBoxStyle} >
                <img width={iconSize} src={staticDataUrl + icon.verydissatisfied} alt="Mood" loading={"lazy"} />
            </Box>
            {actionCount === 5 && icon.dissatisfied &&
                <Box style={iconBoxStyle}>
                    <img width={iconSize} src={staticDataUrl + icon.dissatisfied} alt="Mood" loading={"lazy"} />
                </Box>
            }
            {satisfiedIcon && (
                <Box style={iconBoxStyle}>
                    <img width={iconSize} src={staticDataUrl + icon.satisfied} alt="Mood" loading={"lazy"} />
                </Box>
            )}
            {actionCount === 5 && icon.exceededExpectation &&
                <Box style={iconBoxStyle}>
                    <img
                        width={iconSize}
                        src={staticDataUrl + icon.exceededExpectation}
                        alt="Mood"
                        loading={"lazy"}
                    />
                </Box>
            }
            <Box style={iconBoxStyle}>
                <img width={iconSize} src={staticDataUrl + icon.great} alt="Mood" loading={"lazy"} />
            </Box>
        </>
    );
};

export default IconSetComponent;

import React from 'react';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';

import Page404 from './NotFountPage';
import BasicError from './BasicError';
import { CustomError, ErrorType } from './ErrorModels';
import ErrorSnackBar from './ErrorSnackBar';
import { ApplicationState } from '../../ApplicationState';
import * as selectors from './errorSelectors';

type Props = {
    serverError: ErrorType | null,
    children: JSX.Element,
    errs: CustomError[],
}

const ErrorContentHandler = (error: ErrorType) => {
  switch (error.code) {
    case 404:
      return <Page404 />;
      // ... cases for other types of errors
      
    default:
      return <BasicError message={error.message} code={error.code} />;
      // return children
  }
}

const ErrorHandler: React.FC<Props> = (props) => {
  const {
    serverError, errs, children
  } = props;

  if(serverError || errs.length){
    if(serverError) {
      return (
        <Box className="error_container">
          <Box className="error_wrap">
            { ErrorContentHandler(serverError) }
          </Box>
        </Box>
      )
    } else {
      return (
        <>
          { children }
          <ErrorSnackBar />
        </>
      ) 
    }

  }
  return children
  
};

const mapStateToProps = (state: ApplicationState) => {
    return {
        serverError: selectors.serverSelector(state),
        errs: selectors.snackBarErrorsSelector(state),
    }  
} 

const mapDispatchToProps = {  }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ErrorHandler);
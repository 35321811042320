import React, {useEffect} from "react";
import {connect} from "react-redux";
import SectionComponent from "../../shared/components/SectionComponent";
import { getSurveySettings, toggleAnonymousSetting } from "../settingsActions";
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import {SurveySetting} from "../SettingsModels";

type FormCustomizationsProps = {
    getSurveySettings: typeof getSurveySettings;
    toggleAnonymousSetting: typeof toggleAnonymousSetting;
    surveySettings: SurveySetting;
};

const FormCustomizationsComponent = (props: FormCustomizationsProps) => {
    const { getSurveySettings, surveySettings, toggleAnonymousSetting } = props;
    useEffect(() => {
        getSurveySettings();
    }, []);

    const handleToggleAnonymousFeedback = () => {
        // handle toggle anonymous feedback
        toggleAnonymousSetting({ ...surveySettings, anonymousActive: surveySettings.anonymousActive === 1 ? 0 : 1 });
    }

    return (
        <Box className="email-notification">
            <SectionComponent marginTop="42px" width="558px" >FORM CUSTOMIZATIONS</SectionComponent>
            <Box className='content-block table'>
                <TableContainer>
                    <Table className="tbl" size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Type</TableCell>
                                <TableCell align="center">Enabled</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography>Anonymous Feedback (All Forms)</Typography>
                                </TableCell>
                                <TableCell><input type={"checkbox"} checked={surveySettings.anonymousActive === 1} onChange={handleToggleAnonymousFeedback} /></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

const mapDispatchToProps = {
    getSurveySettings,
    toggleAnonymousSetting,
}

const mapStateToProps = (state: any) => {
    return {
        surveySettings: state.settings.surveySettings,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormCustomizationsComponent);
import {Box, CircularProgress, Typography} from "@material-ui/core";

interface LoadingProps {
    message?: string;
}

function Loading(props: LoadingProps) {
    const message = props.message || "Loading...";
    return (<Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
            <CircularProgress/>
            <Box p={2}>
                <Typography variant={"h5"} color="textSecondary">{message}</Typography>
            </Box>
        </Box>);
}

export default Loading;

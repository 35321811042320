import { takeLatest, put, call, select, take } from "redux-saga/effects";
import { AddCreditCardResponse, Administrator, BillingInfo, ConfirmPaymentStatus, CreditCard, InviteAdministratorRequest, Invoice, InvoicePDFResponse, CouponCode } from "./AccountData";

import * as actions from './accountActions';
import * as errorActions from "../shared/errors/errorActions";
import * as sharedActions from "../shared/sharedActions";
import * as loadingActions from '../shared/loading/loadingStateActions';

import * as mockApi from '../api/mockApi';
import * as api from '../api/index';

import {
    BillingInfoModel,
    UpdateBillingInfoModel,
    StripeSetupIntentModel,
    UpdateCreditCardModel,
    AddCreditCardModel,
    ConfirmPaymentRequest,
    SubmitCouponCodeModel
} from "./AccountModels";
import { ReturnResultData } from "../shared/SharedData";
import { ActionType, getType } from "typesafe-actions";

import * as sharedSelectors from "../shared/sharedSelectors";
import * as mappers from "./accountMappers";
import { OrganizationModel } from "../shared/SharedModels";
import { ValidationError } from "../shared/errors/ErrorModels";

import { appSettings } from '../config/appSettings';
import {ApiError, BadRequestError, UnauthorizedError} from "../auth/ApiError";
import { customErrorMapper } from "../shared/errors/ErrorMappers";
import {submitCouponCodeCompleted} from "./accountActions";
import {SurveyAnswerFormDetails} from "../Feedback/FeedbackData";

const apiName = appSettings.apisMetadata.find(x => x.id === 'rms')!.name;
const apiUrlRoot = appSettings.apisMetadata.find(x => x.id === 'rms')!.baseAddress;

export default function* accountSaga() {
    yield takeLatest(getType(actions.getBillingInfo), getBillingInfo);
    yield takeLatest(getType(actions.updateBillingInfo), updateBillingInfo);
    yield takeLatest(getType(actions.addCreditCard), addCreditCard);
    yield takeLatest(getType(actions.updateCreditCard), updateCreditCard);
    yield takeLatest(getType(actions.getCreditCardInfo), getCreditCardInfo);
    yield takeLatest(getType(actions.validateCreditCardModel), validateCreditCardModel);
    yield takeLatest(getType(actions.validateBillingInfoModel), validateBillingInfoModel);
    yield takeLatest(getType(actions.createSetupIntent), createSetupIntent);
    yield takeLatest(getType(actions.confirmStripePayment), confirmStripePayment);
    yield takeLatest(getType(actions.updateSubscription), updateSubscription);
    yield takeLatest(getType(actions.deactivateSubscription), deactivateSubscription);
    yield takeLatest(getType(actions.getInvoices), getInvoices);
    yield takeLatest(getType(actions.getUsers), getUsers);
    yield takeLatest(getType(actions.deleteUser), deleteUser);
    yield takeLatest(getType(actions.getInvoice), getInvoice);
    yield takeLatest(getType(actions.setDefaultCreditCard), setDefaultCreditCard);
    yield takeLatest(getType(actions.inviteUser), inviteUser);
    yield takeLatest(getType(actions.submitCouponCode), submitCouponCode);
    yield takeLatest(getType(actions.loadCouponCodes), loadCouponCodes);
}

function* getBillingInfo(action: ActionType<typeof actions.getBillingInfo>){
    try {

        // inside function or not (for decrease duplication)
        const response: ReturnResultData<BillingInfo> = yield call(mockApi.getBillingInfo, action.payload);

        const model: BillingInfoModel = { ...response.data };

        yield put(actions.getBillingInfoCompleted(model));

    } catch(e) {
        console.error(e);
    } finally {

        // stop loader
    }
}

function* updateBillingInfo(action: ActionType<typeof actions.updateBillingInfo>){
    try {
        const model = action.payload;
        // credit card validation
        yield put(actions.validateBillingInfoModel({ ...model }));

        const isValid: ActionType<typeof actions.validateBillingInfoModelCompleted> = yield take(getType(actions.validateBillingInfoModelCompleted));
        if (!isValid.payload) {
            return;
        }

        const data = mappers.updateBillingInfoDetailsFromModel(model);

        yield call(api.updateBillingInfo, data);

        yield put(actions.updateBillingInfoCompleted(model));

    } catch(e) {
        console.error(e);
    } finally {

        // stop loader
    }
}

function* addCreditCard(action: ActionType<typeof actions.addCreditCard>){
    yield put(loadingActions.begin());

    try {
        const model = action.payload;
        // credit card validation
        yield put(actions.validateCreditCardModel({ ...model }));

        const isValid: ActionType<typeof actions.validateCreditCardModelCompleted> = yield take(getType(actions.validateCreditCardModelCompleted));
        if (!isValid.payload) {
            return;
        }

        const organization: OrganizationModel = yield select(sharedSelectors.organizationSelector);
        const data = mappers.addCreditCardFromModel(model);

        const response: AddCreditCardResponse = yield call(api.addCreditCard, data);
        // handle payment status
        if(response.paymentStatus) handlePaymentResponse(response.paymentStatus);
        yield put(sharedActions.getProfile());

        const cardModel = mappers.creditCardToModel(response.paymentMethod);

        yield put(actions.addCreditCardCompleted(cardModel));

    } catch(e) {

        console.error(e);
        yield put(loadingActions.reset());

    } finally {
        yield put(loadingActions.complete());
    }
}

function* updateCreditCard(action: ActionType<typeof actions.updateCreditCard>){
    try {
        const model = action.payload;
        // credit card validation
        yield put(actions.validateCreditCardModel({ ...model }));

        const isValid: ActionType<typeof actions.validateCreditCardModelCompleted> = yield take(getType(actions.validateCreditCardModelCompleted));
        if (!isValid.payload) {
            return;
        }

        // delete organizationId from backend model and here;
        const organization: OrganizationModel = yield select(sharedSelectors.organizationSelector);
        const data = mappers.creditCardFromModel(model);

        yield call(api.updateCreditCard, data);

        yield put(actions.updateCreditCardCompleted(model));

    } catch(e) {
        console.error(e);
    } finally {

        // stop loader
    }
}

function* getCreditCardInfo(action: ActionType<typeof actions.getCreditCardInfo>){
    try {
        // const data: CreditCard[] = yield call(mockApi.getCreditCardInfo)

        const cards: CreditCard[] = yield call(api.getCreditCardInfo);
        const model = cards.map(card => mappers.creditCardToModel(card));

        yield put(actions.getCreditCardInfoCompleted(model));

    } catch(e) {
        console.error(e);
    } finally {
        // stop loader
    }
}

function* validateCreditCardModel(action: ActionType<typeof actions.validateCreditCardModel>){
    const model = action.payload;
    const isValid: boolean = yield validateCreditCardModelBase(model);
    yield put(actions.validateCreditCardModelCompleted(isValid));
}

function* validateCreditCardModelBase(model: AddCreditCardModel | UpdateCreditCardModel) {
    yield put(errorActions.clearValidationErrors());
    yield put(errorActions.clearErrors());

    const validationErrors: ValidationError[] = [];

    // if(!model.cardNumber) {
    //     validationErrors.push({ name: 'CardNumber', message: 'Card number cannot be empty' });
    // }

    // if(!model.secretCode) {
    //     validationErrors.push({ name: 'CVV', message: 'Verification code cannot be empty' });
    // }

    // if (!/^\d+$/.test(model.cardNumber) && !validationErrors.some(x => x.name === 'CardNumber')) {
    //     validationErrors.push({ name: 'CardNumber', message: 'Digits only' });
    // }

    // if (!/^\d+$/.test(model.secretCode) && !validationErrors.some(x => x.name === 'CVV')) {
    //     validationErrors.push({ name: 'CVV', message: 'Digits only' });
    // }

    // if (!model.cardholderName) {
    //     validationErrors.push({ name: 'CardholderName', message: 'Cardholder`s Full Name cannot be empty' });
    // }

    // if (!model.expirationMonth) {
    //     validationErrors.push({ name: 'ExpirationMonth', message: 'Expiration Month cannot be empty' });
    // }

    // if (!model.expirationYear) {
    //     validationErrors.push({ name: 'ExpirationYear', message: 'Expiration Year cannot be empty' });
    // }

    if(validationErrors.length) {
        yield put(errorActions.setValidationErrors(validationErrors));
        return false;
    }

    return true;
}

function* validateBillingInfoModel(action: ActionType<typeof actions.validateBillingInfoModel>){
    const model = action.payload;
    const isValid: boolean = yield validateBillingInfoModelBase(model);
    yield put(actions.validateBillingInfoModelCompleted(isValid));
}

function* validateBillingInfoModelBase(model: UpdateBillingInfoModel) {
    yield put(errorActions.clearValidationErrors());
    yield put(errorActions.clearErrors());

    const validationErrors: ValidationError[] = [];

    const emailRegExp = /^\S+@\S+\.\S+$/;

    if(!model.user) {
        validationErrors.push({ name: 'FullName', message: 'Full name cannot be empty' });
    }

    if(!model.organizationFullName) {
        validationErrors.push({ name: 'OrganizationName', message: 'Organization name cannot be empty' });
    }

    if (!model.email) {
        validationErrors.push({ name: 'Email', message: 'Email cannot be empty' });
    } else if (!model.email.match(emailRegExp)) {
        validationErrors.push({ name: 'Email', message: 'The wrong format is used for email' });
    }

    if(validationErrors.length) {
        yield put(errorActions.setValidationErrors(validationErrors));
        return false;
    }

    return true;
}

function* createSetupIntent(action: ActionType<typeof actions.createSetupIntent>) {
    try {
        const setupIntent: StripeSetupIntentModel = yield call(api.createStripeSetupIntent);
        yield put(actions.createSetupIntentCompleted(setupIntent));
    } catch {

    } finally {

    }
}

function* confirmStripePayment(action: ActionType<typeof actions.confirmStripePayment>) {
    const request: ConfirmPaymentRequest = action.payload;

    try {
        const paymentResponse: ConfirmPaymentStatus = yield call(api.pay, request);
        yield call(() => handlePaymentResponse(paymentResponse));

    } catch {

    } finally {

    }
}

function* handlePaymentResponse(response: ConfirmPaymentStatus) {

    if(response.error) {
        yield put(errorActions.addError({ id: Date.now(), message: response.error }));
    } else if(response.requiresAction) {
        yield put(actions.setRequireStripeAction({ actionRequired: true, paymentIntentClientSecret: response.paymentIntentClientSecret}));
    } else if(response.success) {
        yield put(actions.setSuccessfulPaymentFlag(true));
    }
}

function* updateSubscription(action: ActionType<typeof actions.updateSubscription>){
    const subscriptionId = action.payload;

    yield put(loadingActions.begin());

    try
    {
        const response: ConfirmPaymentStatus = yield call(() => api.updateSubscription(subscriptionId));

        if(response.success) yield put(actions.setUpdateSubscriptionFlag(true));
        yield call(handlePaymentResponse, response);

        if(response.success) yield put(sharedActions.getOrganizationPlan());

        yield put(sharedActions.getProfile());
    }
    catch
    {
        yield put(loadingActions.reset());
    }
    finally
    {
        yield put(loadingActions.complete());
        yield put(actions.setUpdateSubscriptionFlag(false));
    }
}

function* deactivateSubscription(action: ActionType<typeof actions.deactivateSubscription>){

    yield put(loadingActions.begin());

    try
    {
        yield call(() => api.deactivateSubscription());
        yield put(actions.setDeactivateSubscriptionFlag(true));

        yield put(sharedActions.getProfile());
    }
    catch
    {
        yield put(loadingActions.reset());
    }
    finally
    {
        yield put(loadingActions.complete());
    }
}

function* getInvoices(action: ActionType<typeof actions.getInvoices>){
    try
    {
        const invoices: Invoice[] = yield call(() => api.getInvoices());
        const model = invoices.map(invoice => mappers.invoiceToModel(invoice));
        yield put(actions.getInvoicesCompleted(model));
    }
    catch
    {

    }
    finally
    {

    }
}

function* getUsers(action: ActionType<typeof actions.getUsers>){
    try
    {
        const admins: Administrator[] = yield call(() => api.getUsers());
        const model = admins.map(admin => mappers.administratorToModel(admin));
        yield put(actions.getUsersCompleted(model));

    } catch {

    } finally {

    }
}

function* deleteUser(action: ActionType<typeof actions.deleteUser>){
    const id = action.payload;

    try
    {
        yield call(() => api.deleteUser(id));
        yield put(actions.deleteUserCompleted(id));

    } catch {

    } finally {

    }
}

function* getInvoice(action: ActionType<typeof actions.getInvoice>) {
    const id = action.payload;

    try {
        // test
        const invoice: BlobPart = yield call(function*() {
            const tokenObj = JSON.parse(localStorage.getItem("api")!);
            const token = tokenObj["access_token"];

            const response: Response = yield fetch(apiUrlRoot + `/${apiName}/billing-information/invoice/${id}`, {
                headers: {
                    "Authorization": "Bearer " + token,
                }
            });

            if(response.ok) {
                const blob: BlobPart = yield response.blob();
                return blob;
            }
        });
        //var arrBuffer = base64ToArrayBuffer(invoice);
        const blob = new Blob([invoice], {type: 'application/pdf'});
        var link=document.createElement('a');
        link.href=window.URL.createObjectURL(blob);
        link.download="Report_"+new Date()+".pdf";
        link.click();

    } catch {

    } finally {

    }
}

function* setDefaultCreditCard(action: ActionType<typeof actions.setDefaultCreditCard>) {
    const cardId = action.payload;

    try {
        const response: ConfirmPaymentStatus = yield call(api.setDefaultCreditCard, cardId);
        yield put(actions.getCreditCardInfo());
        yield call(handlePaymentResponse, response);

    } catch {

    } finally {

    }
}

function* inviteUser(action: ActionType<typeof actions.inviteUser>) {
    const model = action.payload;

    const inviteUserRequest: InviteAdministratorRequest = {
        fullName: model.fullName,
        email: model.email,
    };

    try {
        yield call(api.inviteUser, inviteUserRequest);
        yield put(actions.setInviteUserFlag(true));
        yield put(actions.getUsers());

    } catch(e) {
        if(e instanceof BadRequestError || e instanceof ApiError) {
            const err = customErrorMapper(e.message);
            yield put(errorActions.addError(err));
        }

    } finally {

    }
}

export function* loadCouponCodes(action: ActionType<typeof actions.loadCouponCodes>) {
    try {
        const coupons: CouponCode[] = yield call(api.loadCouponCodes);
        const model = coupons.map((coupon: CouponCode) => mappers.couponCodeToModel(coupon));

        yield put(actions.loadCouponCodesCompleted(model));
    } catch {

    } finally {

    }
}

function* submitCouponCode(action: ActionType<typeof actions.submitCouponCode>){
    const model = action.payload;

    const submitCouponCodeModel: SubmitCouponCodeModel = {
        couponCode: model.couponCode,
        organizationId: model.organizationId
    };

    try {
        const response: Response = yield call(api.submitCouponCode, submitCouponCodeModel);

        if (response) {
            yield put(actions.submitCouponCodeCompleted(true));
            window.location.reload();
        } else {
            const err = customErrorMapper('Invalid coupon code');

            yield put(actions.submitCouponCodeCompleted(false));
            yield put(errorActions.addError(err));
        }

    } catch(e) {

        if(e instanceof BadRequestError) {

            const err = customErrorMapper(e.message);
            yield put(errorActions.addError(err));
        }

    } finally {

    }
}

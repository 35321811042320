import React, {useEffect} from "react";
import {connect} from "react-redux";
import {
    RouteComponentProps,
    Route,
    Switch,
    withRouter, Redirect, useHistory
} from "react-router-dom";
import {ThemeProvider, createTheme} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";

import Account from "./AccountPage/AccountPage";
import Feedback from "./Feedback/components/FeedbackPage";
import Reviews from "./ReviewsPage/components/ReviewsPage";
import Reporting from "./ReportingPage/components/ReportingPage";
import SignUp from "./EntryPages/SignUpPage/components/SignUpPage";
import SignIn from "./EntryPages/SignInPage/SignInPage";
import SignOut from "./EntryPages/SignOutComponent";
import UnsubscribePage from "./EntryPages/EmailUnsubscribePage/UnsubscribePage";
import ErrorContentHandler from "./shared/errors/ErrorHandler";
import Loading from "./shared/loading/LoadingStateContainer";
import Setup from "./SetupPage/SetupPage";
import Settings from "./SettingsPage/components/SettingsPage";

import "./App.scss";
import {login, setPrimaryToken} from "./auth/redux/actions";
import {redirect, setReturnUrl} from "./shared/router/routerActions";
import {getWhiteLabelProfile, setServiceIsDisabledFlag, whiteLabelCheckCompleted} from "./shared/sharedActions";

import AppWrapper from "./shared/components/AppWrapper";
import NotFoundPage from "./shared/errors/NotFountPage";
import OAuthCallback from "./EntryPages/OAuthCallbackComponent";
import {appSettings} from "./config/appSettings";
import Cookies from "universal-cookie/es6";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {ApplicationState} from "./ApplicationState";
import {UserProfileModel, WhiteLabelProfile} from "./shared/SharedModels";
import VerifyOrganization from "./shared/components/VerifyOrganization";
import ForgotPasswordPage from "./EntryPages/ResetPasswordPage/components/ForgotPasswordPage";
import ResetPasswordPage from "./EntryPages/ResetPasswordPage/components/ResetPasswordPage";
import SubmitFeedbackWrapper from "./Feedback/components/SubmitFeedbackWrapper";
import SignaturePageAgentView from "./SetupPage/components/SignaturePageAgentView";

import {loadingSelector} from "./shared/sharedSelectors";
import {LoadingStateMap} from "./shared/loading/LoadingStateModel";
import {RouteProps} from "react-router";
import ReCaptchaRouteComponent from "./shared/components/ReCaptchaRouteComponent";
import * as sharedSelectors from "./shared/sharedSelectors";

const apiName = appSettings.apisMetadata.find((x) => x.id === "rms")!.name;
const stripeKey = appSettings.Stripe.publishableKey;
const stripePromise = loadStripe(stripeKey);

interface StateProps {
    userProfile: UserProfileModel | undefined;
    navigateUrl: string;
    loadingState: LoadingStateMap;
    getWhiteLabelProfile: typeof getWhiteLabelProfile;
    login: typeof login;
    setPrimaryToken: typeof setPrimaryToken;
    setServiceIsDisabledFlag: typeof setServiceIsDisabledFlag;
    redirect: typeof redirect;
    setReturnUrl: typeof setReturnUrl;
    whiteLabel?: WhiteLabelProfile;
    whiteLabelCheckCompleted: typeof whiteLabelCheckCompleted
}

const AppWrapperComponent = (child: JSX.Element) => {
    return <AppWrapper>{child}</AppWrapper>;
};

const rmsTheme = createTheme({
    typography: {
        fontFamily: "inherit",
    },
    palette: {
        primary: {
            main: "#3B76EF",
            contrastText: "#F1F1F1",
        },
        secondary: {
            main: "#69CC3D",
            contrastText: "#FFFFFF",
        },
        warning: {
            main: "#EFA13B",
            contrastText: "#fff",
        },
        text: {
            secondary: "#fff",
        },
    },
    overrides: {
        MuiTouchRipple: {
            root: {
                display: "none",
            },
        },
        MuiButton: {
            root: {
                borderRadius: "35px",
                textTransform: "none",
            },
            contained: {
                backgroundColor: "inherit",
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: "6px",
            },
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: "transparent",
            },
        },
        MuiContainer: {
            root: {
                padding: 0,
            },
        },
        MuiStepper: {
            root: {
                padding: "0 100px 10px 100px",
            },
        },
        MuiStep: {
            horizontal: {
                borderRadius: "5px",
                paddingLeft: "0px",
                paddingRight: "0px",

                width: "268px",
                backgroundColor: "#FFFFFF",
            },
        },
        MuiStepLabel: {
            root: {
                display: "flex",
                flexDirection: "row-reverse",

                height: "85px",
            },
            label: {
                color: "inherit",
            },
        },
        MuiFormLabel: {
            root: {
                color: "#8A8A8A",
            },
        },
        MuiPaper: {
            root: {
                transition: "none",
                color: "inherit",
                backgroundColor: "#F5F5F5",
            },
            elevation4: {
                boxShadow: "none",
            },
        },
        MuiTableBody: {
            root: {
                backgroundColor: "#fff",
            },
        },
        MuiTableHead: {
            root: {
                backgroundColor: "#F1F1F1",
            },
        },
        MuiTabs: {
            indicator: {
                backgroundColor: "transparent",
            },
        },
        MuiFormControl: {
            root: {
                display: "block",
            },
        },
        MuiInputBase: {
            root: {
                width: "100%",
            },
        },
        MuiInput: {
            underline: {
                borderBottom: "none",
            },
        },
        MuiInputLabel: {
            outlined: {
                zIndex: 0,
            },
        },
        MuiTableCell: {
            root: {
                textAlign: "center",
            },
        },
        MuiSelect: {
            root: {
                height: "40px",
            },
            outlined: {
                borderRadius: "30px",
            },
            select: {
                focus: {
                    borderRadius: "inherit",
                },
            },
        },
        MuiTypography: {
            root: {
                fontFamily: "inherit",
                margin: 0,
            },
            colorTextPrimary: {
                color: "#4B5A7A",
            },
            colorTextSecondary: {
                color: "inherit",
            },
            body1: {
                lineHeight: "inherit",
            },
        },
        MuiSnackbar: {
            root: {
                flexDirection: "column",
                alignItems: "normal",
            },
        },
        MuiCheckbox: {
            root: {
                color: "#D6D6D6",
            },
        },
    },
    zIndex: {},
});

const PrivateRoute: React.FC<RouteProps> = (props) => {
    return (
        <Route
            {...props}
        >{
            localStorage.getItem('api') ? (
                props.children
            ) : (
                <Redirect to={{pathname: "/signin"}}/>
            )
        }</Route>
    );
};



const App: React.FunctionComponent<
    RouteComponentProps<{ orgid: string }> & StateProps
> = (props) => {
    const history = useHistory();
    const loading = props.loadingState.default?.isLoading || false;
    useEffect(() => {
        props.getWhiteLabelProfile();

        const cookie = new Cookies();
        const tokens = cookie.get("rms-tokens");
        const orgId = cookie.get("rms-orgId");
        const primaryToken = cookie.get("rms-primary-tokens");

        if (tokens) {
            if (primaryToken) {
                props.setPrimaryToken({name: "primaryToken", tokens: primaryToken});
            }

            props.login({
                apiName,
                tokens,
            });

            history.replace(`/${orgId}/setup/customize`);
            cookie.remove("rms-tokens", {path: "/"});
            cookie.remove("rms-orgId", {path: "/"});
            cookie.remove("rms-primary-tokens", {path: "/"});
        }
    }, []);

    const listener = (event: any) => {
        if (event.data.tokens) {
            const cookie = new Cookies();
            cookie.set("rms-tokens", event.data.tokens, {path: "/"});
            cookie.set("rms-primary-tokens", event.data.primaryToken, {path: "/"});
            cookie.set("rms-orgId", event.data.orgId, {path: "/"});
        }
    };

    if (window.addEventListener) {
        window.addEventListener("message", listener);
    }

    return (
        <ThemeProvider theme={rmsTheme}>
            <Elements stripe={stripePromise}>
                <Box className="App">
                    {loading ? <Loading /> :
                        <ErrorContentHandler>
                            <Switch>
                                <ReCaptchaRouteComponent path="/" exact whiteLabel={props.whiteLabel}>
                                    <SignIn />
                                </ReCaptchaRouteComponent>
                                <ReCaptchaRouteComponent path="/signup" whiteLabel={props.whiteLabel}>
                                    <SignUp />
                                </ReCaptchaRouteComponent>
                                <ReCaptchaRouteComponent path="/signup/appsumo" whiteLabel={props.whiteLabel}>
                                    <SignUp />
                                </ReCaptchaRouteComponent>
                                <ReCaptchaRouteComponent path="/signin" whiteLabel={props.whiteLabel}>
                                    <SignIn />
                                </ReCaptchaRouteComponent>
                                <Route path="/signout" component={SignOut} />
                                <ReCaptchaRouteComponent path={"/email-unsubscribe"} whiteLabel={props.whiteLabel}>
                                    <UnsubscribePage />
                                </ReCaptchaRouteComponent>
                                <ReCaptchaRouteComponent path="/forgotpassword" whiteLabel={props.whiteLabel}>
                                    <ForgotPasswordPage />
                                </ReCaptchaRouteComponent>
                                <ReCaptchaRouteComponent path="/account/resetpassword" whiteLabel={props.whiteLabel}>
                                    <ResetPasswordPage />
                                </ReCaptchaRouteComponent>
                                <Route path="/oauthcallback">
                                    <OAuthCallback />
                                </Route>
                                <ReCaptchaRouteComponent path="/:orgid/UserInvite/:userid" whiteLabel={props.whiteLabel}>
                                    <SignUp />
                                </ReCaptchaRouteComponent>
                                <ReCaptchaRouteComponent path="/:orgid/submitsurvey/:agentid/:type" whiteLabel={props.whiteLabel}>
                                    <SubmitFeedbackWrapper/>
                                </ReCaptchaRouteComponent>
                                <Route
                                    path="/:orgId/setup/signature/agentview/:agentid"
                                    component={SignaturePageAgentView}
                                />
                                <PrivateRoute path="/:orgid/setup/:step">
                                    {AppWrapperComponent(<Setup/>)}
                                </PrivateRoute>
                                <PrivateRoute path="/:orgid/customerfeedback">
                                    {AppWrapperComponent(<Feedback/>)}
                                </PrivateRoute>
                                <PrivateRoute path="/:orgid/reviews">
                                    {AppWrapperComponent(<Reviews/>)}
                                </PrivateRoute>
                                <PrivateRoute path="/:orgid/reporting">
                                    {AppWrapperComponent(<Reporting/>)}
                                </PrivateRoute>
                                <PrivateRoute path="/:orgid/account/:tab">
                                    {AppWrapperComponent(<Account/>)}
                                </PrivateRoute>
                                <PrivateRoute path="/:orgid/settings">
                                    {AppWrapperComponent(<Settings/>)}
                                </PrivateRoute>
                                <ReCaptchaRouteComponent path="/:orgid/VerifyNewOrg" whiteLabel={props.whiteLabel}>
                                    <VerifyOrganization/>
                                </ReCaptchaRouteComponent>
                                <Route component={NotFoundPage}/>
                            </Switch>
                        </ErrorContentHandler>}
                </Box>
            </Elements>
        </ThemeProvider>
    );
};

// include state types
const mapStateToProps = (state: ApplicationState) => {
    return {
        userProfile: state.globals.profile,
        verifying: state.globals.verifying,
        loadingState: loadingSelector(state),
        whiteLabel: sharedSelectors.whiteLabelSelector(state),
        // navigateUrl: state.auth.navigateUrl
    };
};

const mapDispatchToProps = {
    login,
    setPrimaryToken,
    redirect,
    setServiceIsDisabledFlag,
    getWhiteLabelProfile,
    whiteLabelCheckCompleted
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default withRouter(AppContainer);

import { Box } from '@material-ui/core'

type Props = {
    message: string,
    code: number,
}

const BasicError: React.FC<Props> = ({ message, code }) => {
    return (
        <Box>
            <h1>Error {code ? code : ''}</h1>
            {message}
        </Box>
    )
}

export default BasicError
import { ApplicationState } from "../../ApplicationState";
import { InvitedUserProfileModel } from "../SharedModels";
import { CustomError, ErrorType, ValidationError } from "./ErrorModels";

export const snackBarErrorsSelector = (state: ApplicationState): CustomError[] | [] => {
    return state.errors.stack;
};

export const validationSelector = (state: ApplicationState): ValidationError[] | [] => {
    return state.errors.validation;
};

// type ??
export const serverSelector = (state: ApplicationState): ErrorType | null => {
    return state.errors.server;
};
import { SubscriptionPlan } from "./SharedData";
import { SubscriptionPlanModel } from "./SharedModels";

export const subscriptionPlanToModel = (plan: SubscriptionPlan): SubscriptionPlanModel => {
    return {
        planId: plan.planId,
        title: plan.title,
        agentsLimitMin: plan.agentsLimitMin,
        agentsLimitMax: plan.agentsLimitMax,
        pricePerAgent: plan.pricePerAgent,
        typeName: plan.typeName,
        priceTotal: plan.priceTotal,
        discountPercent: plan.discountPercent,
    }
};
const parseJwt = (token: string) => {
    

    try {
        if (!token) {
            throw new Error('parseJwt# Token is required.');
        }

        const base64Payload = token.split('.')[1];
        let payload = new Uint8Array();

        try {
            payload = Buffer.from(base64Payload, 'base64');
        } catch (err) {
            throw new Error(`parseJwt# Malformed token: ${err}`);
        }

        return {
            // decodedToken: JSON.parse(payload),
            decodedToken: JSON.parse(payload.toString()),
        };
    } catch (err) {
        console.log(`Bonus logging: ${err}`);

        return {
            error: 'Unable to decode token.',
        };
    }
};

export const getExpirationDate = (jwtToken?: string): number | null => {
    if (!jwtToken) {
        return null;
    }

    // const jwt = JSON.parse(atob(jwtToken.split('.')[1]));
    const jwt = parseJwt(jwtToken);

    // multiply by 1000 to convert seconds into milliseconds
    // return jwt && jwt.exp && jwt.exp * 1000 || null;
    return jwt && jwt.decodedToken && jwt.decodedToken.exp || null;
};

export const isExpired = (exp?: number | null) => {
    if (!exp) {
        return false;
    }

    // convertation where should it be ??
    return Date.now() > (exp*1000);
};

// const setToken = (apiName: string, tokens: TokenPair) => {
//     const api = getApi(apiName);

//     if (api) {
//         const json = JSON.stringify(tokens);

//         localStorage.setItem(apiName, json);
//         authActions.setTokens(tokens);
//     }
// }


import React, { LegacyRef, useEffect, useRef, useState } from "react";
import { Box, Button, Input, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

import { importCSV } from "../setupActions";
import UploadIcon from "../../svg/upload.svg";
import Icon from "../../shared/components/Icon";

type ImportCSVProps = {
  importCSV: typeof importCSV, 
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  btn: {
    display: "block",
  },
  upload: {
    textAlign: "center",
    fontSize: "8pt",
  },
}));

const ImportCSV = (props: ImportCSVProps) => {
    const classes = useStyles();

    const [dragging, setDragging] = useState(false);
    let dragCounter = 0;
    
    const dropRef = useRef<HTMLDivElement>(null);

    function handleDrag(e: any) {
        e.preventDefault()
        e.stopPropagation()
      };
    
    function handleDragIn(e: any) {
        e.preventDefault()
        e.stopPropagation()
        dragCounter++;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
          setDragging(true);
        }
    };
    
    function handleDragOut(e: any) {
        e.preventDefault()
        e.stopPropagation()
        dragCounter--;
        if (dragCounter === 0) {
          setDragging(false);
        }
    };

    function handleDragStart(e: any) {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.clearData();
    }

    function handleDrop(e: any) {
        e.preventDefault()
        e.stopPropagation()
        setDragging(false);

        if (e.dataTransfer.files.length) {
        //   props.handleDrop(e.dataTransfer.files);
          props.importCSV(e.dataTransfer.files[0]);
        //   e.dataTransfer.clearData();
          dragCounter = 0;    
        }
    }
    
    useEffect(() => {
        let div = dropRef.current;
        div!.addEventListener('dragenter', handleDragIn);
        div!.addEventListener('dragleave', handleDragOut);
        div!.addEventListener('dragover', handleDrag);
        div!.addEventListener('dragstart', handleDragStart);
        div!.addEventListener('drop', handleDrop);

        return () => {
            let div = dropRef.current;
            if(div) {
              div!.removeEventListener('dragenter', handleDragIn);
              div!.removeEventListener('dragleave', handleDragOut);
              div!.removeEventListener('dragover', handleDrag);
              div!.removeEventListener('dragstart', handleDragStart);
              div!.removeEventListener('drop', handleDrop);
            }
        };
    },[])

    // function chooseFileHandler(e: React.ChangeEvent<{ files?: string; }>) {
    function chooseFileHandler(e: React.ChangeEvent<{ files?: any; }>) {

      props.importCSV(e.target.files[0]);
    }
    
    return (
        <Box className="csv-container">
          <div
            className="dnd-container"
            ref={dropRef}
          >
            {dragging &&
              <div
                className="dnd"
              >
                {/* <div 
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: 0,
                    left: 0,
                    textAlign: 'center',
                    color: 'grey',
                    fontSize: 36
                  }}
                >
                  <div>drop here :)</div>
                </div> */}
              </div>
            }
            <Box>
              <Icon
                src={UploadIcon}
                className="margin-right"
              />
              <Box className={classes.upload}>
                <span>Upload your CSV File
                  <br/>
                  Drag & Drop
                </span>
                <input
                  accept=".csv"
                  className={classes.input}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={e => chooseFileHandler(e)}
                />
                <label className={classes.btn} htmlFor="contained-button-file">
                  <Button
                    variant="outlined"
                    color="secondary" 
                    component="span"
                    size="small"
                  >
                    Choose File
                  </Button>
                </label>
              </Box>
            </Box>
            <Typography>CSV structure: "FullName, Email"</Typography>
          </div>
        </Box>
    )
}

export default ImportCSV;
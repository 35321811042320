import { Snackbar } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";

type SnackbarComponentProps = {
    isOpen: boolean,
    msg: string,
    severity: Color,
    handleClose: () => void;
};

export const SnackbarComponent:React.FC<SnackbarComponentProps> = (props) => {
    const { isOpen, msg, severity } = props;

    return (
        <Snackbar open={isOpen} onClose={props.handleClose} autoHideDuration={1000} >
            <Alert variant="filled" severity={severity || "success"}>
                {msg}
            </Alert>
        </Snackbar>
    )
}

export default SnackbarComponent; 


import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  reviewsSvg: {
    fillRule: "evenodd",
    clipRule: "evenodd",
    strokeLinecap: "round",
    strokeLinejoin: "round",
  },
  reviewsSvgPath: {
    strokeWidth: "12.5px",
    strokeLinejoin: "miter",
  },
}));

const ReviewsIcon = (params) => {
  const classes = useStyles();
  return (
    <svg
      className={classes.reviewsSvg}
      width="35"
      height="35"
      viewBox="0 0 209 209"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
    >
      <g>
        <path
          className={classes.reviewsSvgPath}
          stroke={params.isSelected ? "#3b76ef" : "#c8dafe"}
          fill={params.isSelected ? "#f1f1f1" : "#3b76ef"}
          d="M104.167,24.943l29.368,47.168c0.095,0.153 0.22,0.286 0.368,0.391c0.147,0.105 0.315,0.18 0.493,0.221l54.943,12.741l-36.793,41.893c-0.119,0.136 -0.209,0.293 -0.266,0.463c-0.056,0.17 -0.077,0.349 -0.063,0.528l4.589,55.042l-52.107,-21.277c-0.169,-0.069 -0.35,-0.104 -0.532,-0.104c-0.183,-0 -0.364,0.035 -0.533,0.104l-52.106,21.277l4.588,-55.042c0.015,-0.179 -0.007,-0.358 -0.063,-0.528c-0.057,-0.17 -0.147,-0.327 -0.266,-0.463l-36.792,-41.893l54.942,-12.741c0.178,-0.041 0.346,-0.116 0.494,-0.221c0.148,-0.105 0.273,-0.238 0.368,-0.391l29.368,-47.168Z"
        />
      </g>
    </svg>
  );
};
export default ReviewsIcon;

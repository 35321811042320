import {appSettings} from '../config/appSettings';
import {Credentials} from '../EntryPages/SignInPage/signInData';
import {fetchBase, get, post} from '../auth/authAPI';

//need to separate into package
import {
    ConfirmEmailVerification,
    ConfirmVerificationResponse,
    ReenterSignupEmail,
    SignupInvitedUser,
    UserSignUpData
} from '../EntryPages/SignUpPage/signUpData';
import {GetOAuthUrlOptions, VerifyOrgRequestModel} from '../shared/SharedModels';
import {ForgotPassword, ResetPassword} from '../EntryPages/ResetPasswordPage/ResetPasswordData';
// declare const appSettings: any;

const rmsApiName = appSettings.apisMetadata.find(x => x.id === 'rms')!.name;
const authUrlRoot = appSettings.authorizationServer.baseAddress;
const authEndPoint = appSettings.authorizationServer.authorizationEndpoint;

export const initialAuthToken = async (url: string) => {
    if (url === 'feedback/token' || url === 'auth/verify-recaptcha') {
        let token;
        await fetch(`${authUrlRoot}/${rmsApiName}/${url}`)
            .then(response => response.json())
            .then(data => {
                token = data;
            });
        return token;
    } else {
        return get(rmsApiName, `${authUrlRoot}/${rmsApiName}${authEndPoint}/${url}`);
    }
}


export const verify = (model: Credentials, authToken: string) => {

    const url = authUrlRoot + `/${rmsApiName}` + authEndPoint + '/token';

    const user = {
        email: model.email,
        password: model.password,
        primaryOrganization: '1',
    };

    const userData = new FormData();
    for (const i in user) {
        userData.append(i, user[i as keyof typeof user])
    }

    const options = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + authToken
        },
        body: userData
    }

    return fetch(url, options);
};

export const switchAgentToken = (agentOrganizationId: number) => {
    const url = authUrlRoot + `/${rmsApiName}${authEndPoint}/switch-agent-token/${agentOrganizationId}`;

    const tokenObj = JSON.parse(localStorage.getItem("api")!);
    const token = tokenObj["access_token"];

    const options = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token
        },
    }

    return fetch(url, options);
};

export const signup = (model: UserSignUpData, authToken: string) => {
    const url = authUrlRoot + `/${rmsApiName}` + authEndPoint + '/signup';

    const signUpUser: { [key: string]: any } = {
        fullName: model.fullName,
        organizationName: model.organizationName,
        email: model.email,
        password: model.password,
        passwordConfirmation: model.passwordConfirmation,
        isTermsApproved: model.isTermsApproved,
        couponCode: model.couponCode,
        businessType: model.businessType
    }

    const signUpUserData = new FormData();
    for (const i in signUpUser) {
        signUpUserData.append(i, signUpUser[i as keyof typeof signUpUser]);
    }

    const options = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + authToken
        },
        body: signUpUserData,
    }

    return fetch(url, options);
};

export const signupInvitedUser = (model: SignupInvitedUser, authToken: string) => {
    const url = authUrlRoot + `/${rmsApiName}` + authEndPoint + '/signup/administrator';

    const invitedUser: { [key: string]: any } = {
        userId: model.userId,
        fullName: model.fullName,
        password: model.password,
        passwordConfirmation: model.passwordConfirmation,
        isTermsApproved: model.isTermsApproved,
    }

    const invitedUserData = new FormData();
    for (const i in invitedUser) {
        invitedUserData.append(i, invitedUser[i])
    }

    const options = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + authToken
        },
        body: invitedUserData,
    }

    return fetch(url, options);
}

export const getAuthUrl = (options: GetOAuthUrlOptions) => {
    return get(rmsApiName, authUrlRoot + `/${rmsApiName}` + `/auth/auth-url/${options.provider}?${options.qParams}`);
};

export const getInvitedUserProfile = (id: number) => {
    return fetch(authUrlRoot + `/${rmsApiName}` + authEndPoint + `/profile/${id}`);
}

export const verifyOrganization = (data: VerifyOrgRequestModel) => {
    const url = authUrlRoot + `/${rmsApiName}` + authEndPoint + '/verifyorganization';

    const organization: { [key: string]: any } = {
        organizationId: data.organizationId,
    }

    const organizationData = new FormData();
    for (const i in organization) {
        organizationData.append(i, organization[i]);
    }

    const options = {
        method: 'POST',
        body: organizationData,
    }

    return fetch(url, options);
};

export const forgotPassword = (data: ForgotPassword, authToken: string) => {
    const url = authUrlRoot + `/${rmsApiName}` + authEndPoint + '/forgot-password';

    const userEmail = {
        email: data.email,
    }

    const userEmailData = new FormData();
    for (const i in userEmail) {
        userEmailData.append(i, userEmail[i as keyof typeof userEmail]);
    }

    const options = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + authToken
        },
        body: userEmailData,
    }

    return fetch(url, options);
}

export const resetPassword = (data: ResetPassword, authToken: string) => {
    const url = authUrlRoot + `/${rmsApiName}` + authEndPoint + '/reset-password';

    const passwordReset = {
        password: data.password,
        confirmPassword: data.confirmPassword,
        email: data.email,
        token: data.token,
    }

    const passwordResetData = new FormData();
    for (const i in passwordReset) {
        passwordResetData.append(i, passwordReset[i as keyof typeof passwordReset]);
    }

    const options = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + authToken
        },
        body: passwordResetData,
    }

    return fetch(url, options);
}

export const confirmEmailVerification = (data: ConfirmEmailVerification) => {
    const url = authUrlRoot + `/${rmsApiName}` + authEndPoint + '/confirm-email-verification';

    const verificationCode: {[key:string]: any} = {
        verificationCode: data.verificationCode
    }

    const verificationData = new FormData();

    for (const i in verificationCode) {
        verificationData.append(i, verificationCode[i as keyof typeof verificationCode])
    }

    return post(rmsApiName, url, verificationData);
}

export const resendEmailVerificationCode = () => {
    const url = authUrlRoot + `/${rmsApiName}` + authEndPoint + '/resend-email-verification-code';

    return get(rmsApiName, url);
};

export const reenterSignupEmail = (data: ReenterSignupEmail) => {
    const url = authUrlRoot + `/${rmsApiName}` + authEndPoint + '/reenter-signup-email';

    const signupEmail: {[key:string]: any} = {
        email: data.email
    }

    const signupEmailData = new FormData();

    for (const i in signupEmail) {
        signupEmailData.append(i, signupEmail[i as keyof typeof signupEmail])
    }

    return post(rmsApiName, url, signupEmailData);
};


import { deprecated } from 'typesafe-actions';
import { AlertModel, CustomError, ErrorType, ValidationError } from './ErrorModels';
const { createStandardAction } = deprecated;

const addError = createStandardAction('errors/ADD_CUSTOM_ERROR')<CustomError>();
const removeError = createStandardAction('errors/REMOVE_CUSTOM_ERROR')<number>();
const setErrors = createStandardAction('errors/SET_CUSTOM_ERRORS')<CustomError[]>();
const clearErrors = createStandardAction('errors/CLEAR')();

const addServerError = createStandardAction('errors/ADD_SERVER_ERROR')<ErrorType>();
const removeServerError = createStandardAction('errors/REMOVE_SERVER_ERROR')();

const setValidationErrors = createStandardAction('errors/SET_VALIDATION_ERRORS')<ValidationError[]>();
const clearValidationErrors = createStandardAction('validationerrors/CLEAR')();

const addAlert = createStandardAction('errors/ADD_ALERT')<AlertModel>();

export { addError, removeError, addServerError, removeServerError, setErrors, setValidationErrors, clearValidationErrors, clearErrors, addAlert };
import React, {useCallback, useEffect} from 'react';
import {Box, Typography} from "@material-ui/core";
import {useLocation} from "react-router";
import {bindActionCreators, Dispatch} from "redux";
import {emailUnsubscribe} from "./emailUnsubscribeActions";
import {connect} from 'react-redux';
import {unsubscribeLoadingSelector, unsubscribeResultSelector} from "./emailUnsubscribeSelectors";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import * as sharedActions from "../../shared/sharedActions";
import {ReCaptchaModel} from "../../shared/SharedModels";
import {ApplicationState} from "../../ApplicationState";
import Loading from "../../shared/components/Loading";

interface UnsubscribeEmailProps {
    emailUnsubscribe: typeof emailUnsubscribe;
    unsubscribeResult: string;
    verifyReCaptcha: typeof sharedActions.verifyReCaptcha;
    recaptchaResponse?: ReCaptchaModel;
    isLoading: boolean;
}

function UnsubscribePage(props: UnsubscribeEmailProps) {
    const {executeRecaptcha} = useGoogleReCaptcha();
    const location = useLocation()

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha('unsubscribe');

        if (token) {
            props.verifyReCaptcha(token);
        }

    }, [executeRecaptcha, props.verifyReCaptcha]);

    useEffect(() => {
        const runReCaptchaVerification = async () => {
            await handleReCaptchaVerify();
        };

        if (executeRecaptcha) {
            runReCaptchaVerification();
        }
    }, [executeRecaptcha, handleReCaptchaVerify]);

    useEffect(() => {
        if (props.recaptchaResponse) {
            if (!props.recaptchaResponse.success) {
                return;
            }
            if (props.recaptchaResponse.action !== 'unsubscribe') {
                return;
            }

            const token = new URLSearchParams(location.search).get('t');

            if (token) {
                props.emailUnsubscribe(token);
            }
        }
    }, [props.recaptchaResponse]);

    return (
        <Box className='login-container-wrapper'>
            <Box className='login-container'>
                <Box className='login-box' justifyContent='space-around'>
                    <Box className='login-box-item'>
                        <Box className='sign-box'>
                            <Typography className="title" variant='h6'><strong>Unsubscribe</strong></Typography>
                            {
                                props.isLoading ?
                                    <Loading message={" "}/> :
                                    <Typography style={{marginBottom: "10px", fontSize: '1.2rem'}}>
                                        {props.unsubscribeResult === "success" ? 'You have successfully unsubscribed!' : props.unsubscribeResult === "failure" ? 'An error occurred trying to unsubscribe' : ""}
                                    </Typography>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        unsubscribeResult: unsubscribeResultSelector(state),
        recaptchaResponse: state.globals.reCaptchaResponse,
        isLoading: unsubscribeLoadingSelector(state),
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        emailUnsubscribe,
        verifyReCaptcha: sharedActions.verifyReCaptcha
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UnsubscribePage);

import { getType } from "typesafe-actions";
import { CSATScale, SettingsModel } from "./SettingsModels";
import * as actions from "./settingsActions";
import {toggleAnonymousSettingCompleted} from "./settingsActions";

const initialSettingsState = {
    language: "English",
    csat: [],
    logo: "",
    surveySettings: {
        id: 0,
        organizationId: 0,
        anonymousActive: 0,
    },
};

const settingsReducer = (state: SettingsModel = initialSettingsState, action: any) => {
    switch(action.type){
        case getType(actions.getNotificationSettingsCompleted):
            return {
                ...state,
                csat: [...action.payload],
            }
        case getType(actions.editNotificationSettingsCompleted):
            return {
                ...state,
                csat: state.csat.map((scale: CSATScale) =>
                    scale.id === action.payload.id ? { ...scale, ...action.payload } : scale),
            }
        case getType(actions.getSurveySettingsCompleted):
        case getType(toggleAnonymousSettingCompleted):
            return {
                ...state,
                surveySettings: action.payload,
            }
        default:
            return {
                ...state,
            };
    }
}

export default settingsReducer;
import { VerifyingModel } from "../../shared/SharedModels"
import { ConfirmEmailVerification, ConfirmVerificationResponse, ReenterSignupEmail } from "./signUpData"
import { ConfirmEmailVerificationModel, ReenterSignupEmailModel } from "./signUpModels"

export const confirmEmailVerificationFromModel = (model: ConfirmEmailVerificationModel): ConfirmEmailVerification => {
    return {
        verificationCode: model.verificationCode,
        //userId: model.userId,
    }
}

export const confirmVerificationResponseToModel = (data: ConfirmVerificationResponse): VerifyingModel => {
    return {
        isVerified: data.isVerified,
        isExpired: data.isExpired,
    }
};

export const reenterSignupEmailFromModel = (model: ReenterSignupEmailModel): ReenterSignupEmail => {
    return {
        email: model.email,
    } 
};
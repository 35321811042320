enum AuthTypes {
    SET_TOKENS = "auth/SET_TOKENS",
    SET_PRIMARY_TOKEN = "auth/SET_PRIMARY_TOKEN",
    REMOVE_AGENT_TOKEN = "auth/REMOVE_AGENT_TOKEN",
    SET_AGENT_TOKEN = "auth/SET_AGENT_TOKEN",
    REMOVE_TOKENS = "auth/REMOVE_TOKENS",
    REFRESH_TOKENS = "auth/REFRESH_TOKENS",
    ACQUIRE_TOKENS = "auth/ACQUIRE_TOKENS",
    LOGIN = "auth/LOGIN",
}

export default AuthTypes;
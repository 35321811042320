import {fromUrl, parseDomain, ParseResultType} from "parse-domain";

function numberWithDivider(x: number) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

function getCurrentDomainName() {
    let domain = "";
    try {
        const parseResult = parseDomain(
            fromUrl(window.location.href)
        );
        if (parseResult.type === ParseResultType.Listed) {
            domain = parseResult.domain + "." + parseResult.topLevelDomains.join(".");
        }
    } catch (e) {
        console.error(e);
    }
    return domain;
}

export { numberWithDivider, getCurrentDomainName };

import { ApplicationState } from "../ApplicationState";
import { InvitedUserProfileModel, SubscriptionPlanModel, VerifyingModel } from "./SharedModels";

export const loadingSelector = (state: ApplicationState) => {
    return state.loading;
}

export const whiteLabelSelector = (state: ApplicationState) => {
    return state.globals.whiteLabel;
}

export const whiteLabelCheckCompletedSelector = (state: ApplicationState) => {
    return state.globals.whiteLabelCheckCompleted;
}

export const navTabSelector = (state: ApplicationState) => {
    return state.globals.navTabIsSelected;
}

export const profileSelector = (state: ApplicationState) => {
    return state.globals.profile;
}

export const orgPlanSelector = (state: ApplicationState): SubscriptionPlanModel | undefined => {
    return state.globals.orgPlan;
}

export const organizationSelector = (state: ApplicationState) => {
    return state.globals.profile?.organization;
}

export const isSearchingSelector = (state: ApplicationState) => {
    return state.globals.isSearching;
}

export const isDisabledServiceSelector = (state: ApplicationState) => {
    return state.globals.isDisabledService;
};

export const invitedUserSelector = (state: ApplicationState): InvitedUserProfileModel | undefined => {
    return state.globals.invitedUserProfile;
};

export const verifyingFlagSelector = (state: ApplicationState): VerifyingModel => {
    return state.globals.verifying;
};


import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {AppBar, Box, Tab, Tabs} from '@material-ui/core';
import {withRouter, RouteComponentProps, Redirect} from "react-router-dom";
import EnvelopIcon from "../../svg/envelop";
import ImportCsvIcon from "../../svg/import-csv";
import {getCurrentDomainName} from "../../shared/helpers";
import {Route} from "react-router";
import ConnectEmailClient from "./ConnectEmailClient";

type ImportTabsProps = {
    orgId: number,
}

// define link tab props
type LinkTabProps = {
    index: number,
    label: React.ReactNode,
    onClick: () => void,
};

function a11yProps(index: any) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
        index,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        color: '#707070',
        border: '1px solid white',
        borderRadius: '5px 5px 0px 0px',
        backgroundColor: "#F1F1F1",
        zIndex: 0,
    },
    tab: {
        borderRight: "1px solid #fff",
    },
    selectedTab: {
        backgroundColor: '#fff',
        color: '#3B76EF',
    },
}));

function ImportTabs(props: RouteComponentProps & ImportTabsProps) {
  const classes = useStyles();
  const {location, orgId} = props;
  const [selectedTab, setSelectedTab] = useState(0);
  const [redirect, setRedirect] = useState('');

  useEffect(() => {
      if (redirect) {
          setRedirect('');
      }
  }, [redirect]);

  useEffect(() => {
    if(location.pathname) {
      const tabIndex = getTabIndex(location.pathname);
      setSelectedTab(tabIndex);
    }
  }, []);

  const handleChange = (event: any, tabIndex: any) => {
    setSelectedTab(tabIndex);
  };

  const getTabIndex = (url: string) => {
    if(/ConnectEmailClient/.test(url)) return 0;
    if(/csv/.test(url)) return 1;

    return 0;
  }

    const LinkTab = (props: LinkTabProps) => {
        return (
            <Tab
                component={Box}
                className={`${classes.tab} ${props.index === selectedTab ? classes.selectedTab : ''}`}
                {...props}
                onClick={props.onClick}
            />
        );
    }
    const domain = getCurrentDomainName();
    let connectEmailClientTab = null;
    // if it contains "ratemyservice" then show the connect email client component
    if (!domain || domain.match('ratemyservice')) {
        connectEmailClientTab = (
            <LinkTab
                label={<><EnvelopIcon color={selectedTab === 0 ? "#3b76ef" : "#4B5A7A"}/>Import Staff Members from Email
                    Client</>} onClick={() => setRedirect(`/org${orgId}/setup/AgentImport/ConnectEmailClient`)} {...a11yProps(0)} />
        );
    }
    if (redirect) {
        return <Redirect to={redirect} />;
    }
    return (
        <AppBar position="static" className={classes.root}>
            <Tabs
                value={selectedTab}
                onChange={handleChange}

                variant="fullWidth"
                aria-label="nav tabs example"
            >
                {connectEmailClientTab}
                <LinkTab label={<><ImportCsvIcon color={selectedTab === 1 ? "#3b76ef" : "#4B5A7A"}/>Import from CSV</>}
                         onClick={() => setRedirect(`/org${orgId}/setup/AgentImport/csv`)} {...a11yProps(1)} />
            </Tabs>
        </AppBar>
    );
}

export default withRouter(ImportTabs);

const StepIcon = (params) => (

    <svg focusable="false" height="50" width="50" viewBox="0 0 50 50"
        fill={params.active ? "#c8dafe" : "transparent"} x="586" y="120"
        stroke={params.active ? "#3B76EF" : "rgba(0, 0, 0, 0.4)"}
        aria-hidden="true"
    >
        <circle cx="25" cy="25" r="24"></circle>
        <text x="25" y="30" textAnchor="middle">{params.number}</text>
    </svg>
)

export default StepIcon;
import { Typography, Box, Grid, Button, Snackbar, Dialog, DialogContent, makeStyles } from "@material-ui/core";
import * as clipboard from "clipboard-polyfill";
import SectionComponent from "../../shared/components/SectionComponent";
import { AgentWithSnippetModel, TemplateModel } from "../SetupModels";

import * as lookupSelectors from "../../lookups/lookupSelectors";
import * as selectors from "../setupSelectors";
import * as sharedSelectors from "../../shared/sharedSelectors";
import React, { useEffect, useState } from "react";
import { loadTemplateActions, loadFontSizes, loadFontFamilies, loadColorPalette } from "../../lookups/lookupActions";
import { getAgentsWithSnippetByOrg, getTemplate, changeCopySnippetFlag, emailSurveyToAgent, getAgentSnippet, setAlertNotification } from '../setupActions';
import { setServiceIsDisabledFlag, clearUserProfile } from '../../shared/sharedActions';
import {KeyValueModel, OrganizationModel, WhiteLabelProfile} from "../../shared/SharedModels";
import { ApplicationState } from "../../ApplicationState";
import { connect } from "react-redux";
import { Alert } from "@material-ui/lab";
import SubmitFeedbackPage from "../../Feedback/components/SubmitFeedbackPage";

import '../../Feedback/components/FeedbackPage.scss';
import { RouteComponentProps } from "react-router-dom";
import MenuBar from "../../shared/components/MenuBar";
import NavTabs from "../../shared/components/NavTabs";
import { SnackbarComponent } from "../../shared/components/Snackbar";

type SignatureAgentViewProps = {
    whiteLabel?: WhiteLabelProfile,
    template?: TemplateModel,
    agents: AgentWithSnippetModel[],
    organization?: OrganizationModel,

    actions: KeyValueModel[],
    fontSizes: KeyValueModel[],
    fontFamilies: KeyValueModel[],
    colorPalette: KeyValueModel[],
    iconSets: KeyValueModel[],
    isCopied: boolean,
    isEmailedToAgent: boolean,

    loadTemplateActions: typeof loadTemplateActions,
    loadFontSizes: typeof loadFontSizes,
    loadFontFamilies: typeof loadFontFamilies,
    loadColorPalette: typeof loadColorPalette,
    getAgentsWithSnippetByOrg: typeof getAgentsWithSnippetByOrg,
    getTemplate: typeof getTemplate,
    changeCopySnippetFlag: typeof changeCopySnippetFlag,
    emailSurveyToAgent: typeof emailSurveyToAgent,
    getAgentSnippet: typeof getAgentSnippet,
    setServiceIsDisabledFlag: typeof setServiceIsDisabledFlag,
    clearUserProfile: typeof clearUserProfile,
    setAlertNotification: typeof setAlertNotification
}

type SubmitFeedbackPopupState = {
    open: boolean,
    surveyAnswerId: string,
    type: string,
    organizationId: number,
    agentId: number | null,
};

type SubmitFeedbackPopupProps = {
    state: SubmitFeedbackPopupState,

    handleClose: () => void,
};

const useStyles = makeStyles((theme) => ({
    submitFeedbackPopup: {
      width: "635px",
    },
}));

const SubmitFeedbackPopup: React.FC<SubmitFeedbackPopupProps> = (props) => {
    const { state } = props;
    const classes = useStyles();
    const organizationId = Number(state.organizationId).toString();
    const agentId = Number(state.agentId).toString();
    return (
        <Dialog
            open={state.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            // className={classes.submitFeedbackPopup}
            // fullWidth={true}
            // maxWidth='sm'
        >
            <DialogContent>
                <Box className="submit-feedback-popup container">
                    <SubmitFeedbackPage
                        organizationId={organizationId}
                        agentId={agentId}
                        surveyAnswerId={state.surveyAnswerId}
                        submitRate={state.type}
                    />
                </Box>
            </DialogContent>
        </Dialog>
    )
}

const SignatureAgentView = (props: SignatureAgentViewProps & RouteComponentProps<{ agentid: string }>) => {
    const { agents, actions, fontSizes, fontFamilies, colorPalette, isCopied, isEmailedToAgent, organization } = props;
    const { agentid } = props.match.params;
    const agentId = agentid.replace("agent", "");

    useEffect(() => {
        if(!actions.length) props.loadTemplateActions();
        if(!fontSizes.length) props.loadFontSizes();
        if(!fontFamilies.length) props.loadFontFamilies();
        if(!colorPalette.length) props.loadColorPalette();

        props.clearUserProfile();
        props.getAgentSnippet(agentId);
        props.setServiceIsDisabledFlag(true);

        // for what reason ??
        getTemplate();
    }, []);

    const copyToClipboard = async (rawHtml: string) => {
        const item = new clipboard.ClipboardItem({
            "text/html": new Blob(
              [rawHtml],
              { type: "text/html" }
            )});

        await clipboard.write([item]);

        props.changeCopySnippetFlag(true);
    };

    const copyHTMLToClipboard = async (rawHtml: string) => {
        await clipboard.writeText(rawHtml);
        props.changeCopySnippetFlag(true);
    };

    const handleCopiedAlertClose = () => {
        props.changeCopySnippetFlag(false);
    }

    return (
        <>
            <Box
                className="content-container"
            >
                <MenuBar />
                <Box className='App-content'>
                <NavTabs />
                <Box id='setup-container' >
                    <SectionComponent>Signature</SectionComponent>
                    <Box id="signature-container">
                        {agents && agents.map((agent: AgentWithSnippetModel) => {
                            return (
                                <Box className='signature'>
                                    <Grid className='agent-container' container xs={4}>
                                        <Grid item xs={4} style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)", paddingLeft: "10px" }} >
                                            <strong>Full Name</strong>
                                        </Grid>
                                        <Grid item xs={8} style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}>
                                            {agent.fullName}
                                        </Grid>
                                        <Grid item xs={4} style={{ paddingLeft: "10px" }} >
                                            <strong>Email Address</strong>
                                        </Grid>
                                        <Grid className="highlighted-text" item xs={8}>
                                            <Typography color="primary">{agent.defaultEmail}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Button>
                                        <Box
                                            className="snippet"
                                            dangerouslySetInnerHTML={{ __html: agent.snippet }}
                                        />
                                    </Button>
                                    <Box className="btn-container" display="flex" flexDirection="column"
                                         justifyContent="center">
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => copyToClipboard(agent.snippet)}
                                        >
                                            Copy Survey to Clipboard
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => copyHTMLToClipboard(agent.snippet)}
                                        >
                                            Copy HTML to Clipboard
                                        </Button>
                                    </Box>
                                </Box>
                                )
                        })}
                    </Box>
                </Box>
                <SnackbarComponent
                    isOpen={isCopied}
                    msg="COPIED"
                    severity="success"
                    handleClose={handleCopiedAlertClose}
                />
                </Box>
            </Box>
        </>
    )
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        actions: lookupSelectors.actionTypesSelector(state),
        fontSizes: lookupSelectors.fontSizeTypesSelector(state),
        fontFamilies: lookupSelectors.fontFamilyTypesSelector(state),
        colorPalette: lookupSelectors.colorPaletteSelector(state),
        iconSets: lookupSelectors.iconSetsSelector(state),

        agents: selectors.agentWithSnippetSelector(state),
        template: selectors.templateSelector(state),
        isCopied: selectors.isSnippetCopiedSelector(state),

        organization: sharedSelectors.organizationSelector(state),
    }
}

const mapDispatchToProps = { loadTemplateActions, loadFontSizes, loadFontFamilies, loadColorPalette
    , getAgentsWithSnippetByOrg, getTemplate, changeCopySnippetFlag, emailSurveyToAgent, getAgentSnippet, setServiceIsDisabledFlag, clearUserProfile,
};

const SignatureAgentViewContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SignatureAgentView);

export default SignatureAgentViewContainer;

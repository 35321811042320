import {
    Box,
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
    IconButton,
    makeStyles,
    Table, TableBody,
    TableCell,
    TableHead,
    TableRow, TextField,
    Typography
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import SectionComponent from "../../shared/components/SectionComponent";
import { appSettings } from "../../config/appSettings";
import {uploadAgencyLogo, uploadFavicon, updateAgencySettings} from "../settingsActions";
import ImageUploadComponent from "./ImageUploadComponent";
import {AgencySettings as AgencySettingsModel} from "../SettingsModels";
import Icon from "../../shared/components/Icon";
import editBtnIcon from "../../svg/editbtn.svg";


const staticDataUrl = appSettings.app.staticDataUrl;

type AgencySettingsProps = {
    agencyLogo: string,
    favicon?: string,
    agencyOrganisationId: number,
    whiteLabelOrganisationId?: number,
    uploadAgency: typeof uploadAgencyLogo,
    uploadFavicon: typeof uploadFavicon,
    updateAgencySettings: typeof updateAgencySettings,
    agencySettings: AgencySettingsModel,
};

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
    btn: {
        display: "block",
    },
    upload: {
        textAlign: "center",
    },
}));



const AgencySettings = (props: AgencySettingsProps) => {
    const classes = useStyles();
    const [modalOpen, setModalOpen] = useState(false);
    const { agencyLogo, agencySettings, favicon } = props;
    const [ editSetting, setEditSetting ] = useState('');
    const [ inputValue, setInputValue ] = useState('');

    const uploadFileHandler = (file: File) => {
        if(file) props.uploadAgency({file: file, organisationId: props.whiteLabelOrganisationId});
        return true;
    };

    const uploadFaviconHandler = (file: File) => {
        if(file) props.uploadFavicon({file: file, organisationId: props.whiteLabelOrganisationId});
        return true;
    };

    const getDialogHeading = () => {
        if (editSetting === 'termsService') return 'Edit Terms and Conditions';
        if (editSetting === 'privacyPolicy') return 'Edit Privacy Policy';
        if (editSetting === 'cookiePolicy') return 'Edit Cookies Policy';
        return '';
    }

    const handleClose = () => {
        setModalOpen(false);
        setEditSetting('');
        setInputValue('');
    }

    const handleUpdate = () => {
        let settings = {...props.agencySettings};
        if (editSetting === 'termsService') settings.termsService = inputValue;
        if (editSetting === 'privacyPolicy') settings.privacyPolicy = inputValue;
        if (editSetting === 'cookiePolicy') settings.cookiePolicy = inputValue;
        props.updateAgencySettings(settings);
        handleClose();
    }

    return (
        <Box>
            <Dialog
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DialogTitle id="form-dialog-title">{getDialogHeading()}</DialogTitle>
                <DialogContent>
                    <TextField
                        name="settingInput"
                        value={inputValue}
                        id="settingInput"
                        variant="outlined"
                        placeholder={getDialogHeading()}
                        onChange={(e) => setInputValue(e.target.value)}
                        inputProps={{ autoComplete: "off" }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdate} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
            <SectionComponent marginTop="39px">AGENCY BRANDING</SectionComponent>
            <Box className="agencysettings-container content-block">
                <ImageUploadComponent currentImage={agencyLogo} uploadFileAction={uploadFileHandler} inputLabel={"Agency Logo"} />
                <ImageUploadComponent currentImage={favicon} uploadFileAction={uploadFaviconHandler} inputLabel={"Agency Favicon"} />

                <Typography style={{ marginTop: "1rem", marginBottom: "14px", fontWeight: 700 }} >Current Terms and Conditions</Typography>
                <Table style={{width: "70%"}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography style={{fontWeight: 700}}>Sections</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography style={{fontWeight: 700}}>Current Url</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography style={{fontWeight: 700}}>Action</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography>Terms and Conditions</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{agencySettings.termsService ?? ''}</Typography>
                            </TableCell>
                            <TableCell><Button
                                variant="outlined"
                                color="primary"
                                endIcon={<Icon src={editBtnIcon} />}
                                onClick={() => {
                                setModalOpen(true);
                                setEditSetting('termsService');
                                setInputValue(agencySettings.termsService ?? '');
                            }}>Edit</Button></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography>Privacy Policy</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{agencySettings.privacyPolicy ?? ''}</Typography>
                            </TableCell>
                            <TableCell><Button
                                variant="outlined"
                                color="primary"
                                endIcon={<Icon src={editBtnIcon} />}
                                onClick={() => {
                                setModalOpen(true);
                                setEditSetting('privacyPolicy');
                                setInputValue(agencySettings.privacyPolicy ?? '');
                            }}>Edit</Button></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography>Cookies Policy</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{agencySettings.cookiePolicy ?? ''}</Typography>
                            </TableCell>
                            <TableCell><Button
                                variant="outlined"
                                color="primary"
                                endIcon={<Icon src={editBtnIcon} />}
                                onClick={() => {
                                setModalOpen(true);
                                setEditSetting('cookiePolicy');
                                setInputValue(agencySettings.cookiePolicy ?? '');
                            }}>Edit</Button></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <Typography style={{ marginTop: "1rem", marginBottom: "14px", fontWeight: 700 }} >FROM and REPLY-TO Address for Transactional Emails</Typography>
                <Table style={{width: "70%"}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography style={{fontWeight: 700}}>Current Email</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography style={{fontWeight: 700}}>Action</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography>{agencySettings.fromReplyEmail ?? ''}@{agencySettings.domainName ?? 'domain.com'}</Typography>
                            </TableCell>
                            <TableCell>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    endIcon={<Icon src={editBtnIcon} />}
                                    disabled={true}>Edit</Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>
        </Box>

    )
}

export default AgencySettings;

import { BillingInfo, CreditCard } from '../AccountPage/AccountData';
import { appSettings } from '../config/appSettings';
import { TemplateColorTypes } from '../SetupPage/setupEnums';
import { AgentProfile, AddAgent, Template, ImportUser } from '../SetupPage/SetupPageData';
import { SubscriptionPlan, ReturnResultData } from '../shared/SharedData';
import { TemplateActionData, FontSizeData, FontFamilyData, IconSet, TemplateColor } from '../lookups/LookupsApiData';
import { UserSignUpData } from '../EntryPages/SignUpPage/signUpData';
import { IconSetType } from '../lookups/LookupEnum';
import { ImportStatusType } from '../shared/enums/ImportStatusType';
const delay = 50;

// declare const appSettings: any;

const apiName = appSettings.apisMetadata.find(x => x.id === 'rms')!.name;
const apiUrlRoot = appSettings.apisMetadata.find(x => x.id === 'rms')!.baseAddress;

export const verifyIdToken = (provider: string) => {
    fetch(`${apiUrlRoot}/api/auth-from-code/${provider}}`, );
};


function getReturnResult<TData>(thisData: TData): ReturnResultData<TData> {
    return {
      statusCode: 'OK',
      message: '',
      data: thisData,
      errors: [],
    };
}

const signup = (data: UserSignUpData) => {
  return new Promise(
    (resolve, reject) => {
      resolve({
        ...userProfile,
        ok: "true",
      });
    });
}

const userProfile = {
  id: 1,
  organization: {
      id: 300500,
      subscriptionPlan: {
        id: 1,
        agentsLimitMin: 1,
        agentsLimitMax: 10,
        costPerAgent: 2.99,
        type: "Standard",
        // isDefault: boolean,
        // isArchived: boolean,
      }
  }
}

const getUserProfile = () => {

  // return new Promise<ReturnResultData<UserProfile>>(
  //   (resolve, reject) => {
  //     resolve(getReturnResult<UserProfile>(userProfile));
  //   });
};

const billingInfo = {
  organizationId: 300500,
  organizationFullName: 'African Safari Group',
  user: 'Nicole',
  email: 'nicole@africansafarigroup.com',
}

const getBillingInfo = (orgId: number) => {

  return new Promise<ReturnResultData<BillingInfo>>(
    (resolve, reject) => {
      resolve(getReturnResult<BillingInfo>(billingInfo));
    });
};

const agents: AgentProfile[] = [{
  id: 1,
  fullName: 'David Miller',
  login: 'dominic@vivagyms.com',
  isActive: false,
}]

const getAgents = (orgId: number) => {

  return new Promise<ReturnResultData<AgentProfile[]>>(
    (resolve, reject) => {
      resolve(getReturnResult<AgentProfile[]>(agents));
    });
};

const editAgent = (data: AgentProfile) => {

  return new Promise<ReturnResultData<AgentProfile>>(
    (resolve, reject) => {
      const updatedAgent = { ...data };

      resolve(getReturnResult<AgentProfile>(updatedAgent));
    });
};

const plan: SubscriptionPlan = {
  planId: 2,
  title: "Test Plan",
  agentsLimitMin: 10,
  agentsLimitMax: 30,
  pricePerAgent: 1.99,
  typeName: "Standard",
  priceTotal: 30,
  discountPercent: 0,
};

const getOrganizationPlan = (id: number) => {

  return new Promise<ReturnResultData<SubscriptionPlan>>(
    (resolve, reject) => {
      resolve(getReturnResult<SubscriptionPlan>(plan));
    });
};

const addAgent = (agentData: AddAgent) => {
  const agent: AgentProfile = {
    id: 2,
    fullName: agentData.fullName,
    login: agentData.login,
    isActive: agentData.isActive,
  }

  return new Promise<ReturnResultData<AgentProfile>>(
    (resolve, reject) => {
      resolve(getReturnResult<AgentProfile>(agent));
    });
};

const template: Template = {
  id: 1,
  title: '',
  fontSize: '',
  fontFamily: '',
  fontColor: "",
  iconSetId: 1,
  isDefault: true,
  lang: "",
  actionCount: 3,
  feedbackOption: 'internal'
}

const getTemplate = () => {

  return new Promise<ReturnResultData<Template>>(
    (resolve, reject) => {
      resolve(getReturnResult<Template>(template));
    });
};

const updateTemplate = (template: Template) => {
  // set userId as parameter !!!
  const updatedTemplate: Template = {
    id: template.id,
    title: template.title,
    fontSize: template.fontSize,
    fontFamily: template.fontFamily,
    fontColor: template.fontColor,
    iconSetId: template.iconSetId,
    isDefault: template.isDefault,
    lang: template.lang,
    actionCount: template.actionCount,
    feedbackOption: template.feedbackOption,
  }

  return new Promise<ReturnResultData<Template>>(
    (resolve, reject) => {
      resolve(getReturnResult<Template>(updatedTemplate));
    });
};

const actions: TemplateActionData[] = [
  {
    id: 1,
    text: "How would you rate our customer service?",
    lang: "Eng"
  },
  {
    id: 2,
    text: "Are you satisfied with our customer service?",
    lang: "Eng"
  }
];

function getTemplateActionTypes() {
  return new Promise<TemplateActionData[]>(
    (resolve, reject) => {
      resolve(actions);
    });
}

const fontSizes: FontSizeData[] = [
  {
    id: 1,
    name: "14pt"
  },
  {
    id: 2,
    name: "16pt"
  }
];

function getFontSizeTypes() {
  return new Promise<FontSizeData[]>(
    (resolve, reject) => {
      resolve(fontSizes);
    });
}

const fontFamilies: FontFamilyData[] = [
  {
    id: 1,
    name: "Arial"
  },
  {
    id: 2,
    name: "Tahoma"
  }
];

function getFontFamilyTypes() {
  return new Promise<FontFamilyData[]>(
    (resolve, reject) => {
      resolve(fontFamilies);
    });
}

// const iconSets =
// [   {
//     id: 1,
//     type: IconSetType.Static,
//     goodIcon: ExceededExpectationIcon,
//     okIcon: SatisfiedIcon,
//     badIcon: DissatisfiedIcon,
//     },
//     {
//       id: 2,
//       type: IconSetType.Static,
//       goodIcon: ExceededExpectationIcon,
//       okIcon: SatisfiedIcon,
//       badIcon: DissatisfiedIcon,
//     }
// ];

// function getIconSets() {
//   return new Promise<IconSet[]>(
//     (resolve, reject) => {
//       const response = iconSets;
//       resolve(response);
//     });
// }

const colorPalette = [
  {
    id: 1,
    name: TemplateColorTypes.black,
  },
  {
    id: 2,
    name: TemplateColorTypes.yellow,
  },
  {
    id: 3,
    name: TemplateColorTypes.green,
  },
]

function getColorPalette() {
  return new Promise<TemplateColor[]>(
    (resolve, reject) => {
      const response = colorPalette;
      resolve(response);
    });
}

const subscriptionPlans = [
  {
    planId: 1,
    title: "Test 1",
    agentsLimitMin: 1,
    agentsLimitMax: 10,
    pricePerAgent: 2.99,
    typeName: "Standard",
    priceTotal: 30,
    discountPercent: 30,
  },
  {
    planId: 2,
    title: "Test 2",
    agentsLimitMin: 10,
    agentsLimitMax: 30,
    pricePerAgent: 1.99,
    typeName: "Standard",
    priceTotal: 30,
    discountPercent: 30,
  }
];

function getSubscriptionPlans() {
  return new Promise<SubscriptionPlan[]>(
    (resolve, reject) => {
      const response = subscriptionPlans;
      resolve(response);
    });
}

function addCreditCard(card: CreditCard) {
  return new Promise<CreditCard>(
    (resolve, reject) => {
      resolve(card);
    });
}

// const creditCards = [
//   {
//     id: 1,
//     cardNumber: 4111111111111111,
//     validDue: new Date("2022-01-01"),
//     cardholderName: "Akhmetyanova Elvira",
//     secretCode: 789,
//     isDefault: true,
//     organizationId: 300504,
//   },
//   {
//     id: 2,
//     cardNumber: 4211111111111111,
//     validDue: new Date("2023-01-01"),
//     cardholderName: "Akhmetyanova Elvira",
//     secretCode: 394,
//     isDefault: false,
//     organizationId: 300504,
//   }
// ]

// function getCreditCardInfo() {
//   return new Promise<CreditCard[]>(
//     (resolve, reject) => {
//       resolve(creditCards);
//     });
// }

const importedData: ImportUser[] =
[{
  user: {
    id: 0,
    login: "petrovp@gmail.com",
    fullName: "Petrov Petr",
    organizationId: 300500,
    agencyDetails: []
  },
  importStatus: ImportStatusType.Failed,
  errorMessage: "User already exist",
},
{
  user: {
    id: 1,
    login: "vasilievp@gmail.com",
    fullName: "Vasiliev Petr",
    organizationId: 300500,
    agencyDetails: []
  },
  importStatus: ImportStatusType.Succeed,
  errorMessage: "",
}];

function uploadCSV(csv: any) {
  return new Promise<ImportUser[]>(
    (resolve, reject) => {
      resolve(importedData);
    });
}

export { getUserProfile, getBillingInfo, getAgents, editAgent, addAgent, getOrganizationPlan
  , getTemplateActionTypes, getFontSizeTypes, getFontFamilyTypes, signup, getColorPalette, getSubscriptionPlans
  , addCreditCard, getTemplate, updateTemplate, uploadCSV
};

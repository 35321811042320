import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Box, Tab, Tabs } from '@material-ui/core';
import {Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import BillingInfoIcon from "../../svg/billinginfo";
import EnvelopeIcon from "../../svg/envelope";
import InvoiceIcon from "../../svg/invoice";
import AdministratorIcon from "../../svg/administrator";

type AccountTabsProps = {
  orgId: number,
  isDisabledService: boolean,

  redirect: (arg: string) => void;
}

function a11yProps(index: any) {
  return {
    id: `account-nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
    index,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#F1F1F1',
    border: '1px solid white',
    borderRadius: '5px 5px 0px 0px',
  },
  // can join with other tabs (account)
  tab: {
    borderRight: "1px solid #fff",
    backgroundColor: '#F1F1F1',
  },
  selectedTab: {
    backgroundColor: '#fff',
    color: '#3B76EF',
  },
  appBar: {
    zIndex: 0,
  },
}));

function AccountTabs(props: RouteComponentProps & AccountTabsProps) {
  const classes = useStyles();
  const { orgId, location, isDisabledService } = props;
  const [selectedTab, setSelectedTab] = useState(0);
  const [redirect, setRedirect] = useState('');

  useEffect(() => {
    // reset the redirect to avoid a loop
    if (redirect.length > 0) {
      setRedirect('');
    }
  }, [redirect]);

  useEffect(() => {
    if(location.pathname) {
      const tabIndex = getTabIndex(location.pathname);
      setSelectedTab(tabIndex);
    }
  }, []);

  const primaryToken = JSON.parse(localStorage.getItem('primaryToken')!);

  let viewSubNInvoices = !(primaryToken && primaryToken.grant_type === 'agency_account');

  const handleChange = (event: any, tabIndex: any) => {
    setSelectedTab(tabIndex);
  };

  const getTabIndex = (url: string) => {
    if(/billinginfo/.test(url)) return 0;
    if(/subscription/.test(url)) return 1;
    if(/invoices/.test(url)) return 2;
    if(/users/.test(url)) return 2;

    return 0;
  }

  const LinkTab = (props: any) => {
    return (
      <Tab
        component={Box}
        // need to switch first child
        className={ `${classes.tab} ${props.index === selectedTab ? classes.selectedTab : ''}` }
        onClick={(event: any) => {
          // event.preventDefault();
          setRedirect(props.redirectto);

        }}
        disabled={isDisabledService}
        {...props}
      />
    );
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className={classes.root}>
      <AppBar
        className={classes.appBar}
        color='inherit'
        position="static"
      >
        <Tabs
            value={selectedTab}
            onChange={handleChange}

            variant="fullWidth"
            aria-label="nav tabs example"
        >
            <LinkTab label={<><BillingInfoIcon fill={ selectedTab === 0 ? "#3b76ef" : "#4B5A7A" } />Billing Info</>} redirectto={`/org${orgId}/account/billinginfo`} {...a11yProps(0)} />
          {viewSubNInvoices &&
              <LinkTab label={<><EnvelopeIcon fill={ selectedTab === 1 ? "#3b76ef" : "#4B5A7A" } />Monthly Subscription</>} redirectto={`/org${orgId}/account/subscription`} {...a11yProps(1)} />
          }
          {viewSubNInvoices &&
              <LinkTab label={<><InvoiceIcon fill={ selectedTab === 2 ? "#3b76ef" : "#4B5A7A" } />View Invoices</>} redirectto={`/org${orgId}/account/invoices`} {...a11yProps(2)} />
          }
            <LinkTab label={<><AdministratorIcon fill={ selectedTab === 3 ? "#3b76ef" : "#4B5A7A" } />Administrators</>} redirectto={`/org${orgId}/account/users`} {...a11yProps(3)} />
        </Tabs>
      </AppBar>
    </div>
  );
}

export default withRouter(AccountTabs);

import React, {useEffect, useReducer, useState} from "react";
import {
    Box, Slider, TextField,
    Typography,
} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import '../setupPage.scss';
import {TemplateModel} from "../SetupModels";
import {connect} from 'react-redux';
import {ApplicationState} from "../../ApplicationState";
import {updateTemplate, updateTemplateIconSize, updateTemplateIconSpacing} from "../setupActions";
import {loadIconSizes, loadIconSpacings} from '../../lookups/lookupActions';
import * as lookupSelectors from '../../lookups/lookupSelectors';
import {KeyValueModel} from "../../shared/SharedModels";
import {SelectList} from "../../shared/SelectList";
import {IconSetType} from "../../lookups/LookupEnum";
import SliderInputBox from "../../shared/components/SliderInputBox";

type CustomizeIconsProps = {
    template: TemplateModel,
    iconSizes: KeyValueModel[],
    iconSpacings: KeyValueModel[],
    staticIconSets: KeyValueModel[],
    animatedIconSets: KeyValueModel[],
    updateTemplate: typeof updateTemplate,
    loadIconSizes: typeof loadIconSizes,
    loadIconSpacings: typeof loadIconSpacings,
    updateTemplateIconSize: typeof updateTemplateIconSize,
    updateTemplateIconSpacing: typeof updateTemplateIconSpacing,
}


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        borderRadius: "",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    emailClients: {
        display: "flex",
    },
    active: {
        border: "2px solid #4B5A7A",
        boxShadow: "0 0 10px rgba(0,0,0,0.2);",
    },
    selectWidth: {
        width: "200px",
    },
    language: {
        display: "flex",
        alignItems: "center",
    },
    editBtn: {
        margin: "8px 0 4px 0",
        marginLeft: '3px',
    },
    colorBtns: {
        display: "flex",
    },
    customIcons: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
}));

const CustomizeIcons = (props: CustomizeIconsProps) => {
    const classes = useStyles();
    const {
        template,
        updateTemplate,
        iconSizes,
        iconSpacings,
    } = props;

    const iconTypes: KeyValueModel[] = [
        {key: 1, value: 'Images'},
    ]
    const iconNumber: KeyValueModel[] = [
        {key: 1, value: 3},
        {key: 2, value: 2},
        {key: 3, value: 5},
    ]

    const [iconType, setIconType] = useState(iconTypes[0].value)

    const actionCount = template.actionCount

    useEffect(() => {
        if (!iconSizes.length) props.loadIconSizes();
        if (!iconSpacings.length) props.loadIconSpacings();
    }, []);

    const handleChange = (event: React.ChangeEvent<{ id?: string, name?: string; value: any }>) => {
        const value = event.target.value;
        const name = event.target.name;

        if (name === 'actionCount') {
            // filter the icon sets based on the type and value
            let iconSet = template.iconSet;
            if (value !== template.actionCount) {
                const currentSet = props.staticIconSets.find(set => set.key === template.iconSet);
                if (currentSet && currentSet.value.type === 1) {
                    switch (value) {
                        case 5:
                            iconSet = props.staticIconSets.filter((set: KeyValueModel) => set.value.satisfied && set.value.dissatisfied && set.value.exceededExpectation && set.value.great && set.value.verydissatisfied)[0].key;
                            break;
                        case 3:
                            iconSet = props.staticIconSets.filter((set: KeyValueModel) => set.value.satisfied && set.value.dissatisfied && set.value.exceededExpectation)[0].key;
                            break;
                        case 2:
                            iconSet = props.staticIconSets.filter((set: KeyValueModel) => set.value.verysatisfied || set.value.verydissatisfied)[0].key;
                            break;
                    }
                } else {
                    switch(value) {
                        case 5:
                            iconSet = props.animatedIconSets.filter((set: KeyValueModel) => set.value.satisfied && set.value.dissatisfied && set.value.exceededExpectation && set.value.great && set.value.verydissatisfied)[0].key;
                            break;
                        case 3:
                            iconSet = props.animatedIconSets.filter((set: KeyValueModel) => set.value.satisfied && set.value.dissatisfied && set.value.exceededExpectation)[0].key;
                            break;
                        case 2:
                            iconSet = props.animatedIconSets.filter((set: KeyValueModel) => set.value.verysatisfied || set.value.verydissatisfied)[0].key;
                            break;
                    }
                }
            }
            updateTemplate({
                ...template,
                iconSet,
                [name]: value,
            })
        } else {
            setIconType(value);
        }
        return
    }

    return (
        <>
            <Box style={{flex: "1", alignItems: "stretch"}} className={"setup-content-block-right"}>
                <Box style={{height: "100%"}} className='customize content-block'>
                    <Box className={classes.customIcons}>
                        <Box>
                            <Typography>Icon Type</Typography>
                            <SelectList
                                id="iconType"
                                name="iconType"
                                className={`${classes.selectWidth} select-holder`}
                                value={iconType}
                                onChange={handleChange}
                                items={iconTypes}
                            />
                        </Box>
                        <Box>
                            <Typography>Number of Icons</Typography>
                            <SelectList
                                id="actionCount"
                                name="actionCount"
                                className={`${classes.selectWidth} select-holder`}
                                value={actionCount}
                                onChange={handleChange}
                                items={iconNumber}
                            />
                        </Box>
                    </Box>
                    <Box className={classes.customIcons}>
                        <Box paddingLeft={"0.5rem"}>
                            <SliderInputBox
                                name={"iconSize"}
                                label={"Icon Size"}
                                step={null}
                                defaultValue={template.iconSize}
                                min={20}
                                max={80}
                                marks={iconSizes.map(size => ({value: size.value}))}
                                onChange={(value) => { props.updateTemplateIconSize(value)}}
                            />
                        </Box>
                        <Box paddingLeft={"0.5rem"}>
                            <SliderInputBox
                                name={"iconSpacing"}
                                label={"Icon Spacing"}
                                step={null}
                                defaultValue={template.iconSpacing}
                                min={20}
                                max={100}
                                marks={iconSpacings.map(spacing => ({value: spacing.value}))}
                                onChange={(value) => { props.updateTemplateIconSpacing(value)}}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        actions: lookupSelectors.actionTypesSelector(state),
        staticIconSets: lookupSelectors.iconSetsSelectorByType(state, IconSetType.Static),
        animatedIconSets: lookupSelectors.iconSetsSelectorByType(state, IconSetType.Animated),
        iconSizes: lookupSelectors.iconSizeTypesSelector(state),
        iconSpacings: lookupSelectors.iconSpacingTypesSelector(state),
    }
}

const mapDispatchToProps = {
    updateTemplate,
    loadIconSizes,
    loadIconSpacings,
    updateTemplateIconSize,
    updateTemplateIconSpacing,
};

const CustomizeIconsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomizeIcons);

export default CustomizeIconsContainer;

import { NotificationSettings } from "./SettingsApiData";
import { CSATScale } from "./SettingsModels";

export const notificationSettingsToModel = (settings: NotificationSettings): CSATScale => {
    return {
        id: settings.id,
        surveyId: settings.surveyId,
        rate: settings.rate,
        iconPath: settings.iconPath,
        description: settings.description,
        notificationEmail: settings.emailAddresses,
        enabled: settings.enabled,
        agentEnabled: settings.agentEnabled
    }
};
import { ActionType, getType } from 'typesafe-actions';

import * as loadingStateActions from './loadingStateActions';
import { LoadingStateModel, LoadingStateMap } from './LoadingStateModel';

export type LoadingStateAction = ActionType<typeof loadingStateActions>;
const defaultScope = 'default';
const initialState: LoadingStateMap = {

};

function loadingStateReducer(state: LoadingStateMap = initialState, action: LoadingStateAction)
    : LoadingStateMap {


    switch (action.type) {
        case getType(loadingStateActions.begin): {

            const newState = { ...state };
            const loaders = state[defaultScope] ? state[defaultScope].loaders : 0;
            newState[defaultScope] = {
                isLoading: loaders >= 0,
                loaders: loaders < 0 ? 1 : loaders + 1
            };
            return newState;
        }
        case getType(loadingStateActions.beginScope): {
            const newState = { ...state };
            const loaders = state[action.payload] ? state[action.payload].loaders : 0;
            newState[action.payload] = {
                isLoading: loaders >= 0,
                loaders: loaders < 0 ? 1 : loaders + 1
            };
            return newState;
        }
        case getType(loadingStateActions.setMessage): {
            const newState = { ...state };
            newState[defaultScope].message = action.payload;
            return newState;
        }
        case getType(loadingStateActions.complete): {
            const newState = { ...state };
            const loaders =  state[defaultScope] ? state[defaultScope].loaders : 0;

            newState[defaultScope] = {
                isLoading: false,
                loaders: 0
            };
            return newState;
        }
        case getType(loadingStateActions.completeScope): {
            const newState = { ...state };
            const loaders = state[action.payload] ? state[action.payload].loaders : 0;
            newState[action.payload] = {
                isLoading: loaders > 1,
                loaders: loaders > 0 ? loaders - 1 : 0,
            };
            return newState;
        }
        case getType(loadingStateActions.reset): {
            const newState = { ...state };
            newState[defaultScope] = {
                isLoading: false,
                loaders: 0
            };
            return newState;
        }
        case getType(loadingStateActions.resetScope): {
            const newState = { ...state };
            newState[action.payload] = {
                isLoading: false,
                loaders: 0
            };
            return newState;
        }
        default:
            return state;
    }
}

export default loadingStateReducer;

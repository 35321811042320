import { deprecated } from "typesafe-actions"
const { createStandardAction } = deprecated;

const begin = createStandardAction('loadingState/BEGIN')();
const beginScope = createStandardAction('loadingState/BEGIN_SCOPE')<string>();
const setMessage = createStandardAction('loadingState/SET_MESSAGE')<string>();
const complete = createStandardAction('loadingState/COMPLETE')();
const completeScope = createStandardAction('loadingState/COMPLETE_SCOPE')<string>();
const reset = createStandardAction('loadingState/RESET')();
const resetScope = createStandardAction('loadingState/RESET_SCOPE')<string>();


// const beginScope = createAction('loadingState/BEGIN_SCOPE', action => {
//     return (scope: string) => action(scope);
// })();

// const begin = createAction('loadingState/BEGIN', action => {
//     return () => action();
// })();

// const setMessage = createAction('loadingState/SET_MESSAGE', action => {
//     return (msg: string) => action(msg);
// })();

// const complete = createAction('loadingState/COMPLETE', action => {
//     return () => action();
// })();


// const completeScope = createAction('loadingState/COMPLETE_SCOPE', action => {
//     return (scope: string) => action(scope);
// })();


// const reset = createAction('loadingState/RESET', action => {
//     return () => action();
// })();

// const resetScope = createAction('loadingState/RESET_SCOPE', action => {
//     return (scope: string) => action(scope);
// })();


export { begin, beginScope, setMessage, complete, completeScope, reset, resetScope };
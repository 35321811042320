import React, {FC, useEffect, useState} from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { importFromEmailClient } from "../SetupPage/setupActions";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {ApplicationState} from "../ApplicationState";
import * as sharedSelectors from "../shared/sharedSelectors";
import {UserProfileModel} from "../shared/SharedModels";

type OAuthCallbackProps = {
  importFromEmailClient: typeof importFromEmailClient;
  profile: UserProfileModel | undefined;
};

const OAuthCallback: FC<RouteComponentProps & OAuthCallbackProps> = (props) => {
  const { location} = props;
  const [redirect, setRedirect] = useState('');

  useEffect(() => {
    // reset the redirect to avoid a loop
    if (redirect.length > 0) {
      setRedirect('');
    }
  }, [redirect]);

  useEffect(() => {
    const code = (location.search.match(/code=([^&]+)/) || [])[1];
    const state = (location.search.match(/state=([^&]+)/) || [])[1];

    props.importFromEmailClient({
      client: state,
      code,
    });
    if (props.profile) {
      setRedirect(`/org${props.profile.organizationId}/setup/AgentImport/ConnectEmailClient`)
    }
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <>
    </>
  );
};

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
  return {
    profile: sharedSelectors.profileSelector(state)
  };
};

const OAuthCallbackContainer = connect(mapStateToProps, { importFromEmailClient })(OAuthCallback);

export default withRouter(OAuthCallbackContainer);

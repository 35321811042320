import { connect } from "react-redux";
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { SetupIntentResult } from "@stripe/stripe-js";
import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel } from "@material-ui/core";

import { ApplicationState } from "../../ApplicationState";
import { useEffect, useState } from "react";
import { StripeSetupIntentModel, StripeSetupModel } from "../AccountModels";
import { OrganizationModel } from "../../shared/SharedModels";
import { addCreditCard, updateCreditCard, createSetupIntent, confirmStripePayment } from '../accountActions';
import { addError } from '../../shared/errors/errorActions';
import * as s from '../../shared/sharedSelectors';
import * as errorSelectors from "../../shared/errors/errorSelectors";
import * as lookupSelectors from "../../lookups/lookupSelectors";
import * as selectors from '../accountSelectors';
import { ValidationError } from "../../shared/errors/ErrorModels";

type StripePopupProps = {
    open: boolean,
    action: string,
    validationErrors: ValidationError[] | [],
    stripeSetup: StripeSetupModel,
    organization: OrganizationModel | undefined,

    handleClosePopup: () => void,
    addCreditCard: typeof addCreditCard,
    updateCreditCard: typeof updateCreditCard,
    createSetupIntent: typeof createSetupIntent,
    confirmStripePayment: typeof confirmStripePayment,
    addError: typeof addError,
};

const StripePopup: React.FC<StripePopupProps> = (props) => {
    const { open, action, stripeSetup, organization } = props;
    const isAdding = action === 'add';
    const elements = useElements();
    const stripe = useStripe();
    const [intent, setIntent] = useState<StripeSetupIntentModel | null>();
    const [isDefault, setIsDefault] = useState<boolean>(false);

    useEffect(() => {
        if (stripeSetup.stripeActionRequired) {
            stripe?.handleCardAction(stripeSetup.paymentIntentClientSecret)
                .then(result => {
                    if (result.error) {
                        props.addError({ id: Date.now(), message: result.error.message ? result.error.message : "An error occuired" });
                        return;
                    }
                    props.confirmStripePayment({ paymentIntentId: result.paymentIntent?.id, paymentMethodId: result.paymentIntent?.payment_method })
                });
        };
    }, [])

    useEffect(() => {
        props.createSetupIntent();
    }, [open]);

    useEffect(() => {
        setIntent(stripeSetup.setupIntent);
    }, [stripeSetup.setupIntent]);

    const handleSubmit = async (event: React.FormEvent) => {
        // Block native form submission.
        event.preventDefault();
    
        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          return;
        }

        if (!organization || !intent) return;
    
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);
        if (!cardElement) return;
    
        // Use your card Element with other Stripe.js APIs
        const result = await stripe.confirmCardSetup(intent.clientSecret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    // organizationName
                    name: organization.name,
                }
            }
        });

        handleStripeJsResult(result);
    };

    const handleStripeJsResult = ({ error, setupIntent }: SetupIntentResult) => {
        if (error) {
            props.addError({ id: Date.now(), message: error.message ? error.message : "" });
        } else {
            if(setupIntent && setupIntent.status === 'succeeded') {
                props.addCreditCard({
                    customerId: organization!.customerId,
                    paymentMethodId: setupIntent?.payment_method,
                    isDefault,
                });

                props.handleClosePopup();
            } else {
                // ??

            }
        }
    }

    const handleIsDefaultChange = () => {
        setIsDefault(prev => { return !prev });
    }

    return (
        <Dialog
            open={open}
            onClose={props.handleClosePopup}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">
                { isAdding ? "Add Credit Card" : "Update Credit Card"}
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <CardElement
                        options={{
                            style: {
                              base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                  color: '#aab7c4',
                                },
                              },
                              invalid: {
                                color: '#9e2146',
                              },
                            },
                            hidePostalCode: true,
                          }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isDefault}
                                name="isDefault"
                                color="primary"

                                onChange={handleIsDefaultChange}
                            />
                        }
                        label="Set as the default card"
                    />
                    <Button type="submit" disabled={!stripe}>
                        Save Changes
                    </Button>
                </form>
            </DialogContent>
            {/* <DialogActions>
                <Button onClick={props.handleClosePopup}>Cancel</Button>
                {
                    isAdding ? <Button onClick={handleAddCreditCard}>Add</Button>
                             : <Button onClick={handleUpdateCreditCard}>Update</Button>
                }

            </DialogActions> */}
        </Dialog>
    )
};

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        navTabIsSelected: s.navTabSelector(state),
        subscriptionPlans: lookupSelectors.subscriptionPlansSelector(state),
        validationErrors: errorSelectors.validationSelector(state),
        organization: s.organizationSelector(state),
        stripeSetup: selectors.stripeSettingsSelector(state),
    }  
} 

const mapDispatchToProps = { createSetupIntent, confirmStripePayment, addError };

const StripePopupContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(StripePopup);

export default StripePopupContainer;
import { useEffect } from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as sharedActions from "../shared/sharedActions";
import { RouteComponentProps, withRouter } from "react-router-dom";

type Props = {
    signOut: typeof sharedActions.signOut,   
};

const SignOutComponent: React.FC<RouteComponentProps & Props> = (props) => {
    const { history } = props;

    useEffect(() => {
        props.signOut({
            returnUrl: history.location.pathname,
        });
    }, [])

    return(
        <></>
    )
}

const mapStateToProps = (state: any) => ({
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        signOut: sharedActions.signOut,
    }, dispatch);
  };
  
const SignOut = connect(mapStateToProps, mapDispatchToProps)(SignOutComponent);

export default withRouter(SignOut);
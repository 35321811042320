import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography
    , Button,
} from "@material-ui/core";

import { oauthSettings } from '../../config/appSettings';
import GSuitLogo from '../../svg/gsuite.jsx';
import Office365 from '../../svg/office365.jsx';
import '../setupPage.scss';
import { EmailClientTypes } from '../setupEnums';
import { KeyValueModel } from '../../shared/SharedModels';
import { SelectList } from '../../shared/SelectList';
import CustomRadioButton from '../../shared/components/CustomRadioButton';
import { getAuthUrl } from "../../shared/sharedActions";

const googleSettings = oauthSettings.google;
const azureSettings = oauthSettings.azure;

type ConnectEmailClientProps = {
    selectedEmailClient: EmailClientTypes,

    handleEmailClientChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void,
    getAuthUrl: typeof getAuthUrl,
};

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      borderRadius: "",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    emailClients: {
        display: "flex",
        marginTop: "10px",
    },
    caption: {
        fontWeight: 700,
    },
}));

const ConnectEmailClient = (props: ConnectEmailClientProps) => {
    const classes = useStyles();
    const { selectedEmailClient } = props;
    const [isAdmin, setIsAdmin] = React.useState("No");
    const isAdminTypes: KeyValueModel[] = [
        { key: 0, value: "No" },
        { key: 1, value: "Yes" }
    ];

    const emailClientAdminType = selectedEmailClient === EmailClientTypes.gsuite ? 'Super' : 'Global';

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
        setIsAdmin(event.target.value);
    }

    const handleConnect = async () => {
        let qParams = "";
        let provider = "";

        const settings = selectedEmailClient === EmailClientTypes.gsuite ? googleSettings : azureSettings;
        provider = selectedEmailClient === EmailClientTypes.gsuite ? "google" : "azure";
        const state = selectedEmailClient === EmailClientTypes.gsuite ? "gsuite" : "office365";

        qParams = [
            `redirectUri=${settings.REDIRECT_URI}`,
            `scope=${settings.SCOPE}`,
            `state=${state}`,
            `access_type=offline`
        ].join("&");
        if(!qParams || !provider) return;

        props.getAuthUrl({ provider, qParams });
    }

    return (
        <>
            <Box className="emailclient-container" display="flex" alignItems="flex-start" >
                <Box>
                    <Typography className={classes.caption} >Select Email сlient</Typography>
                    <Box className={classes.emailClients}>
                        <CustomRadioButton
                            name="emailclient"
                            className="emailclient margin-right"
                            handleChange={props.handleEmailClientChange}
                            selected={selectedEmailClient}
                            value={EmailClientTypes.gsuite}>

                            <GSuitLogo opacity={ selectedEmailClient === EmailClientTypes.gsuite ? "1" : "0.31" } />

                        </CustomRadioButton>

                        <CustomRadioButton
                            name="emailclient"
                            handleChange={props.handleEmailClientChange}
                            selected={selectedEmailClient}
                            value={EmailClientTypes.office365}>

                            <Office365 opacity={ selectedEmailClient === EmailClientTypes.office365 ? "1" : "0.31" } />

                        </CustomRadioButton>
                    </Box>
                </Box>
                <Box>
                    <Typography className={classes.caption}>{`Are you a ${emailClientAdminType} Admin?`}</Typography>
                    <SelectList id="admin" name="admin" className="select-holder"
                        value={isAdmin}
                        onChange={handleChange}
                        items={isAdminTypes}
                        displayEmpty={true}

                        // disabled={isDisabled} error={validation.hasError(validationErrors, 'certificationType')}
                        // helperText={validation.getErrorText(validationErrors, 'certificationType')}
                    />
                </Box>
                <Box className="connect-btn" display="flex" justifyContent="flex-end">
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleConnect}
                        disabled={ isAdmin === "No" }

                        size="large"
                    >
                        Connect
                    </Button>
                </Box>
            </Box>
            { isAdmin === "No" ?
                <Typography className="note">
                    *NOTE: You must be the {emailClientAdminType} Admin for your organization in order to connect the email client.
                </Typography>
                : <></>
            }
        </>
    )
}

export default ConnectEmailClient;

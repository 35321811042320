import React, {useState, useEffect, useRef, useCallback} from "react";
import {
    Box, Dialog
    , DialogActions,
    Typography, Button,
} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import '../setupPage.scss';
import {TemplateModel} from "../SetupModels";
import {updateTemplate} from "../setupActions";
import {loadIconSets} from '../../lookups/lookupActions';
import {KeyValueModel} from "../../shared/SharedModels";
import {IconSetType} from "../../lookups/LookupEnum";
import {appSettings} from '../../config/appSettings';
import {ApplicationState} from "../../ApplicationState";
import * as lookupSelectors from "../../lookups/lookupSelectors";
import {connect} from "react-redux";
import IconSetComponent from "../../shared/components/IconSetComponent";
import {animateScroll as scroll} from "react-scroll";

const staticDataUrl = appSettings.app.staticDataUrl;

type IconTemplateProps = {
    template: TemplateModel,
    staticIconSets: KeyValueModel[],
    animatedIconSets: KeyValueModel[],

    loadIconSets: typeof loadIconSets,
    updateTemplate: typeof updateTemplate,
}

type IconSetsPopupProps = {
    template: TemplateModel
    iconSets: KeyValueModel[],
    selectedIconSet: number,
    open: boolean,

    handleChange: (e: React.MouseEvent<HTMLButtonElement>, value: number) => void,
    handleClose: () => void,
};

type IconSetsWrapperProps = {
    template: TemplateModel
    iconSet: KeyValueModel,
    iconType: IconSetType,
    isSelected: boolean,

    clickHandle: (e: any, type: IconSetType) => void,
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,

        borderRadius: "",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    emailClients: {
        display: "flex",

        // cursor: "pointer",
    },
    circle: {
        borderRadius: "50%",
        marginRight: "5px",

        height: "25px",
        minWidth: "25px",

        display: "inline-block",
    },
    iconPopupContainer: {},
    iconPopup: {
        flexDirection: "column",
        minWidth: "400px",

        padding: "20px",
    },
    iconSetBtn: {
        margin: "15px",
        borderRadius: "35px",
        width: "100%",
    },
    iconSet: {
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
    },
    iconTitle: {
        lineHeight: "45px",
    },
}));

const IconSetsPopup: React.FC<IconSetsPopupProps> = (props) => {
    const classes = useStyles();
    const {open} = props;
    const {actionCount} = props.template
    const {iconSets} = props

    const scrollRef = useCallback(node => {
        if (node !== null) {
            node.scrollIntoView({block: "center", inline: "nearest"});
        }
    }, []);

    // filter the icon set before mapping
    let iconSet = props.iconSets;
    if (actionCount === 3) iconSet = props.iconSets.filter(set => set.value.satisfied && set.value.great && set.value.verydissatisfied);
    if (actionCount === 5) iconSet = props.iconSets.filter(set => set.value.satisfied && set.value.dissatisfied && set.value.exceededExpectation && set.value.great && set.value.verydissatisfied);

    return (
        <Dialog
            open={open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"

            className={classes.iconPopupContainer}
        >
            <DialogActions className={classes.iconPopup}>
                {
                    iconSet.map(set => (
                            <div
                                className={`custom-radiobtn ${props.selectedIconSet === set.key ? 'active' : ''} ${classes.iconSetBtn}`}
                                key={set.key}
                                ref={props.selectedIconSet === set.key ? scrollRef : null}
                            >
                                <Button
                                    style={{width: "100%", justifyContent: "space-around"}}
                                    className="btn-override"
                                    name={`iconSet-${set.key}`}
                                    id={set.key}
                                    value={set.key}
                                    onClick={(e) => props.handleChange(e, set.key)}
                                >
                                    <label htmlFor={set.key} className={classes.iconSet}>
                                        <IconSetComponent actionCount={actionCount} icon={set.value}/>
                                    </label>
                                </Button>
                            </div>
                        )
                    )
                }
            </DialogActions>
        </Dialog>
    )
};

const IconSetWrapper: React.FC<IconSetsWrapperProps> = (props) => {
    const {isSelected} = props;
    const {type} = props.iconSet.value;
    const {actionCount} = props.template

    const getIconTypeName = (type: number) => {
        if (IconSetType.Static === type) return "Static - Click to select template";
        if (IconSetType.Animated === type) return "Animated - Click to select template";
        return ""
    }

    return (
        <>
            <Box
                className={`custom-radiobtn ${props.iconType === type ? 'active' : ''} iconSet-container content-block }`}
                style={{marginRight: type === IconSetType.Static ? "50px" : "0px"}}
            >
                <Button style={{width: "100%"}} name={type} id={type} value={type}
                        onClick={(e) => props.clickHandle(e, type)}
                >
                    <label htmlFor={type} style={{width: "100%"}}>
                        <Box className="iconSets-detail">
                            <Typography className="type" variant="h6">{getIconTypeName(props.iconType)}</Typography>
                            <Box>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                                    <circle id="Ellipse_106" data-name="Ellipse 106" cx="24" cy="24" r="24"
                                            fill={isSelected ? "#c8dafe" : "#fff"}/>
                                    <path id="Path_501" data-name="Path 501"
                                          d="M9,16.17,5.53,12.7a1,1,0,0,0-1.41,1.41L8.3,18.29a1,1,0,0,0,1.41,0L20.29,7.71A1,1,0,0,0,18.88,6.3Z"
                                          transform="translate(12 12)" fill={isSelected ? "#3b76ef" : "transparent"}/>
                                </svg>

                            </Box>
                        </Box>
                        <Box className="iconSets">
                            <IconSetComponent actionCount={actionCount} icon={props.iconSet.value}/>
                        </Box>
                    </label>
                </Button>
            </Box>
        </>
    )
}

const IconTemplate = (props: IconTemplateProps) => {
    const {actionCount} = props.template;
    const initialOpenState: { [key: number]: boolean } = {
        1: false,
        2: false,
    };
    const [open, setOpen] = useState(initialOpenState);

    const {
        template, staticIconSets, animatedIconSets, loadIconSets
    } = props;

    const allIconSets = [...staticIconSets, ...animatedIconSets];
    const iconSet = allIconSets.find(set => set.key === template.iconSet);

    // need to get the default icon set based on the selected icon set, i.e. 5 icon set or 3 icon set
    let defaultStaticIconSet: KeyValueModel = staticIconSets.filter(set => set.value.satisfied)[0];
    if (actionCount > 3) defaultStaticIconSet = staticIconSets.filter(set => set.value.satisfied && set.value.dissatisfied && set.value.exceededExpectation && set.value.great && set.value.verydissatisfied)[0];
    let defaultAnimatedIconSet: KeyValueModel = animatedIconSets[0];
    if (actionCount > 3) defaultAnimatedIconSet = animatedIconSets.filter(set => set.value.satisfied && set.value.dissatisfied && set.value.exceededExpectation && set.value.great && set.value.verydissatisfied)[0];

    const handleIconSetChange = (event: React.MouseEvent<HTMLButtonElement>, iconSetId: number) => {
        event.stopPropagation();

        props.updateTemplate({
            ...template,
            iconSet: iconSetId,
        });

        handlePopupClose();
    }

    const handlePopupClose = () => {
        setOpen(initialOpenState);
    }

    useEffect(() => {
        if (!staticIconSets.length) loadIconSets();
    }, []);

    return (
        <Box className='icon-template'>
            <Box display="flex" justifyContent="space-between">
                {staticIconSets.length ?
                    <IconSetWrapper template={template} iconSet={iconSet?.value.type === IconSetType.Static ? iconSet : defaultStaticIconSet}
                                    isSelected={iconSet?.value.type === IconSetType.Static}
                                    iconType={IconSetType.Static} clickHandle={(e, type) => {
                        setOpen({...open, 1: true})
                    }}/> : null}
                {animatedIconSets.length ?
                    <IconSetWrapper template={template} iconSet={iconSet?.value.type === IconSetType.Animated ? iconSet : defaultAnimatedIconSet}
                                    isSelected={iconSet?.value.type === IconSetType.Animated}
                                    iconType={IconSetType.Animated} clickHandle={(e, type) => {
                        setOpen({...open, 2: true})
                    }}/> : null}
            </Box>
            <IconSetsPopup template={template} open={open[1]} iconSets={staticIconSets}
                           selectedIconSet={props.template.iconSet} handleChange={handleIconSetChange}
                           handleClose={handlePopupClose} />
            <IconSetsPopup template={template} open={open[2]} iconSets={animatedIconSets}
                           selectedIconSet={props.template.iconSet} handleChange={handleIconSetChange}
                           handleClose={handlePopupClose} />
        </Box>
    )
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        staticIconSets: lookupSelectors.iconSetsSelectorByType(state, IconSetType.Static),
        animatedIconSets: lookupSelectors.iconSetsSelectorByType(state, IconSetType.Animated),
    }
}

const mapDispatchToProps = {loadIconSets, updateTemplate};

const IconTemplateContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(IconTemplate);

export default IconTemplateContainer;

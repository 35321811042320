import {Typography, Box, Grid, Button, Link, Snackbar, Dialog, DialogContent, makeStyles} from "@material-ui/core";
import * as clipboard from "clipboard-polyfill";
import SectionComponent from "../../shared/components/SectionComponent";
import {AgentWithSnippetModel, TemplateModel} from "../SetupModels";

import * as lookupSelectors from "../../lookups/lookupSelectors";
import * as selectors from "../setupSelectors";
import * as sharedSelectors from "../../shared/sharedSelectors";
import React, {useEffect, useState} from "react";
import {loadTemplateActions, loadFontSizes, loadFontFamilies, loadColorPalette} from "../../lookups/lookupActions";
import {
    getAgentsWithSnippetByOrg,
    getTemplate,
    changeCopySnippetFlag,
    emailSurveyToAgent,
    setAlertNotification,
    getAgents
} from '../setupActions';
import {KeyValueModel, OrganizationModel} from "../../shared/SharedModels";
import {ApplicationState} from "../../ApplicationState";
import {connect} from "react-redux";
import {Alert} from "@material-ui/lab";
import SubmitFeedbackPage from "../../Feedback/components/SubmitFeedbackPage";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CodeOutlinedIcon from '@material-ui/icons/CodeOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';

import '../../Feedback/components/FeedbackPage.scss';
import SnackbarComponent from "../../shared/components/Snackbar";

type SignaturePageProps = {
    template?: TemplateModel,
    agents: AgentWithSnippetModel[],
    organization?: OrganizationModel,

    actions: KeyValueModel[],
    fontSizes: KeyValueModel[],
    fontFamilies: KeyValueModel[],
    colorPalette: KeyValueModel[],
    iconSets: KeyValueModel[],
    isCopied: boolean,
    isEmailedToAgent: boolean,

    loadTemplateActions: typeof loadTemplateActions,
    loadFontSizes: typeof loadFontSizes,
    loadFontFamilies: typeof loadFontFamilies,
    loadColorPalette: typeof loadColorPalette,
    getAgentsWithSnippetByOrg: typeof getAgentsWithSnippetByOrg,
    getTemplate: typeof getTemplate,
    changeCopySnippetFlag: typeof changeCopySnippetFlag,
    emailSurveyToAgent: typeof emailSurveyToAgent,
    setAlertNotification: typeof setAlertNotification,
}

type SubmitFeedbackPopupState = {
    open: boolean,
    surveyAnswerId: string,
    type: string,
    organizationId: number,
    agentId: number | null,
};

type SubmitFeedbackPopupProps = {
    state: SubmitFeedbackPopupState,

    handleClose: () => void,
};

const useStyles = makeStyles((theme) => ({
    submitFeedbackPopup: {
        width: "635px",
    },
    iconButton: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row"
    }
}));


const SubmitFeedbackPopup: React.FC<SubmitFeedbackPopupProps> = (props) => {
    const {state} = props;
    const classes = useStyles();
    const organizationId = Number(state.organizationId).toString();
    const agentId = Number(state.agentId).toString();
    return (
        <Dialog
            open={state.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            // className={classes.submitFeedbackPopup}
            // fullWidth={true}
            // maxWidth='sm'
        >
            <DialogContent>
                <Box className="submit-feedback-popup container">
                    <SubmitFeedbackPage
                        organizationId={organizationId}
                        agentId={agentId}
                        surveyAnswerId={state.surveyAnswerId}
                        submitRate={state.type}
                    />
                </Box>
            </DialogContent>
        </Dialog>
    )
}

const SignaturePage = (props: SignaturePageProps) => {
    const {agents, actions, fontSizes, fontFamilies, colorPalette, isCopied, organization, isEmailedToAgent} = props;
    const classes = useStyles();
    const initialsubmitFeedbackPopupState = {
        open: false,
        surveyAnswerId: "",
        type: "",
        organizationId: organization!.id,
        agentId: null,
    };

    const [submitFeedbackPopupState, setSubmitFeedbackPopupState] = useState<SubmitFeedbackPopupState>(initialsubmitFeedbackPopupState);
    const [dialogState, setDialogState] = useState<{name: string, state: boolean}[]>([]);
    useEffect(() => {
        if (!actions.length) props.loadTemplateActions();
        if (!fontSizes.length) props.loadFontSizes();
        if (!fontFamilies.length) props.loadFontFamilies();
        if (!colorPalette.length) props.loadColorPalette();

        props.getAgentsWithSnippetByOrg();

        // for what reason ??
        getTemplate();
    }, []);

    useEffect(() => {
        props.loadTemplateActions();
        props.loadFontSizes();
        props.loadFontFamilies();
        props.loadColorPalette();
        props.getAgentsWithSnippetByOrg();
        getAgents();
    }, [organization]);

    const setupFeedbackClickHandler = (feedbackType: string, agentId: number) => {
        const element = document.getElementById(`${feedbackType}-${agentId}`);
        if (element != null) {
            element.onclick = function (e) {
                submitFeedbackOpen(e, agentId);
            };
        }
    }

    useEffect(() => {
        if (agents.length) {
            agents.forEach((agent) => {
                ['VerySatisfied', 'ExceededExpectations', 'Satisfied', 'Dissatisfied', 'VeryDissatisfied'].forEach(feedbackType => {
                    setupFeedbackClickHandler(feedbackType, agent.id);
                });
            });
        }
    }, [agents]);

    const copyToClipboard = async (rawHtml: string) => {
        const item = new clipboard.ClipboardItem({
            "text/html": new Blob(
                [rawHtml],
                {type: "text/html"}
            )
        });

        await clipboard.write([item]);

        props.changeCopySnippetFlag(true);
    };

    const copyHTMLToClipboard = async (rawHtml: string) => {
        await clipboard.writeText(rawHtml);
        props.changeCopySnippetFlag(true);
    };

    const submitFeedbackOpen = (ev: globalThis.MouseEvent, agentId: number) => {
        const target = ev.currentTarget as HTMLTextAreaElement;
        const ref = target.getAttribute("href");
        const type = target.getAttribute("id")!.replace(`-${agentId}`, "");
        const surveyAnswerId = ref!.substring(ref!.indexOf('surveyAnswerId=')).replace('surveyAnswerId=', '');
        setSubmitFeedbackPopupState((prev: SubmitFeedbackPopupState) => {
            return {
                open: true,
                surveyAnswerId,
                type,
                organizationId: organization!.id,
                agentId,
            }
        });
        ev.preventDefault();
        return false;
    };

    const handleCopiedAlertClose = () => {
        props.changeCopySnippetFlag(false);
    }

    const handleSubmitFeedbackPopupClose = () => {
        setSubmitFeedbackPopupState(initialsubmitFeedbackPopupState);
    };

    const toggleDialog = (dialogId: string) => {
        // add state if it doesn't exist
        if (!dialogState.find(dialog => dialog.name === dialogId)) {
            setDialogState([...dialogState, {name: dialogId, state: true}]);
        } else {
            // toggle state
            setDialogState(dialogState.map(dialog => {
                if (dialog.name === dialogId) {
                    dialog.state = !dialog.state;
                }
                return dialog;
            }));
        }

    }

    return (
        <>
            <SectionComponent>Signature</SectionComponent>
            <Box id="signature-container">
                {agents && agents.map((agent: AgentWithSnippetModel) => {
                    return (
                        <Box className='signature'>
                            <Grid className='agent-container' container xs={4}>
                                <Grid item xs={4}
                                      style={{borderBottom: "1px solid rgba(224, 224, 224, 1)", paddingLeft: "10px"}}>
                                    <strong>Full Name</strong>
                                </Grid>
                                <Grid item xs={8} style={{borderBottom: "1px solid rgba(224, 224, 224, 1)"}}>
                                    {agent.fullName}
                                </Grid>
                                <Grid item xs={4} style={{paddingLeft: "10px"}}>
                                    <strong>Email Address</strong>
                                </Grid>
                                <Grid className="highlighted-text" item xs={8}>
                                    <Typography color="primary">{agent.defaultEmail}</Typography>
                                </Grid>
                            </Grid>
                            <Button className={"agentSnippet"}>
                                <Box
                                    className="snippet"
                                    dangerouslySetInnerHTML={{__html: agent.snippet}}
                                />
                            </Button>
                            <Box className="btn-container" display="flex" flexDirection="column"
                                 justifyContent="center" justifyItems={"center"}>
                                <Button
                                    className={classes.iconButton}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => copyToClipboard(agent.snippet)}
                                >
                                    Copy Widget to Clipboard <FileCopyOutlinedIcon style={{marginLeft: "1rem"}}/>
                                </Button>
                                <Box display={"flex"} justifyContent={"center"} width={"100%"} marginTop={"0.5rem"}>
                                    <Button variant={"text"} fullWidth={true} color={"primary"} style={{textDecoration: "underline"}} onClick={() => toggleDialog(`dialog_${agent.id}`)}>More Options</Button>
                                </Box>
                                <Dialog
                                    open={dialogState.find(dialog => dialog.name === `dialog_${agent.id}`)?.state || false}
                                    onClose={() => toggleDialog(`dialog_${agent.id}`)}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogContent>
                                        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} gridGap={"1rem"}>
                                            <Button
                                                className={classes.iconButton}
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => copyHTMLToClipboard(agent.snippet)}
                                            >
                                                Copy HTML Widget to Clipboard <CodeOutlinedIcon style={{marginLeft: "1rem"}}/>
                                            </Button>
                                            <Button
                                                className={classes.iconButton}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => props.emailSurveyToAgent(agent.id)}
                                            >
                                                Email Survey to Staff Member <EmailOutlinedIcon style={{marginLeft: "1rem"}}/>
                                            </Button>
                                        </Box>
                                    </DialogContent>
                                </Dialog>
                            </Box>
                        </Box>
                    )
                })}
            </Box>
            {submitFeedbackPopupState.open ?
                <SubmitFeedbackPopup
                    state={submitFeedbackPopupState}
                    handleClose={handleSubmitFeedbackPopupClose}
                />
                :
                <></>
            }
            <Snackbar open={isCopied} onClose={handleCopiedAlertClose} autoHideDuration={1000}>
                <Alert variant="filled" severity="success">
                    COPIED
                </Alert>
            </Snackbar>
            <SnackbarComponent
                isOpen={isEmailedToAgent}
                msg="EMAILED"
                severity="success"
                handleClose={() => props.setAlertNotification(false)}
            />
        </>
    )
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        actions: lookupSelectors.actionTypesSelector(state),
        fontSizes: lookupSelectors.fontSizeTypesSelector(state),
        fontFamilies: lookupSelectors.fontFamilyTypesSelector(state),
        colorPalette: lookupSelectors.colorPaletteSelector(state),
        iconSets: lookupSelectors.iconSetsSelector(state),

        agents: selectors.agentWithSnippetSelector(state),
        template: selectors.templateSelector(state),
        isCopied: selectors.isSnippetCopiedSelector(state),
        isEmailedToAgent: selectors.isEmailedToAgentSelector(state),
        organization: sharedSelectors.organizationSelector(state),
    }
}

const mapDispatchToProps = {
    loadTemplateActions, loadFontSizes, loadFontFamilies, loadColorPalette
    , getAgentsWithSnippetByOrg, getTemplate, changeCopySnippetFlag, emailSurveyToAgent, setAlertNotification
};

const SignatureContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SignaturePage);

export default SignatureContainer;

import { deprecated } from 'typesafe-actions';
import { ConfirmEmailVerificationModel, ReenterSignupEmailModel, SignupInvitedUserModel, UserSignUpModel } from './signUpModels';

const { createStandardAction } = deprecated;

export const signUp = createStandardAction('signup/REGISTER_NEW_ORG')<UserSignUpModel>();

export const signupInvitedUser = createStandardAction('signup/REGISTER_INVITED_USER')<SignupInvitedUserModel>();

export const validateSignUpModel = createStandardAction('signup/VALIDATE_SIGNUP')<UserSignUpModel>();
export const validateInvitedUserSignUpModel = createStandardAction('signup/VALIDATE_INVITED_USER_SIGNUP')<SignupInvitedUserModel>();

export const validateSignUpModelCompleted = createStandardAction('signup/VALIDATE_SIGNUP_COMPLETED')<boolean>();

export const confirmEmailVerification = createStandardAction('signup/CONFIRM_EMAIL_VERIFICATION')<ConfirmEmailVerificationModel>();

export const resendOTP = createStandardAction("signup/RESEND_OTP")();

export const reenterSignupEmail = createStandardAction("signup/REENTER_SIGNUP_EMAIL")<ReenterSignupEmailModel>();
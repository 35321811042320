import { ApplicationState } from "../ApplicationState";

export const tableSummarySelector = (state: ApplicationState) => {
    return state.summary.tableSummary;
}

export const chartSummarySelector = (state: ApplicationState) => {
    return state.summary.chartSummary;
}

export const filtersSelector = (state: ApplicationState) => {
    return state.summary.filters;
}
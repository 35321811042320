import {
    AddAgent,
    AgentProfile,
    AgentWithSnippet,
    GetReviewSiteOption,
    ImportUser,
    Template
} from "./SetupPageData";
import {
    AgentProfileModel,
    AgentWithSnippetModel,
    ImportUserModel,
    NewAgentModel,
    ReviewSiteOptionModel,
    TemplateFeedbackOptionModel,
    TemplateModel
} from "./SetupModels";

export const agentToModel = (agent: AgentProfile): AgentProfileModel => {
    return {
        id: agent.id,
        fullName: agent.fullName,
        defaultEmail: agent.login,
        active: agent.isActive,
    }
};

export const agentWithSnippetToModel = (agent: AgentWithSnippet): AgentWithSnippetModel => {
    return {
        id: agent.id,
        fullName: agent.fullName,
        defaultEmail: agent.login,
        active: agent.isActive,
        snippet: agent.snippet,
    }
};

export const agentFromModel = (model: AgentProfileModel): AgentProfile => {
    return {
        id: model.id,
        fullName: model.fullName,
        login: model.defaultEmail,
        isActive: model.active,
    }
};

export const addAgentFromModel = (model: NewAgentModel): AddAgent => {
    return {
        login: model.defaultEmail,
        fullName: model.fullName,
        isActive: false,
    }
};

export const getReviewSiteOptionFromModel = (model: ReviewSiteOptionModel): GetReviewSiteOption => {
    return {
        id: model.id,
        name: model.name,
    }
};

export const templateToModel = (template: Template): TemplateModel => {
    return {
        id: template.id,
        action: template.title,
        size: template.fontSize,
        font: template.fontFamily,
        titleSpacing: template.titleSpacing || 24,
        color: template.fontColor,
        iconSet: template.iconSetId,
        iconSize: template.iconSize || 50,
        iconSpacing: template.iconSpacing || 50,
        isDefault: template.isDefault,
        lang: template.lang,
        actionCount: template.actionCount,
        feedbackOption: template.feedbackOption,
    }
};

export const templateFromModel = (template: TemplateModel): Template => {
    return {
        id: template.id,
        title: template.action,
        fontSize: template.size,
        fontFamily: template.font,
        fontColor: template.color,
        iconSetId: template.iconSet,
        isDefault: template.isDefault,
        lang: template.lang,
        actionCount: template.actionCount,
        feedbackOption: template.feedbackOption,
        titleSpacing: template.titleSpacing,
        iconSize: template.iconSize,
        iconSpacing: template.iconSpacing,
    }
};

export const templateFeedbackOptionFromModel = (template: TemplateFeedbackOptionModel): TemplateFeedbackOptionModel => {
    return {
        id: template.id,
        feedbackOption: template.feedbackOption,
    };
};

export const importUserToModel = (data: ImportUser): ImportUserModel => {
    return {
        user: data.user,
        importStatus: data.importStatus,
        errorMessage: data.errorMessage,
    }
};

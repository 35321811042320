import {call, put, select, take, takeLatest, throttle} from "redux-saga/effects";

import * as api from '../api/index';
import * as actions from './setupActions';
import * as mappers from './setupMappers';
import * as sharedSelectors from '../shared/sharedSelectors';
import * as setupSelectors from './setupSelectors';
import * as errorActions from '../shared/errors/errorActions';
import * as sharedActions from '../shared/sharedActions';
import * as routerActions from '../shared/router/routerActions';
import * as loadingActions from '../shared/loading/loadingStateActions';
import {
    AddAgent,
    AgentProfile,
    AgentWithSnippet,
    ImportUser,
    ReviewSite,
    Template
} from "./SetupPageData";
import {
    AgentProfileModel,
    AgentWithSnippetModel, AutocompleteSuggestion,
    ReviewSiteModel, ReviewSiteOptionModel,
    TemplateModel,
} from "./SetupModels";
import {ActionType, getType} from "typesafe-actions";
import {customErrorMapper} from "../shared/errors/ErrorMappers";
import {UserProfileModel} from "../shared/SharedModels";
import {BadRequestError} from "../auth/ApiError";
import {profileSelector} from "../shared/sharedSelectors";


export default function* setupSaga() {
    // implement via getType
    yield takeLatest(getType(actions.getAgents), getWhiteLabelAgents);
    yield takeLatest('setup/EDIT_AGENT', editAgent);
    yield takeLatest('setup/ADD_AGENT', addAgent);
    yield takeLatest(getType(actions.getTemplate), getTemplate);
    yield takeLatest(getType(actions.updateTemplateTitleSpacing), updateTemplateTitleSpacing);
    yield takeLatest(getType(actions.updateTemplateIconSize), updateTemplateIconSize);
    yield takeLatest(getType(actions.updateTemplateIconSpacing), updateTemplateIconSpacing);
    yield throttle(1000, getType(actions.updateTemplate), updateTemplate);
    yield takeLatest(getType(actions.updateTemplateFeedbackOption), updateTemplateFeedbackOption);
    yield takeLatest(getType(actions.importCSV), importCSV);
    yield takeLatest(getType(actions.importFromEmailClient), importFromEmailClient);
    yield takeLatest(getType(actions.getAgentsWithSnippetByOrg), getAgentsWithSnippetByOrg);
    yield takeLatest(getType(actions.emailSurveyToAgent), emailSurveyToAgent);
    yield takeLatest(getType(actions.getAgentSnippet), getAgentSnippet);
    yield takeLatest(getType(actions.getSuggestions), getSuggestions);
    yield takeLatest(getType(actions.toggleReviewSiteIsActive), toggleReviewSiteIsActive);
    yield takeLatest(getType(actions.toggleSurveyFormIsActive), toggleSurveyFormIsActive);
    yield takeLatest(getType(actions.addReviewSite), addReviewSite);
    yield takeLatest(getType(actions.deleteReviewSite), deleteReviewSite);
    yield takeLatest(getType(actions.getReviewSites), getReviewSites);
    yield takeLatest(getType(actions.getReviewSiteOptions), getReviewSiteOptions);
    yield takeLatest(getType(actions.setReviewSiteAllocationPercentage), setReviewSiteAllocationPercentage);
}

function* getWhiteLabelAgents(action: ActionType<typeof actions.getAgents>) {
    try {
        const profile: UserProfileModel = yield select(profileSelector);

        const organizationId = profile.organization.id;

        const agents: AgentProfile[] = yield call(api.getAgents, organizationId);
        const model: AgentProfileModel[] = agents.map((agent: AgentProfile) => mappers.agentToModel(agent));
        yield put(actions.getAgentsCompleted(model));

    } catch (e) {

    } finally {

    }
}

function* getAgentsWithSnippetByOrg(action: ActionType<typeof actions.getAgentsWithSnippetByOrg>) {
    try {
        const profile: UserProfileModel = yield select(profileSelector);

        const organizationId = profile.organization.id;

        const agents: AgentWithSnippet[] = yield call(api.getAgentsWithSnippetByOrg, organizationId);
        const model: AgentWithSnippetModel[] = agents.map((agent: AgentWithSnippet) => mappers.agentWithSnippetToModel(agent));
        yield put(actions.getAgentsWithSnippetByOrgCompleted(model));

    } catch (e) {

    } finally {

    }
}

function* getAgentSnippet(action: ActionType<typeof actions.getAgentSnippet>) {
    const agentId = action.payload;

    try {
        const response: Response = yield call(() => api.getAgentSnippet(agentId));

        if (response) {
            const data: AgentWithSnippet[] = yield call(() => response);
            const model: AgentWithSnippetModel[] = data.map((agent: AgentWithSnippet) => mappers.agentWithSnippetToModel(agent));
            yield put(actions.getAgentsWithSnippetByOrgCompleted(model));
        }

    } catch (e) {

    } finally {

    }
}

function* editAgent(action: ActionType<typeof actions.editAgent>) {
    try {
        const model = action.payload;
        // fields validation
        const data = mappers.agentFromModel(model);

        const agent: AgentProfile = yield call(() => api.updateAgent(data));
        yield put(actions.editAgentCompleted(model));
        yield put(actions.changeSaveTemplateFlag(true));

    } catch (e) {

        if (e instanceof BadRequestError) {
            const err = customErrorMapper(e.message);
            yield put(errorActions.addError(err));
        }

    } finally {

    }
}

function* addAgent(action: ActionType<typeof actions.addAgent>) {
    try {
        const model = action.payload;

        // fields validation
        const data: AddAgent = mappers.addAgentFromModel(model);
        const agent: AgentProfile = yield call(api.addAgent, data);

        const agentModel = mappers.agentToModel(agent);
        yield put(actions.addAgentCompleted(agentModel));

    } catch (e) {

        if (e instanceof BadRequestError) {
            const err = customErrorMapper(e.message);
            yield put(errorActions.addError(err));
        }

    } finally {

    }
}

function* getTemplate(action: ActionType<typeof actions.getTemplate>) {

    loadingActions.begin();

    try {

        const template: Template = yield call(api.getTemplate);
        // const response: ReturnResultData<Template> = yield call(mockApi.getTemplate);
        // const template: Template = response.data;

        const model: TemplateModel = mappers.templateToModel(template);
        yield put(actions.getTemplateCompleted(model));

    } catch (e) {

        loadingActions.reset();

    } finally {
        loadingActions.complete();
    }
}

function* updateTemplateTitleSpacing(action: ActionType<typeof actions.updateTemplateTitleSpacing>) {
    const template: TemplateModel = yield select(setupSelectors.templateSelector);
    yield put(actions.updateTemplate({...template, titleSpacing: action.payload}));
}

function* updateTemplateIconSize(action: ActionType<typeof actions.updateTemplateIconSize>) {
    const template: TemplateModel = yield select(setupSelectors.templateSelector);
    yield put(actions.updateTemplate({...template, iconSize: action.payload}));
}

function* updateTemplateIconSpacing(action: ActionType<typeof actions.updateTemplateIconSpacing>) {
    const template: TemplateModel = yield select(setupSelectors.templateSelector);
    yield put(actions.updateTemplate({...template, iconSpacing: action.payload}));
}

function* updateTemplate(action: ActionType<typeof actions.updateTemplate>) {
    try {
        const data = mappers.templateFromModel(action.payload);

        const template: Template = yield call(api.updateTemplate, data);
        // const response: ReturnResultData<Template> = yield call(mockApi.updateTemplate, data);
        // const template: Template = response.data;

        // const template: TemplateModel = mappers.templateToModel(updatedTemplateData);
        yield put(actions.updateTemplateCompleted(action.payload));

    } catch (e) {

    } finally {

    }
}

function* updateTemplateFeedbackOption(action: ActionType<typeof actions.updateTemplateFeedbackOption>): any {
    try {
        const data = mappers.templateFeedbackOptionFromModel(action.payload);

        const template: TemplateModel = yield call(api.updateTemplateFeedbackOption, data);
        yield put(actions.updateTemplateCompleted(template));
        yield put(actions.setReviewSiteAllocationPercentage(null));
    } catch (e) {
        console.error(e);

    } finally {

    }
}

function* importCSV(action: ActionType<typeof actions.importCSV>) {
    const csv = action.payload;
    yield put(loadingActions.beginScope("import"));

    try {
        const importUsers: ImportUser[] = yield call(api.uploadCSV, csv);
        const model = importUsers.map(user => mappers.importUserToModel(user));

        const profile: UserProfileModel = yield select(sharedSelectors.profileSelector);
        yield put(actions.getAgents());

        // need to handle it right (!!!)
        yield take(getType(actions.getAgentsCompleted));
        yield put(actions.importCompleted(model));

    } catch (e) {
        const err = customErrorMapper(e.message);
        yield put(errorActions.addError(err));

        yield put(loadingActions.resetScope("import"));
    } finally {

        yield put(loadingActions.completeScope("import"));
    }
}

function* importFromEmailClient(action: ActionType<typeof actions.importFromEmailClient>) {
    yield put(loadingActions.begin());
    const reqParams = action.payload;

    try {
        const importUsers: ImportUser[] = yield call(api.importFromEmailClient, reqParams.client, reqParams.code);

        const model = importUsers.map(user => mappers.importUserToModel(user));
        yield put(actions.importCompleted(model));
    } catch (e) {

        if (e instanceof BadRequestError) {
            const err = customErrorMapper(e.message);
            yield put(errorActions.addError(err));
        }

        yield put(loadingActions.reset());
    } finally {
        yield put(sharedActions.getProfile());
        // yield take(getType(sharedActions.loadUserProfileCompleted));
        // const profile: UserProfileModel = yield select(sharedSelectors.profileSelector);
        //
        // yield put(actions.getAgents());
        //
        // need to handle it right (!!!)
        // yield take(getType(actions.getAgentsCompleted));
        // yield put(routerActions.redirect(`/org${profile.organizationId}/setup/AgentImport/ConnectEmailClient`));

        yield put(loadingActions.complete());
    }
}


function* emailSurveyToAgent(action: ActionType<typeof actions.emailSurveyToAgent>) {
    yield put(loadingActions.begin());
    const agentId = action.payload;

    try {
        yield call(() => api.emailSurveyToAgent(agentId));
        yield put(actions.setAlertNotification(true));
    } catch (e) {

        if (e instanceof BadRequestError) {
            const err = customErrorMapper(e.message);
            yield put(errorActions.addError(err));
        }

        yield put(loadingActions.reset());
    } finally {
        yield put(loadingActions.complete());
    }
}


function* getSuggestions(action: ActionType<typeof actions.getSuggestions>) {
    try {
        // @ts-ignore
        const result = yield call(api.getSuggestions, action.payload)

        const predictions = result.predictions;

        const suggestions = predictions.map((x: AutocompleteSuggestion) => {
            return {
                description: x.description,
                place_id: x.place_id,
            }
        })

        yield put(actions.getSuggestionsCompleted(suggestions))
    } catch (e) {
        console.log(e)
    } finally {

    }
}


function* toggleReviewSiteIsActive(action: ActionType<typeof actions.toggleReviewSiteIsActive>) {

    try {
        const model = action.payload;

        yield call(() => api.toggleReviewSiteIsActive(model));

        yield put(actions.toggleReviewSiteIsActiveCompleted(model));

        yield put(actions.setReviewSiteAllocationPercentage(null));
    } catch (e) {

    } finally {

    }
}


function* toggleSurveyFormIsActive(action: ActionType<typeof actions.toggleSurveyFormIsActive>) {

    try {
        const model = action.payload;

        yield call(() => api.toggleSurveyFormIsActive(model));

        yield put(actions.toggleSurveyFormIsActiveCompleted(model.surveyFormIsActive));

    } catch (e) {

    } finally {

    }
}


function* addReviewSite(action: ActionType<typeof actions.addReviewSite>) {

    try {
        const model = action.payload;
        const reviewSite: ReviewSiteModel = yield call(api.addReviewSite, model);
        yield put(actions.setAlertNotification(true));
        yield put(actions.addReviewSiteCompleted(reviewSite));
        yield put(actions.setReviewSiteAllocationPercentage(null));
    } catch (e) {

        if (e instanceof BadRequestError) {
            const err = customErrorMapper(e.message);
            yield put(errorActions.addError(err));
        }

    } finally {
    }
}


function* deleteReviewSite(action: ActionType<typeof actions.deleteReviewSite>) {
    const id = action.payload;

    try {
        yield call(() => api.deleteReviewSite(id));
        yield put(actions.deleteReviewSiteCompleted(id));
        yield put(actions.setReviewSiteAllocationPercentage(null));
    } catch {

    } finally {

    }
}

function* setReviewSiteAllocationPercentage(action: ActionType<typeof actions.setReviewSiteAllocationPercentage>): any { // Select effect doesn't like typescript
    let reviewSites = action.payload;
    if (!reviewSites) reviewSites = yield select(setupSelectors.reviewSiteSelector);
    if (reviewSites) {
        const currentTemplate = yield select(setupSelectors.templateSelector);
        let activeReviewSiteCount = 0;
        activeReviewSiteCount = reviewSites.filter((site: ReviewSiteModel) => site.isActive).length;
        try {
            let updatedSites = reviewSites.map((site: ReviewSiteModel) => {
                const percentage = 100 / activeReviewSiteCount;
                const number = percentage.toFixed(2);
                site.allocationPercentage = "100";
                if (isFinite(+number)) {
                    site.allocationPercentage = number;
                }
                return site;
            });
            yield put(actions.getReviewSitesCompleted(updatedSites));
        } catch (e) {
            console.error(e);
        } finally {

        }
    }
}

function* getReviewSites(action: ActionType<typeof actions.getReviewSites>) {
    try {
        const reviewSites: ReviewSite[] = yield call(api.getReviewSites);
        // before we set the review sites, we need to set the allocation percentage
        yield put(actions.setReviewSiteAllocationPercentage(reviewSites));

    } catch (e) {

    } finally {

    }
}


function* getReviewSiteOptions(action: ActionType<typeof actions.getReviewSiteOptions>) {
    try {
        // @ts-ignore
        const result = yield call(api.getReviewSiteOptions);
        const reviewSitesOptions: ReviewSiteOptionModel[] = result.map((res: ReviewSiteOptionModel) => mappers.getReviewSiteOptionFromModel(res));
        const filteredOptions = reviewSitesOptions.filter((result: ReviewSiteOptionModel) => result.name !== 'Survey')

        yield put(actions.getReviewSiteOptionsCompleted(filteredOptions));

    } catch (e) {

    } finally {

    }
}

export default (params) => (

  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22" fill={params.fill}>
    <defs>
      <clipPath id="clipPath">
        <rect id="Rectangle_126" data-name="Rectangle 126" width="22" height="22" fill="#fff"/>
      </clipPath>
    </defs>
    <g id="Mask_Group_6" data-name="Mask Group 6" clipPath="url(#clipPath)">
      <g id="bill" transform="translate(1.16 0)">
        <g id="Group_546" data-name="Group 546">
          <g id="Group_545" data-name="Group 545">
            <path id="Path_458" data-name="Path 458" d="M20.41,10.57H17.23V.43a.43.43,0,0,0-.734-.3L15.279,1.343,14.062.126a.43.43,0,0,0-.608,0L12.237,1.343,11.02.126a.43.43,0,0,0-.608,0L9.195,1.343,7.978.126a.43.43,0,0,0-.608,0L6.153,1.343,4.936.126a.43.43,0,0,0-.608,0L3.111,1.343,1.893.126a.43.43,0,0,0-.733.315V19.766A2.237,2.237,0,0,0,3.395,22H18.605a2.237,2.237,0,0,0,2.234-2.234V11A.43.43,0,0,0,20.41,10.57ZM3.395,21.141A1.377,1.377,0,0,1,2.02,19.766V1.468l.787.787a.43.43,0,0,0,.608,0L4.632,1.037,5.849,2.255a.43.43,0,0,0,.608,0L7.674,1.037,8.891,2.255a.43.43,0,0,0,.608,0l1.217-1.217,1.217,1.217a.43.43,0,0,0,.608,0l1.217-1.217,1.217,1.217a.43.43,0,0,0,.608,0l.788-.788V11s0,.008,0,.012v8.754a2.223,2.223,0,0,0,.475,1.375H3.395ZM19.98,19.766a1.375,1.375,0,1,1-2.75,0V11.43h2.75v8.336Z" transform="translate(-1.16 0)"/>
          </g>
        </g>
        <g id="Group_548" data-name="Group 548" transform="translate(6.402 3.975)">
          <g id="Group_547" data-name="Group 547">
            <path id="Path_459" data-name="Path 459" d="M9.2,6.826a.725.725,0,0,1-.773-.662A.725.725,0,0,1,9.2,5.5a.725.725,0,0,1,.773.662.43.43,0,1,0,.859,0A1.546,1.546,0,0,0,9.625,4.7V4.4a.43.43,0,0,0-.859,0V4.7a1.546,1.546,0,0,0-1.2,1.468A1.582,1.582,0,0,0,9.2,7.685a.725.725,0,0,1,.773.662A.725.725,0,0,1,9.2,9.01a.725.725,0,0,1-.773-.662.43.43,0,1,0-.859,0,1.546,1.546,0,0,0,1.2,1.468v.351a.43.43,0,0,0,.859,0V9.815a1.546,1.546,0,0,0,1.2-1.468A1.582,1.582,0,0,0,9.2,6.826Z" transform="translate(-7.562 -3.975)" />
          </g>
        </g>
        <g id="Group_550" data-name="Group 550" transform="translate(11.084 6.826)">
          <g id="Group_549" data-name="Group 549">
            <path id="Path_460" data-name="Path 460" d="M13.512,6.826h-.837a.43.43,0,1,0,0,.859h.837a.43.43,0,0,0,0-.859Z" transform="translate(-12.245 -6.826)" />
          </g>
        </g>
        <g id="Group_552" data-name="Group 552" transform="translate(3.288 6.826)">
          <g id="Group_551" data-name="Group 551">
            <path id="Path_461" data-name="Path 461" d="M5.716,6.826H4.878a.43.43,0,0,0,0,.859h.837a.43.43,0,0,0,0-.859Z" transform="translate(-4.449 -6.826)" />
          </g>
        </g>
        <g id="Group_554" data-name="Group 554" transform="translate(5.332 11.602)">
          <g id="Group_553" data-name="Group 553">
            <path id="Path_462" data-name="Path 462" d="M13.729,11.6H6.922a.43.43,0,0,0,0,.859h6.807a.43.43,0,0,0,0-.859Z" transform="translate(-6.492 -11.602)" />
          </g>
        </g>
        <g id="Group_556" data-name="Group 556" transform="translate(3.071 11.602)">
          <g id="Group_555" data-name="Group 555">
            <path id="Path_463" data-name="Path 463" d="M4.965,11.728a.429.429,0,1,0,.126.3A.433.433,0,0,0,4.965,11.728Z" transform="translate(-4.231 -11.602)" />
          </g>
        </g>
        <g id="Group_558" data-name="Group 558" transform="translate(5.332 14.438)">
          <g id="Group_557" data-name="Group 557">
            <path id="Path_464" data-name="Path 464" d="M13.729,14.438H6.922a.43.43,0,0,0,0,.859h6.807a.43.43,0,0,0,0-.859Z" transform="translate(-6.492 -14.438)" />
          </g>
        </g>
        <g id="Group_560" data-name="Group 560" transform="translate(3.071 14.438)">
          <g id="Group_559" data-name="Group 559">
            <path id="Path_465" data-name="Path 465" d="M4.965,14.564a.429.429,0,1,0,.126.3A.433.433,0,0,0,4.965,14.564Z" transform="translate(-4.231 -14.438)" />
          </g>
        </g>
        <g id="Group_562" data-name="Group 562" transform="translate(5.332 17.274)">
          <g id="Group_561" data-name="Group 561">
            <path id="Path_466" data-name="Path 466" d="M13.729,17.274H6.922a.43.43,0,0,0,0,.859h6.807a.43.43,0,0,0,0-.859Z" transform="translate(-6.492 -17.274)" />
          </g>
        </g>
        <g id="Group_564" data-name="Group 564" transform="translate(3.071 17.274)">
          <g id="Group_563" data-name="Group 563">
            <path id="Path_467" data-name="Path 467" d="M4.965,17.4a.429.429,0,1,0,.126.3A.433.433,0,0,0,4.965,17.4Z" transform="translate(-4.231 -17.274)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
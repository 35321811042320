import { getType } from "typesafe-actions";
import * as actions from "./emailUnsubscribeActions";

const initialSettingsState = {
    result: "",
    isLoading: true
};

interface EmailUnsubscribeModel{
    result: string;
    isLoading: boolean;
}

const emailUnsubscribeReducer = (state: EmailUnsubscribeModel = initialSettingsState, action: any) => {
    switch(action.type){
        case getType(actions.emailUnsubscribeSuccess):
            return {
                ...state,
                result: "success",
                isLoading: false
            }
        case getType(actions.emailUnsubscribeFailure):
            return {
                ...state,
                result: "failure",
                isLoading: false
            }
        default:
            return {
                ...state,
            };
    }
}

export default emailUnsubscribeReducer;
import React from "react";
import { Box, Checkbox } from "@material-ui/core";

type CustomRadioButtonProps = {
    name: string,
    value: any,
    selected: any,
    children: React.ReactNode,
    className?: string,

    handleChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void,
}

const CustomRadioButton: React.FC<CustomRadioButtonProps> = (props) => {

    return(
        <Box className={ `custom-radiobtn ${props.selected === props.value ? 'active' : ''} ${props.className ? props.className : "" }` }>
            <Checkbox name={props.name} style={{ display: "none", }} id={props.value} value={props.value} checked={ props.selected === props.value }
                onChange={props.handleChange}
            />
            <label htmlFor={props.value}>
                {props.children}
            </label>
        </Box>
    )
}

export default CustomRadioButton;
import { AddCreditCard, Administrator, CreditCard, Invoice, UpdateBillingInfo, CouponCode } from "./AccountData";
import { AddCreditCardModel, AdministratorModel, CreditCardModel, InvoiceModel, UpdateBillingInfoModel, CouponCodesModel } from "./AccountModels";

function getMonthStr(m: number) {
    let month = m + 1;
    return (month < 10 ? `0${month}` : `${month}`);
}

export const addCreditCardFromModel = (card: AddCreditCardModel): AddCreditCard => {

    return {
        paymentMethodId: card.paymentMethodId,
        isDefault: card.isDefault,
        customerId: card.customerId,
    }
}

export const creditCardFromModel = (card: CreditCardModel): CreditCard => {

    return {
        id: card.id,
        paymentMethodId: card.paymentMethodId,
        isDefault: card.isDefault,
        customerId: card.customerId,
        last4: card.last4,
        expMonth: card.expMonth,
        expYear: card.expYear,
        brand: card.brand,
    }
}

export const creditCardToModel = (card: CreditCard): CreditCardModel => {

    return {
        id: card.id,
        paymentMethodId: card.paymentMethodId,
        isDefault: card.isDefault,
        customerId: card.customerId,
        last4: card.last4,
        expMonth: card.expMonth,
        expYear: card.expYear,
        brand: card.brand,
    }
}

export const updateBillingInfoDetailsFromModel = (model: UpdateBillingInfoModel): UpdateBillingInfo  => {
    return {
        organizationName: model.organizationFullName,
        contactFullName: model.user,
        contactEmail: model.email,
    }
}

export const invoiceToModel = (data: Invoice): InvoiceModel  => {

    return {
        id: data.id,
        createdDate: data.createdDate,
        status: data.status,
        totalPrice: data.totalPrice,
    }
}

export const administratorToModel = (data: Administrator): AdministratorModel  => {
    return {
        id: data.id,
        fullName: data.fullName, 
        login: data.login,
    }
}

export const couponCodeToModel = (coupon: CouponCode): CouponCodesModel => {
    return {
        id: coupon.id,
        initiative: coupon.initiative,
        code: coupon.code,
        discount: coupon.discount,
        subscriptionLevel: coupon.subscriptionLevel,
        isExpired: coupon.isExpired,
    }
}


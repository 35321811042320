import { action, deprecated } from 'typesafe-actions';
import { AlertModel } from './errors/ErrorModels';
import {
    GetOAuthUrlOptions,
    InvitedUserProfileModel, ReCaptchaModel,
    SignoutModel,
    SubscriptionPlanModel,
    UserProfileModel,
    VerifyingModel,
    VerifyOrgRequestModel,
    WhiteLabelProfile
} from './SharedModels';

const { createStandardAction } = deprecated;

export const getWhiteLabelProfile = createStandardAction('shared/GET_WHITE_LABEL_PROFILE')();
export const setWhiteLabelProfile = createStandardAction('shared/SET_WHITE_LABEL_PROFILE')<WhiteLabelProfile>();
export const whiteLabelCheckCompleted = createStandardAction('shared/WHITE_LABEL_CHECK_COMPLETED')();
export const switchNavMenu = (navTabIsSelected: boolean) => action('shared/SWITCH_NAV_MENU', navTabIsSelected);
export const setIsSearching = createStandardAction('shared/SET_ISSEARCHING')<boolean>();
// export const setProfile = (profile: UserProfileModel) => createAction('shared/SET_PROFILE', action => {
//     return (profile: UserProfileModel) => action(profile);
// });
export const getProfile = createStandardAction('shared/GET_PROFILE')();
export const switchOrganizationProfile = createStandardAction('shared/SWITCH_ORGANIZATION_PROFILE')<number>();
export const getInvitedUserProfile = createStandardAction('signup/GET_INVITED_USER_PROFILE')<number>();
export const getInvitedUserProfileCompleted = createStandardAction('signup/INVITED_USER_PROFILE_GOT')<InvitedUserProfileModel>();

export const getOrganizationPlan = createStandardAction('shared/GET_ORGANIZATION_PLAN')();
export const getAuthUrl = createStandardAction('shared/GET_AUTH_URL')<GetOAuthUrlOptions>();

export const loadUserProfileCompleted = createStandardAction('shared/LOAD_PROFILE_COMPLETED')<UserProfileModel>();
export const getOrgPlanCompleted = (model: SubscriptionPlanModel) => action('shared/GET_ORGANIZATION_PLAN_COMPLETED', model);

export const setServiceIsDisabledFlag = createStandardAction('shared/SET_SERVICE_IS_DISABLED_FLAG')<Boolean>();

export const setIsVerified = createStandardAction('shared/SET_IS_VERIFIED_FLAG')<Boolean>();
export const setVerifying = createStandardAction('shared/SET_IS_VERIFYING')<VerifyingModel>();
export const verifyOrganization = createStandardAction('shared/VERIFY_ORGANIZATION')<VerifyOrgRequestModel>();

export const setForgotPasswordConfirmed = createStandardAction('shared/SET_FORGOT_PASSWORD_CONFIRMED')<boolean>();
export const setResetPasswordConfirmed = createStandardAction('shared/SET_RESET_PASSWORD_CONFIRMED')<boolean>();

export const signOut = createStandardAction('shared/CLEAR_TOKENS')<SignoutModel>();
export const clearStore = createStandardAction('shared/CLEAR_STORE')();
export const clearUserProfile = createStandardAction('shared/CLEAR_USER_PROFILE')();

export const setReenteringFlag = createStandardAction("shared/SET_IS_REENTERING")<boolean>();

export const gettingReCaptchaToken = createStandardAction("shared/GETTING_RECAPTCHA_TOKEN")<string>();
export const verifyReCaptcha = createStandardAction("shared/VERIFY_RECAPTCHA")<string>();
export const setReCaptchaVerified = createStandardAction("shared/SET_RECAPTCHA_VERIFIED")<boolean>();
export const setReCaptchaResponse = createStandardAction("shared/SET_RECAPTCHA_RESPONSE")<ReCaptchaModel>();

export const getSubscriptionTrialDays = createStandardAction("shared/GET_SUBSCRIPTION_TRIAL_DAYS")();

export const getSubscriptionTrialDaysCompleted = createStandardAction("shared/GET_SUBSCRIPTION_TRIAL_DAYS_COMPLETED")<number>();

import { connect } from "react-redux";
import { ApplicationState } from "../../ApplicationState";
import * as selectors from "../sharedSelectors";
import { verifyOrganization } from "../sharedActions";
import {useCallback, useEffect} from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import {ReCaptchaModel, VerifyingModel} from "../SharedModels";
import * as sharedActions from "../sharedActions";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {setValidationErrors} from "../errors/errorActions";

type VerifyOrganizationProps = {
    verifying: VerifyingModel;
    verifyOrganization: typeof verifyOrganization;
    verifyReCaptcha: typeof sharedActions.verifyReCaptcha;
    recaptchaResponse?: ReCaptchaModel;
};

const VerifyOrganization: React.FC<VerifyOrganizationProps & RouteComponentProps<{ orgid: string }>> = (props) => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    const { orgid } = props.match.params;
    const { isVerified } = props.verifying;
    const organizationId = Number(orgid.replace("org", ""));

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            setValidationErrors([{name: 'recaptcha', message: 'reCaptcha not yet available'}]);
            return;
        }

        const token = await executeRecaptcha('/signup');
        if (token) {
            props.verifyReCaptcha(token);
        }

    }, [executeRecaptcha]);

    useEffect(() => {
        if (props.recaptchaResponse) {
            if (!props.recaptchaResponse.success) {
                setValidationErrors([{name: 'recaptcha', message: 'reCaptcha was not successful'}]);
                return;
            }
            if (props.recaptchaResponse.action !== '/signup' || props.recaptchaResponse.score < 0.6) {
                setValidationErrors([{name: 'recaptcha', message: 'reCaptcha did not pass'}]);
                return;
            }
            const model = {
                organizationId,
            };
            props.verifyOrganization(model);
        }
    }, [props.recaptchaResponse])

    useEffect(() => {
        if(organizationId) {
            handleReCaptchaVerify();
        }
    }, []);

    return (
        <Box className="anonymous-page-container">
            {
                isVerified ?
                    <Box className='anonymous-page'>
                        <Box className="anonymous-page-content">
                            <Typography>SUCCESS!</Typography>
                            <Typography>Organization was verified.</Typography>
                        </Box>
                    </Box>
                :
                <></>
            }
        </Box>
    )
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        verifying: selectors.verifyingFlagSelector(state),
        recaptchaResponse: state.globals.reCaptchaResponse,
    }
}

const mapDispatchToProps = { verifyOrganization, verifyReCaptcha: sharedActions.verifyReCaptcha };

const VerifyOrganizationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(VerifyOrganization);

export default withRouter(VerifyOrganizationContainer);

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {ApplicationState} from "../../ApplicationState";

import * as selectors from '../accountSelectors';
import {deleteUser, getUsers} from "../accountActions";
import {AdministratorModel} from "../AccountModels";

type AdministratorsProps = {
    users: AdministratorModel[], isUserInvited: boolean,

    getUsers: typeof getUsers, deleteUser: typeof deleteUser,
};

type DeleteUserPopupProps = {
    open: boolean, userId: number,

    deleteUser: typeof deleteUser, handleClose: () => void,
};

const DeleteUserPopup: React.FC<DeleteUserPopupProps> = (props) => {
    const {open, userId} = props;

    const handleDeleteAdministrator = () => {
        props.deleteUser(userId);
        props.handleClose();
    };

    return (<Dialog
            open={open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">
                Are you sure you want to Delete this Administrator ?
            </DialogTitle>
            <DialogContent>
                <Typography>
                    This Administrator will no longer have access to your Dashboard.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button onClick={handleDeleteAdministrator}>Yes, Delete</Button>
            </DialogActions>
        </Dialog>)
};

const Administrators = (props: AdministratorsProps) => {
    const {users} = props;

    const [deletePopupOpen, setDeletePopupOpen] = useState(false);
    const [deletedUser, setDeletedUser] = useState<number>();

    useEffect(() => {
        props.getUsers();
    }, []);

    const handleDeleteAdministrator = (id: number) => {
        setDeletePopupOpen(true);
        setDeletedUser(id);
    };

    const handleDeletePopupClose = () => {
        setDeletePopupOpen(false);
    };

    return (<Box id='subscription-container'>
            <Box className="subscription">
                <Box className='title'>
                    <Typography variant='h6'>
                        According to your Subscription you can add unlimited Administrators here.
                    </Typography>
                </Box>
                <TableContainer>
                    <Table className={`tbl`} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">User ID</TableCell>
                                <TableCell align="center">Full Name</TableCell>
                                <TableCell align="center">Default E-mail Address</TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.length > 0 && users.map(user => {
                                return (<TableRow className="subscriptionplan" key={user.id}>
                                        <TableCell component="th" scope="row">
                                            {user.id}
                                        </TableCell>
                                        <TableCell align="center">
                                            {user.fullName}
                                        </TableCell>
                                        <TableCell align="center">
                                            {user.login}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button
                                                className="margin-right"
                                                variant="outlined"
                                                color="inherit"
                                                disabled={users.length <= 1}
                                                onClick={() => handleDeleteAdministrator(user.id)}
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>)
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {deletedUser && <DeleteUserPopup
                open={deletePopupOpen}
                handleClose={handleDeletePopupClose}
                userId={deletedUser}
                deleteUser={props.deleteUser}
            />}

        </Box>)
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        users: selectors.usersSelector(state),
    }
}

const mapDispatchToProps = {getUsers, deleteUser};

const AdministratorsContainer = connect(mapStateToProps, mapDispatchToProps)(Administrators);

export default AdministratorsContainer;

import React from 'react';
import ReactDOM from 'react-dom';
import createSagaMiddleware from "redux-saga";
import './index.css';

import reportWebVitals from './reportWebVitals';
import {appSettings} from './config/appSettings';

import {createStore, applyMiddleware, compose} from 'redux';
import {Provider} from 'react-redux';
import {Router} from 'react-router-dom';

import createAuthMiddleware, {registerApi} from "./auth/authMiddleware";
import App from './App';
import rootReducer from './rootReducer';
import {createBrowserHistory} from 'history';
import {rootSaga} from './rootSaga';
import {signOut} from './shared/sharedActions';

const history = createBrowserHistory();
const initialiseSagaMiddleware = createSagaMiddleware();
// more details
// const storeEnhancers = (window && (window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const appUrlRoot = appSettings.clientApp.baseAddress;
const apiUrlRoot = appSettings.sites.api.baseAddress;

function authorizationRequest() {
    return new Promise(resolve => {
        // window.location.href = appUrlRoot + '/signout';
        const model = /signin/.test(history.location.pathname) ? {} : {returnUrl: history.location.pathname};
        store.dispatch(signOut(model));
        resolve();
    })
}

const store = createStore(
    // rootReducer(history),
    rootReducer,
    // applyMiddleware(forbiddenWordsMiddleware, initialiseSagaMiddleware)
    applyMiddleware(
        initialiseSagaMiddleware,
        createAuthMiddleware({
            history,
            authorizationServerUrl: 'test',
            authorizationEndpoint: 'test',
            clientAppServerUrl: 'test',
            // authorizationRequest,
        })
    )
);

const regApi = registerApi(store);
regApi({
    name: 'api',
    baseAddress: apiUrlRoot,
    //
    // authorizationRequest,

    // what is it
    // clientId: '',
    // scope: '',
});

initialiseSagaMiddleware.run(rootSaga);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router history={history}>
                <App/>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, {useEffect} from 'react';
import {Box, FormControl, InputLabel, makeStyles, MenuItem, Select, Typography} from '@material-ui/core';
import SectionComponent from '../../shared/components/SectionComponent';
import {connect} from 'react-redux';
import * as selectors from "../ReportingSelectors";
import {getReportSummary} from "../../Feedback/feedbackActions";
import {ApplicationState} from '../../ApplicationState';
import {ReportingFilters, SummaryByDateRangeModel, SummaryEntryModel} from '../ReportingModels';
import {appSettings} from '../../config/appSettings';
import {CustomerSatisfactionType} from '../../shared/enums/FeedbackType';
import * as lookupSelectors from "../../lookups/lookupSelectors";
import {getAgentsForFilter} from '../../lookups/lookupActions';
import {setFilterValue} from '../reportingActions';
import {getProfile} from '../../shared/sharedActions';
import {profileSelector} from '../../shared/sharedSelectors';
import './ReportingPage.scss';
import Chart from './ChartComponent';
import {KeyValueModel, UserProfileModel} from '../../shared/SharedModels';

const staticDataUrl = appSettings.app.staticDataUrl;

type ReportingProps = {
    profile: UserProfileModel | undefined,
    tableSummary: SummaryEntryModel[],
    chartSummary?: SummaryByDateRangeModel,
    agentFilter: KeyValueModel[],
    filters: ReportingFilters,

    getProfile: typeof getProfile;
    getReportSummary: typeof getReportSummary,
    getAgentsForFilter: typeof getAgentsForFilter,
    setFilterValue: typeof setFilterValue,
};

const useStyles = makeStyles((theme) => ({
    feedbackLabel: {
        paddingLeft: "2rem",
    }
}));

const getTimePeriodName = (character: string): string => {
    switch (character) {
        case "y":
            return "YEARS";
        case "m":
            return "MONTHS";
        case "d":
            return "DAYS";
        default:
            return "";
    }
};

const getSatisfactionColor = (type: string): string => {
    switch (type) {
        case CustomerSatisfactionType.ExceededExpectations:
            return "#E1FFEC";
        case CustomerSatisfactionType.Satisfied:
            return "#FDF3D1";
        case CustomerSatisfactionType.Dissatisfied:
            return "#F9C9CC";
        default:
            return "";
    }
};

const Reporting: React.FC<ReportingProps> = (props) => {
    const {profile, tableSummary, chartSummary, agentFilter, filters, setFilterValue, getProfile} = props;
    const classes = useStyles();

    const organization = profile?.organization;

    useEffect(() => {
        if (!agentFilter.length) props.getAgentsForFilter();
    }, []);

    useEffect(() => {
        props.getReportSummary(filters);
    }, [filters, organization]);

    const feedbackTypes = ["Feedback", "Reviews"];

    const [isAgentFilterSelected, setIsAgentFilterSelected] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: any; }>) => {
        if (!event.target.name) return;
        if (event.target.name === "agentId") {
            if (event.target.value !== "") {
                setIsAgentFilterSelected(true)
                setFilterValue({name: "reportType", value: "Feedback",});
                setFilterValue({name: event.target.name, value: event.target.value});
            } else if (event.target.value === "") {
                setIsAgentFilterSelected(false)
                setFilterValue({name: event.target.name, value: event.target.value});
                setFilterValue({name: "reportType", value: "",});
            }
        } else {
            setFilterValue({name: event.target.name, value: event.target.value});
        }
    };

    return (
        <Box id='reporting-container'>
            <Box className="filter-container">
                <Typography>Staff Member</Typography>
                <FormControl variant="outlined" margin="dense" className="select-holder filter-list">
                    <Select
                        value={filters.agentId}
                        displayEmpty
                        onChange={handleChange}
                        name="agentId"
                        id="agentFilter"
                    >
                        <MenuItem value="">
                            Show All
                        </MenuItem>
                        {agentFilter && agentFilter.map(i =>
                            <MenuItem key={i.key} value={i.key}>{i.value}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <Typography className={classes.feedbackLabel}>Feedback Type</Typography>
                <FormControl variant="outlined" margin="dense" className="select-holder filter-list">
                    <Select
                        value={filters.reportType}
                        displayEmpty
                        onChange={handleChange}
                        name="reportType"
                        id="reportType"
                        disabled={isAgentFilterSelected}
                    >
                        <MenuItem value="">
                            Show All
                        </MenuItem>
                        {feedbackTypes && feedbackTypes.map(i =>
                            <MenuItem key={i} value={i}>{i}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Box>

            <Chart
                chartSummary={chartSummary}
            />

            <Box display="flex" marginTop="40px">
                {tableSummary.length > 0 &&
                    tableSummary.map((s, index) => {
                        const title = (!s.timePeriodQuantity && !s.timePeriodType) ? "ALL TIME" : `LAST ${s.timePeriodQuantity.toString()} ${getTimePeriodName(s.timePeriodType)}`;

                        return (
                            <Box key={index} className="summary">
                                <SectionComponent>
                                    {title}
                                </SectionComponent>
                                <Box display="flex" className="content-block" alignItems="center">
                                    <Box display="flex" flexDirection="column" alignItems="center">
                                        <Typography><strong>Satisfaction</strong></Typography>
                                        <Typography className="textColor-base" variant="h5">
                                            {s.criteriaPercent.toFixed(1)} %
                                        </Typography>
                                    </Box>
                                    <Box className="summary-unit-container" display="flex" flexGrow="1"
                                         justifyContent="center">
                                        {s.units.sort((a, b) => {
                                            return b.rate - a.rate
                                        }).map(u => {

                                            return (
                                                <Box
                                                    key={`${u.type}_${index}`}
                                                    className="summary-unit"
                                                    display="flex"
                                                    flexDirection="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    style={{backgroundColor: u.backgroundColor}}
                                                >
                                                    <img src={`${staticDataUrl}/default/${u.type === 'VerySatisfied' ? 'ExceededExpectations' : u.type}.png`} alt=""/>
                                                    <Typography className="textColor-base" style={{marginTop: "12px"}}>
                                                        {u.count}
                                                    </Typography>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })
                }
            </Box>

        </Box>
    )
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        profile: profileSelector(state),
        tableSummary: selectors.tableSummarySelector(state),
        chartSummary: selectors.chartSummarySelector(state),
        filters: selectors.filtersSelector(state),

        agentFilter: lookupSelectors.agentsSelector(state),
    }
}

const mapDispatchToProps = {getReportSummary, getAgentsForFilter, setFilterValue, getProfile};

const ReportingContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Reporting);

export default ReportingContainer;

import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../ApplicationState";

import { InvoiceModel } from "../AccountModels";
import * as selectors from '../accountSelectors';
import { getInvoices, getInvoice } from "../accountActions";

type InvoicesProps = {
    invoices: InvoiceModel[],

    getInvoices: typeof getInvoices,
    getInvoice: typeof getInvoice,
};

const Invoices = (props: InvoicesProps) => {
    const { invoices } = props;

    useEffect(() => {
        props.getInvoices();
    }, []);

    const handleDownloadInvoice = (id: number) => {
        props.getInvoice(id);
    };

    return (
        <Box id='subscription-container'>
            <Box className="subscription">
                <Box className='title'>
                    <Typography variant='h6'>You can download the invoices from below.</Typography>
                </Box>
                <TableContainer>
                <Table className={`tbl`} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Invoices #</TableCell>
                            <TableCell align="center">Date</TableCell>
                            <TableCell align="center">Amount</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoices.length > 0 && invoices.map(invoice => {
                            return (
                                <TableRow className="subscriptionplan" key={invoice.id}>
                                    <TableCell component="th" scope="row" >
                                        {invoice.id}
                                    </TableCell>
                                    <TableCell align="center">
                                        {invoice.createdDate && new Date(invoice.createdDate).toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false })}  
                                    </TableCell>
                                    <TableCell align="center">
                                            $ {invoice.totalPrice}
                                    </TableCell>
                                    <TableCell align="center">
                                        {invoice.status}
                                    </TableCell>
                                    <TableCell align="center">
                                            <Button
                                                className="margin-right"
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => handleDownloadInvoice(invoice.id)}
                                            >
                                                Download
                                            </Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            </Box>
        </Box>
    )
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        invoices: selectors.invoicesSelector(state),
    }  
} 

const mapDispatchToProps = { getInvoices, getInvoice };

const InvoicesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Invoices);

export default InvoicesContainer;